import { Close, ExpandMore, PlayArrow, Stop } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import type { UseQueryResult } from '@tanstack/react-query';
import type { EngineUsage } from 'apis/rest/engineUsage/types';
import { useGetInferredEventsByReportIdForAsset } from 'apis/rest/inferredEvents/hooks';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import type { HttpResponseError } from 'helpers/api';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSize } from 'hooks/useSize';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslations } from 'use-intl';
import { formatDateTime } from 'utils/time';
import EngineCycles from '../engineCycles';
import Score from '../score';
import { reducer } from '../timeline/tripTimelineView';
import { LoadTrip, TripDetail } from '../tripDetails/tripDetail';

interface TripReportsAssetTable {
  query: { from: number; until: number };
  trips: Trip[];
  asset: AssetWithDevice;
  timezone: string;
  engineUsageQuery: UseQueryResult<EngineUsage[], HttpResponseError>;
  displayEngineUsage: boolean;
  isExpanded: boolean;
  setExpanded: (assetId: number | undefined) => void;
}

interface AssetTrips {
  asset: AssetBasic;
  trips: Trip[];
  rows: TripRow[];
}

interface AssetTripsWithEngine {
  asset: AssetBasic;
  trips: Trip[];
  engineUsages: EngineUsage[];
  isLoadingEngineUsages: boolean;
  rows: (TripRow | EngineRow)[];
}

interface TripRow {
  type: 'TRIP';
  value: Trip;
  order: number;
}

interface EngineRow {
  type: 'ENGINE_START' | 'ENGINE_STOP';
  value: EngineUsage;
  order: number;
}

const getSummaryGridProps = (displayDurationMetrics: boolean, displayEngineUsage: boolean) => {
  return {
    gridTemplateColumns: `minmax(max-content, 1fr) 12rem 12rem 12rem ${displayDurationMetrics ? '12rem 12rem' : ''} ${displayEngineUsage ? '12rem 12rem' : ''} max-content`,
    gridTemplateAreas: {
      xs: `"asset count duration distance ${displayDurationMetrics ? 'airborneDuration movementDuration' : ''} ${displayEngineUsage ? 'engineStarts engineDuration' : ''} action"`,
    },
  };
};

interface SummaryProps {
  asset: AssetWithDevice;
  trips: Trip[];
  displayEngineUsage: boolean;
  engineUsages: EngineUsage[] | undefined;
  isLoadingEngineUsages: boolean;
}

const Summary = ({ asset, trips, displayEngineUsage, engineUsages = [], isLoadingEngineUsages }: SummaryProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.assetTable.summary');
  const distance = useDistance();
  const duration = useDuration();

  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);

  const enableDurationMetrics = useFeatureFlag('frontendAirborneMovementTime');
  const displayDurationMetrics = useMemo(
    () => enableDurationMetrics ? ((!displayEngineUsage && size.width > 1200) || size.width > 1500) : false,
    [enableDurationMetrics, size.width, displayEngineUsage],
  );

  const totals = useMemo(
    () =>
      trips.reduce(
        (sum, trip) => ({
          duration: sum.duration + (trip.duration ?? 0),
          distance: sum.distance + (trip.distance ?? 0),
          airborneDuration: sum.airborneDuration + (trip.airborneDuration ?? 0),
          movementDuration: sum.movementDuration + (trip.movementDuration ?? 0),
        }),
        {
          duration: 0,
          distance: 0,
          airborneDuration: 0,
          movementDuration: 0,
        },
      ),
    [trips],
  );

  const totalEngineDuration = useMemo(
    () => engineUsages.reduce((sum, item) => sum + (item.duration ?? 0), 0),
    [engineUsages],
  );

  return (
    <Box
      mx={1}
      my={2}
      flex={1}
      display="grid"
      {...getSummaryGridProps(displayDurationMetrics, displayEngineUsage)}
      gap={3}
      alignItems="center"
      ref={containerRef}
    >
      <Box gridArea="asset">
        <Stack direction="row" alignItems="center" spacing={1}>
          <AssetColourMarker assetId={asset.id} />
          <Typography variant="h3">
            <AssetLabel asset={asset} />
          </Typography>
        </Stack>
        <Typography variant="h6">
          {asset.make} {asset.model}
        </Typography>
      </Box>
      <Box gridArea="count">
        <Score label={t('count')} value={trips.length} />
      </Box>
      <Box gridArea="duration">
        <Score label={t('duration')} value={duration.fromMillis(totals.duration)} />
      </Box>
      <Box gridArea="distance">
        <Score label={t('distance')} value={distance.create(totals.distance * 1000).format()} />
      </Box>
      {displayDurationMetrics && (
        <>
          <Box gridArea="airborneDuration">
            <Score label={t('airborneDuration')} value={duration.fromMillis(totals.airborneDuration)} />
          </Box>
          <Box gridArea="movementDuration">
            <Score label={t('movementDuration')} value={duration.fromMillis(totals.movementDuration)} />
          </Box>
        </>
      )}
      {displayEngineUsage && (
        <Box gridArea="engineStarts">
          <Score
            label={t('engineCycles')}
            value={<EngineCycles count={engineUsages.length} />}
            loading={isLoadingEngineUsages}
          />
        </Box>
      )}
      {displayEngineUsage && (isLoadingEngineUsages || engineUsages.length > 0) && (
        <Box gridArea="engineDuration">
          <Score
            label={t('engineDuration')}
            value={duration.fromMillis(totalEngineDuration)}
            loading={isLoadingEngineUsages}
          />
        </Box>
      )}
    </Box>
  );
};

const TripReportsAssetTable = ({
  trips: allTrips,
  asset,
  query,
  engineUsageQuery,
  timezone,
  displayEngineUsage,
  isExpanded,
  setExpanded,
}: TripReportsAssetTable) => {
  const t = useTranslations('pages.reporting.tripAnalysis.assetTable');

  const trips = useMemo(
    () => allTrips.filter(trip => trip.assetId === asset.id).sort((a, b) => a.startTime - b.startTime),
    [asset, allTrips],
  );

  const inferredEventsQuery = useGetInferredEventsByReportIdForAsset(
    asset.id,
    DateTime.fromMillis(query.from),
    DateTime.fromMillis(query.until),
  );

  const cluster = useMemo<AssetTripsWithEngine | AssetTrips>(() => {
    if (displayEngineUsage) {
      const rows: (TripRow | EngineRow)[] = trips.map<TripRow>(trip => ({
        type: 'TRIP',
        value: trip,
        order: trip.startTime,
      }));
      const engineUsages = engineUsageQuery.data?.filter(engineUsage => engineUsage.assetId === asset.id) ?? [];

      for (const item of engineUsages) {
        rows.push({ type: 'ENGINE_START', value: item, order: item.startTime });
        if (item.endTime !== undefined) rows.push({ type: 'ENGINE_STOP', value: item, order: item.endTime });
      }

      rows.sort((a, b) => a.order - b.order);

      return {
        asset,
        trips,
        engineUsages,
        isLoadingEngineUsages: engineUsageQuery.isLoading,
        rows,
      };
    }

    const rows: TripRow[] = trips.map<TripRow>(trip => ({ type: 'TRIP', value: trip, order: trip.startTime }));
    rows.sort((a, b) => a.order - b.order);

    return {
      asset,
      trips,
      rows,
    };
  }, [trips, asset, displayEngineUsage, engineUsageQuery]);

  const theme = useTheme();
  const selectedBgColor = alpha(theme.palette.primary.main, 0.05);

  const { state } = useLocation();
  const [tripState, dispatch] = useReducer(reducer, { trips: [], findTripAround: state?.findTripAround });
  useEffect(() => dispatch({ type: 'SET_TRIPS', trips }), [trips]);

  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      navigate('.', { state: undefined });
    }
  }, [state, navigate]);

  const distance = useDistance();
  const duration = useDuration();

  const enableDurationMetrics = useFeatureFlag('frontendAirborneMovementTime');

  let isWithinEngineUsage = false;
  return (
    <Paper sx={{ my: 3 }} elevation={0}>
      <Accordion
        slotProps={{ transition: { unmountOnExit: true, mountOnEnter: true } }}
        disableGutters
        sx={{ boxShadow: 'none', '&.Mui-disabled': { bgcolor: 'common.white' } }}
        expanded={isExpanded}
        onChange={(event, value) => setExpanded(value ? asset.id : undefined)}
        disabled={trips.length === 0}
      >
        <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
          <Summary
            asset={asset}
            trips={trips}
            displayEngineUsage={displayEngineUsage}
            engineUsages={engineUsageQuery.data?.filter(engineUsage => engineUsage.assetId === asset.id)}
            isLoadingEngineUsages={engineUsageQuery.isLoading}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box pb={2}>
            <Table>
              <TableHead>
                <TableRow>
                  {displayEngineUsage && <TableCell sx={{ p: 0 }} />}
                  <TableCell width={enableDurationMetrics ? '30%' : '40%'}>{t('from')}</TableCell>
                  <TableCell width={enableDurationMetrics ? '30%' : '40%'}>{t('to')}</TableCell>
                  <TableCell width="10%">{t('duration')}</TableCell>
                  <TableCell width="10%">{t('distance')}</TableCell>
                  {enableDurationMetrics && (
                    <>
                      <TableCell width="10%">{t('airborneDuration')}</TableCell>
                      <TableCell width="10%">{t('movementDuration')}</TableCell>
                    </>
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {cluster.rows.map((row, index) => {
                  const nextRow = cluster.rows.at(index + 1);

                  if (row.type === 'TRIP') {
                    const trip = row.value;
                    const isSelectedTrip = trip.id === tripState.selectedTrip?.id;
                    return (
                      <Fragment key={`trip-${trip.id}`}>
                        <TableRow
                          onClick={() =>
                            dispatch({ type: 'SET_SELECTED_TRIP', tripId: isSelectedTrip ? undefined : trip.id })
                          }
                          sx={{
                            cursor: 'pointer',
                            bgcolor: isSelectedTrip ? selectedBgColor : undefined,
                            ':hover': { bgcolor: selectedBgColor },
                            '& > .MuiTableCell-root': { border: 0 },
                          }}
                        >
                          {displayEngineUsage && !isWithinEngineUsage && <TableCell sx={{ p: 0 }} />}
                          <TableCell>
                            <Typography>{trip.start}</Typography>
                            <Typography>{formatDateTime(trip.startTime, timezone, true)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{trip.end}</Typography>
                            <Typography>
                              {trip.endTime ? formatDateTime(trip.endTime, timezone, true) : null}
                            </Typography>
                          </TableCell>
                          <TableCell>{duration.fromMillis(trip.duration)}</TableCell>
                          <TableCell>
                            {trip.distance === undefined ? null : distance.create(trip.distance * 1000).format()}
                          </TableCell>
                          {enableDurationMetrics && (
                            <>
                              <TableCell>{duration.fromMillis(trip.airborneDuration)}</TableCell>
                              <TableCell>{duration.fromMillis(trip.movementDuration)}</TableCell>
                            </>
                          )}
                          <TableCell>
                            <IconButton
                              onClick={event => {
                                dispatch({ type: 'SET_SELECTED_TRIP', tripId: isSelectedTrip ? undefined : trip.id });
                                event.stopPropagation();
                              }}
                            >
                              {isSelectedTrip ? <Close fontSize="large" /> : <ExpandMore fontSize="large" />}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            bgcolor: selectedBgColor,
                            '& > .MuiTableCell-root': { p: '0 !important' },
                          }}
                        >
                          <TableCell
                            colSpan={100}
                            sx={{ borderWidth: nextRow ? (nextRow?.type === 'ENGINE_START' ? 4 : undefined) : 0 }}
                          >
                            <Collapse in={isSelectedTrip} unmountOnExit mountOnEnter>
                              <LoadTrip
                                tripId={trip.id}
                                assetId={trip.assetId}
                                loaded={innerTrip => (
                                  <TripDetail
                                    trip={innerTrip}
                                    inferredEvents={inferredEventsQuery.data}
                                    dispatch={dispatch}
                                    hideHeader
                                    timezone={timezone}
                                  />
                                )}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  }

                  if (row.type === 'ENGINE_START') {
                    isWithinEngineUsage = true;
                    const endIndex = cluster.rows.slice(index).findIndex(r => r.type === 'ENGINE_STOP');
                    const rowSpan = cluster.rows.slice(index, index + endIndex + 1).reduce((count, r) => {
                      if (r.type === 'TRIP') return count + 2;
                      return count + 1;
                    }, 0);

                    const isStartStop = nextRow?.type === 'ENGINE_STOP';

                    return (
                      <Fragment key={`engine-start-${row.value.startTime}`}>
                        <TableRow>
                          <TableCell
                            sx={{ borderWidth: cluster.rows[index + endIndex + 1] ? 4 : 0, height: '1px' }}
                            rowSpan={rowSpan}
                          >
                            <Stack height="100%" alignItems="center" spacing={2} py={1.5}>
                              <PlayArrow />
                              <Box sx={{ flex: 1, borderLeft: '2px dashed #999' }} />
                              <Stop />
                            </Stack>
                          </TableCell>
                          <TableCell
                            colSpan={100}
                            sx={{ borderWidth: isStartStop ? 0 : undefined, pb: isStartStop ? 0 : undefined }}
                          >
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography>
                                Engine start
                                <br />
                                {formatDateTime(row.value.startTime, timezone, true)}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  }

                  if (row.type === 'ENGINE_STOP') {
                    isWithinEngineUsage = false;
                    return (
                      <Fragment key={`engine-stop-${row.value.endTime}`}>
                        <TableRow>
                          <TableCell colSpan={100} sx={{ borderWidth: nextRow ? 4 : 0 }}>
                            <Stack direction="row" justifyContent="space-between">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography>
                                  Engine stop
                                  <br />
                                  {formatDateTime(row.value.endTime, timezone, true)}
                                </Typography>
                              </Stack>
                              <Typography textAlign="right">
                                Engine duration
                                <br />
                                {duration.fromMillis(row.value.duration)}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  }

                  return null;
                })}
              </TableBody>
            </Table>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default TripReportsAssetTable;
