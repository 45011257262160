// helpers for processing numbers

// clamps a number between a minimum and maximum
export const clampNumber = (min: number, max: number, input: number): number => {
  return Math.min(max, Math.max(min, input));
}

// wraps an input between a minimum and maximum
export const wrapNumber = (min: number, max: number, input: number): number => {
  if (input > max) {
    return wrapNumber(min, max, min + (input - max));
  }
  if (input < min) {
    return wrapNumber(min, max, max - (input - min));
  }
  return input;
}
