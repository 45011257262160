import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  formControl: {
    margin: '0 10px 7px 0',
    minWidth: 150,
    maxWidth: 150,
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(10px, -6px) scale(0.75)',
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
    '& .MuiSelect-select': {
      padding: '12px 22px 12px 12px',
    },
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.lightGrey,
      },
    },
  },
}));
