import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef, useState,
} from 'react';
import {
  Box, Typography, Stack
} from '@mui/material';
import {
  AutoSizer, Column, defaultTableRowRenderer, Table, TableHeaderProps, SortDirection, SortDirectionType
} from 'react-virtualized';
import { GetApp } from '@mui/icons-material';
import {
  altitude, bearing, calculateDistance, coordinate, distance, speed
} from 'helpers/unitsOfMeasure';
import { useSelector } from 'react-redux';
import { ClassNameMap } from '@mui/styles';
import { useTranslations } from 'use-intl';
import clsx from 'clsx';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import moment from 'moment';
import { stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import { useAssetReportsNoInactive } from 'repositories/reports/hooks';
import { labelToDisplayLabel } from 'helpers/events';
import { gatewayToTransport } from 'helpers/transport';
import { useSetViewport } from 'contexts/viewport/useViewport';
import { reportsToKmz } from 'utils/kmz';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import { supportsBattery } from 'helpers/deviceSupport';
import { useAssetLabel } from 'components/shared/assetLabel';
import useTimezone from 'hooks/session/useTimezone';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { InferredEventId, ReportWithInferredEvents } from 'apis/rest/inferredEvents/types';
import { getSelectedDay, getSelectedItem } from 'slices/app.slice';
import { setSelectedReport } from 'slices/report.slice';
import { useAppDispatch } from 'store/types';
import { useInferredEventsForReports } from 'repositories/inferredEvents/hooks';
import { getSelectedLeg, getSelectedMapId, setAssetFollow } from 'slices/map.slice';
import DownloadSpeedDial from './downloadSpeedDial';
import MultiSelectFilter from './multiSelectFilter';
import useStyles from './analysisbox-styles';
import TPFormDialog from 'components/tpForms/formModal/formModal-view';

interface AnalysisboxProps {
  drawerHeight: number;
  timeline?: boolean;
}

type Field = { selected: boolean };

interface Fields {
  id: Field;
  timestamp: Field;
  coordinates: Field;
  altitude: Field;
  bearing: Field;
  speed: Field;
  event: Field;
  inferredEvents: Field;
  dop: Field;
  latency: Field;
  elapsed: Field;
  distance: Field;
  battery: Field;
  gateway: Field;
  metadata: Field;
  text: Field;
}

const DEFAULT_SELECTED_FIELDS: Fields = {
  id: { selected: false },
  timestamp: { selected: true },
  coordinates: { selected: true },
  altitude: { selected: true },
  bearing: { selected: true },
  speed: { selected: true },
  event: { selected: true },
  inferredEvents: { selected: false },
  dop: { selected: false },
  latency: { selected: true },
  elapsed: { selected: true },
  distance: { selected: true },
  battery: { selected: true },
  gateway: { selected: true },
  metadata: { selected: false },
  text: { selected: false },
};

const compareTimes = (end: number, start: number): string => (new Date(Math.max(0, (end - start)) * 1000)).toISOString().slice(11, 19);

// const compareTimes = (end: number, start: number): string => (end - start).toString()

type Units = ReduxState['unitSettings']['units'];

const mapToAnalysisBox = (r: ReportWithInferredEvents, units: Units, timezone: string, setOpenForm: (r: Report | null) => void, prev?: Report): AnalysisBoxReport => ({
  id: r.id,
  timestamp: (r.received && timezone)
    ? moment.unix(r.received).tz(timezone).format('D MMM YYYY H:mm:ss z')
    : '--',
  coordinates: (r.latitude && r.longitude && units.coordinate)
    ? coordinate.fromLatLon(r.latitude, r.longitude, units.coordinate)
    : '--',
  altitude: (r.altitude && units.altitude)
    ? altitude.fromSI(r.altitude, units.altitude)
    : '--',
  bearing: (r.course && r.received && units.bearing)
    ? bearing.fromSI(r.course, r.received, r, units.bearing)
    : '--',
  speed: (r.speed && units.speed)
    ? speed.fromKmh(r.speed, units.speed)
    : '--',
  event: r.form
    ? (<Typography onClick={() => setOpenForm(r)}><LaunchOutlinedIcon fontSize="small" /> {r.form.title}</Typography>)
    : r.events[0],
  inferredEvents: r.inferredEvents ?? [],
  // text: (textRecipient && textMessage)
  //   ? `${textRecipient}: ${textMessage}`
  //   : '--',
  dop: r.dop
    || '--',
  latency: (r.logged && r.received)
    ? compareTimes(r.logged, r.received)
    : '--',
  elapsed: (prev?.received && r.received)
    ? compareTimes(r.received, prev?.received)
    : '--',
  distance: (prev?.latitude && prev?.longitude && r.latitude && r.longitude)
    ? calculateDistance(prev, r, units.distance)
    : '--',
  gateway: r.gateway
    ? gatewayToTransport(r.gateway)
    : '--',
  metadata: r.metadata
    ? JSON.stringify(r.metadata, null, 1).slice(1, -1).replace(/["|\n]|(\\n)/g, '')
    : '--',
  latitude: r.latitude,
  longitude: r.longitude,
  received: r.received,
  deviceId: r.deviceId,
  isValid: r.isValid,
  battery: r.battery ? `${r.battery}%` : '--'
});

interface SortIndicatorProps {
  sortDirection?: SortDirectionType;
}

const SortIndicator = ({ sortDirection }: SortIndicatorProps) => {
  const className = clsx('ReactVirtualized__Table__sortableHeaderIcon', {
    'ReactVirtualized__Table__sortableHeaderIcon--ASC': sortDirection === SortDirection.ASC,
    'ReactVirtualized__Table__sortableHeaderIcon--DESC': sortDirection === SortDirection.DESC
  });

  return (
    <svg
      className={className}
      width={18}
      height={18}
      viewBox="0 0 24 24"
      focusable={false}
      aria-hidden
    >
      <path d="M 20 12 l -1.41 -1.41 L 13 16.17 V 4 h -2 v 12.17 l -5.58 -5.59 L 4 12 l 8 8 l 8 -8 Z" />
    </svg>
  );
};

const HeaderRenderer = ({
  dataKey,
  label,
  sortBy,
  sortDirection
}: TableHeaderProps) => {
  const showSortIndicator = sortBy === dataKey;
  const children = [
    (
      <span
        className="ReactVirtualized__Table__headerTruncatedText"
        key="label"
        title={typeof label === 'string' ? label : undefined}
      >
        {label}
      </span>
    )
  ];

  if (showSortIndicator) {
    children.push(<SortIndicator key="SortIndicator" sortDirection={sortDirection} />);
  }

  return children;
};

const Analysisbox = ({ drawerHeight, timeline = false }: AnalysisboxProps): JSX.Element => {
  const timezone = useTimezone();
  const selectedMapId = useSelector(getSelectedMapId);
  const selectedDay = useSelector(getSelectedDay);
  const selectedAsset = useSelector(getSelectedItem);
  const selectedLeg = useSelector(getSelectedLeg);
  const selectedReport = useSelector<ReduxState, Report | null>(state => state.reports.selectedReportPerMap[state.map.selectedMapId]);
  const follow = useSelector((state: ReduxState) => state.map.assetsAreBeingFollowedOnMaps[selectedMapId]);

  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);
  const classes: ClassNameMap = useStyles();
  const t = useTranslations('analysisbox');
  const t2 = useTranslations('shared.inferredEvents');
  const t3 = useTranslations('shared.inferredEventsWithPrefix');
  const units = useUnitSettings();
  const {
    altitude: altitudeUnits,
    distance: distanceUnits,
    bearing: bearingUnits,
  } = useUnitSettings();
  const speedUnits = useSpeedByAsset(selectedAsset ?? undefined);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [selectedFields, setSelectedFields] = useState<Fields>({ ...DEFAULT_SELECTED_FIELDS });
  const [sort, setSort] = useState({ sortBy: 'timestamp', sortDirection: 'DESC' });
  const tableRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openForm, setOpenForm] = useState<Report | null>(null);
  const assetLabel = useAssetLabel();
  const dispatch = useAppDispatch();

  const updateSelectedFields = (newSelectedFields: string[]) => {
    const obj: Fields = { ...selectedFields };
    Object.keys(selectedFields)
      .filter(f => !newSelectedFields.includes(f))
      .forEach(f => {
        obj[f as keyof Fields].selected = false;
      });
    newSelectedFields.forEach(f => {
      obj[f as keyof Fields].selected = true;
    });
    setSelectedFields(obj);
  };

  useEffect(() => {
    if (supportsBattery(selectedAsset ?? undefined)) {
      if (!selectedFields.battery) {
        setSelectedFields({ ...selectedFields, battery: { selected: true } });
      }
    } else if (selectedFields.battery) {
      setSelectedFields({ ...selectedFields, battery: { selected: true } });
    }
  }, [selectedAsset, selectedFields, setSelectedFields]);

  const reportsForAsset = useAssetReportsNoInactive(selectedAsset?.id ?? 0, (now || moment()).tz(timezone).startOf('d').unix());

  const reports = useInferredEventsForReports(reportsForAsset);
  const selectedReports = useMemo(() => {
    if (!reports) return [];
    if (selectedAsset) {
      if (selectedLeg) {
        return reports
          .filter(r => r.received >= selectedLeg.start && (!selectedLeg.complete || r.received <= selectedLeg.end));
      }
      return reports;
    }
    return [];
  }, [selectedAsset, selectedLeg, reports]);

  const inferredEventsToLabel = useCallback((inferredEvents: InferredEventId[] | null | undefined) => (inferredEvents ?? []).map(e => t2(e)).join(', '), [t2]);

  const analysisBoxReports = useMemo(
    () => selectedReports
      .slice()
      .reverse()
      .filter(r => (selectedEvents.length ? selectedEvents.includes(r.events[0] ?? '') || (r.inferredEvents?.some(e => selectedEvents.includes(e)) ?? false) : r))
      .map((r, i, a) => mapToAnalysisBox(r, units, timezone, setOpenForm, a[i - 1])),
    [selectedReports, selectedEvents, units, timezone]
  );
  const rawAnalysisBoxReports = useMemo(() => selectedReports
    .filter(r => (selectedEvents.length ? selectedEvents.includes(r.events[0] ?? '') || (r.inferredEvents?.some(e => selectedEvents.includes(e)) ?? false) : r))
    .map(r => ({ ...r, events: labelToDisplayLabel(r.events[0] ?? '') ?? '' })), [selectedReports, selectedEvents]);

  const dateSort = (a: AnalysisBoxReport, b: AnalysisBoxReport): number => moment(a.timestamp).tz(timezone).unix() - moment(b.timestamp).tz(timezone).unix();
  const alphabeticalSort = (a: any, b: any): number => a[sort.sortBy]?.localeCompare(b[sort.sortBy]);
  const numericalSort = (a: any, b: any): number => {
    const numericalA = typeof a[sort.sortBy] === 'number' ? a[sort.sortBy] : a[sort.sortBy]?.replace(/\D/g, '');
    const numericalB = typeof b[sort.sortBy] === 'number' ? b[sort.sortBy] : b[sort.sortBy]?.replace(/\D/g, '');
    return numericalA - numericalB;
  };

  const getSortMethod = (): (a: any, b: any) => number => {
    switch (sort.sortBy) {
      case 'timestamp':
        return dateSort;
      case 'altitude':
      case 'bearing':
      case 'speed':
      case 'dop':
      case 'distance':
        return numericalSort;
      default:
        return alphabeticalSort;
    }
  };

  const filteredReports = analysisBoxReports.sort(getSortMethod());
  const visibleReports = sort.sortDirection === 'DESC' ? filteredReports.slice().reverse() : filteredReports;
  const visibleReportsWithUnits = visibleReports.map(r => ({
    ...r,
    altitude: typeof r.altitude === 'number' ? altitude.withUnits(r.altitude, altitudeUnits, 0) : r.altitude,
    bearing: typeof r.bearing === 'number' ? bearing.withUnits(r.bearing, bearingUnits, r.received) : r.bearing,
    speed: typeof r.speed === 'number' ? speed.withUnits(r.speed, speedUnits, 1) : r.speed,
    distance: typeof r.distance === 'number' ? distance.withUnits(r.distance, distanceUnits, 2) : r.distance,
  }));

  const reportTitle = selectedLeg
    ? `${selectedLeg.from} - ${selectedLeg.to || ''}`
    : (selectedAsset && !timeline) ? assetLabel(selectedAsset) : '';
  const exportFilename = `TracPlus Export ${reportTitle} ${moment(now ?? new Date()).format('YYYY-MM-DD')}`;
  const uniqueEvents = useMemo(
    () => selectedReports
      .flatMap(r => [(r.events[0] || 'EVT_STANDARD'), ...(r.inferredEvents ?? [])])
      .filter((e, i, self) => self.indexOf(e) === i)
      .toSorted(),
    [selectedReports]
  );

  useEffect(() => {
    if (selectedReport) {
      if (selectedReport.id === selectedRow?.id) { return; }
      setSelectedRow(visibleReportsWithUnits.find(r => r.id === selectedReport?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  const fields = {
    id: { label: 'id', dataKey: 'id', width: () => 0 },
    timestamp: { label: t('timestamp'), dataKey: 'timestamp', width: () => 190 },
    coordinates: { label: t('coordinates'), dataKey: 'coordinates', width: () => 200 },
    altitude: { label: t('altitude'), dataKey: 'altitude', width: () => 90 },
    bearing: { label: t('bearing'), dataKey: 'bearing', width: () => 90 },
    speed: { label: t('speed'), dataKey: 'speed', width: () => 100 },
    event: { label: t('event'), dataKey: 'event', render: labelToDisplayLabel, width: () => 100 },
    inferredEvents: { label: t('inferredEvents'), dataKey: 'inferredEvents', render: inferredEventsToLabel, width: () => 200 },
    text: { label: t('text'), dataKey: 'text', width: () => 100 },
    dop: { label: t('dop'), dataKey: 'dop', width: () => 80 },
    latency: { label: t('latency'), dataKey: 'latency', width: () => 100 },
    elapsed: { label: t('elapsed'), dataKey: 'elapsed', width: () => 100 },
    distance: { label: t('distance'), dataKey: 'distance', width: () => 100 },
    gateway: { label: t('gateway'), dataKey: 'gateway', width: () => 100 },
    metadata: { label: t('metadata'), dataKey: 'metadata', width: () => 700 },
    battery: { label: t('battery'), dataKey: 'battery', width: () => 80 }
  };

  const patchViewport = useSetViewport(selectedMapId);

  const getRowRenderer = (props: any): React.ReactNode => defaultTableRowRenderer({
    ...props,
    onRowClick: row => {
      // centering map on past reports for a followed asset causes bad behavior, so unfollow first
      if (follow) dispatch(setAssetFollow({ mapId: selectedMapId, isFollowed: false }));
      if (row.rowData.isValid) patchViewport({ longitude: row.rowData.longitude, latitude: row.rowData.latitude });
      dispatch(setSelectedReport({ mapId: selectedMapId, report: selectedReports.find(r => r.id === row.rowData.id) ?? null }));
      setSelectedRow(row.rowData);
    },
    onRowMouseOver: row => dispatch(setSelectedReport({ mapId: selectedMapId, report: selectedReports.find(r => r.id === row.rowData.id) ?? null })),
  });

  const handleSort = ({ sortBy, sortDirection }: { sortBy: any, sortDirection: SortDirectionType }): void => setSort({ sortBy, sortDirection });

  const prepareCSVExport = (visibleReports: Report[]): string => {
    const unitsLookup = {
      timestamp: timezone,
      altitude: altitudeUnits,
      bearing: bearing.label(bearingUnits, visibleReports[0]?.received),
      speed: speedUnits,
      distance: distanceUnits
    };
    const csvString = csvStringify(visibleReports, {
      header: true,
      columns: [
        { key: 'id', header: 'Report Id' },
        { key: 'received', header: 'Received (unix timestamp)' },
        { key: 'logged', header: 'Logged (unix timestamp)' },
        { key: 'latitude', header: 'Latitude' },
        { key: 'longitude', header: 'Longitude' },
        { key: 'altitude', header: `Altitude (${unitsLookup['altitude']})` },
        { key: 'course', header: 'Course' },
        { key: 'speed', header: `Speed (${unitsLookup['speed']})` },
        { key: 'isValid', header: 'isValid' },
        { key: 'dop', header: 'dop' },
        { key: 'fixQuality', header: 'fixQuality' },
        { key: 'events', header: 'Events' },
        { key: 'package', header: 'Package' },
        { key: 'gateway', header: 'Gateway' },
        { key: 'assetId', header: 'AssetId' },
        { key: 'battery', header: 'Battery' },
        { key: 'form', header: 'Form' },
        { key: 'text', header: 'Text' }
      ],
    });
    return `data:text/csv;charset=utf-8,${csvString}`;
  };

  const downloadCsvFile = (): void => {
    const encodedUri = encodeURI(prepareCSVExport(rawAnalysisBoxReports));
    const element = document.createElement('a');
    element.setAttribute('href', encodedUri);
    element.setAttribute('download', `${exportFilename}.csv`);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const downloadKmzFile = async (): Promise<void> => {
    const element = document.createElement('a');
    const blob = await reportsToKmz(selectedAsset, selectedReports); // , {type: 'text/plain'});
    element.href = URL.createObjectURL(blob);
    element.download = `${exportFilename}.kmz`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const getRowClassName = (index: { index: number }): string => (selectedRow && visibleReportsWithUnits.findIndex(row => row.id === selectedRow?.id) === index.index ? classes.focusedRow : '');

  return (
    <Box className={classes.tableContainer}>
      <Stack alignItems="start">
        <Typography variant="h2">{reportTitle}</Typography>
        <Typography variant="h5" pb={1}>{visibleReportsWithUnits.length} reports</Typography>
      </Stack>

      <MultiSelectFilter
        options={visibleReportsWithUnits.length ? Object.keys(selectedFields).filter(k => Object.keys(visibleReportsWithUnits[0])?.includes(k)) : []}
        selectedOptions={Object.keys(selectedFields).filter(k => selectedFields[k as keyof Fields].selected)}
        setSelectedOptions={updateSelectedFields}
        label={t('fieldFilter.selectColumns')}
        summaryText={t('fieldFilter.summaryText')}
      />
      <MultiSelectFilter
        options={uniqueEvents}
        optionRenderer={(evt: string) => (evt.startsWith('INFERRED') ? t3(evt as InferredEventId) : labelToDisplayLabel(evt))}
        selectedOptions={selectedEvents}
        setSelectedOptions={setSelectedEvents}
        label={t('eventFilter.filterEvents')}
        summaryText={t('eventFilter.summaryText')}
      />

      <Box>
        <DownloadSpeedDial ariaLabel="analysisbox-exports">
          <SpeedDialAction
            key="csv-download"
            icon={<GetApp />}
            tooltipTitle={`${t('export')} CSV`}
            onClick={() => downloadCsvFile()}
            tooltipOpen
          />
          <SpeedDialAction
            key="kmz-download"
            icon={<GetApp />}
            tooltipTitle={`${t('export')} KMZ`}
            onClick={() => downloadKmzFile()}
            tooltipOpen
          />
        </DownloadSpeedDial>
      </Box>
      <div className={classes.autosizerWrapper} ref={tableRef}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              className={classes.reportsTable}
              width={width}
              height={drawerHeight - 80}
              headerHeight={49}
              rowHeight={37}
              rowCount={visibleReportsWithUnits.length}
              rowGetter={({ index }) => visibleReportsWithUnits[index]}
              sort={handleSort}
              sortBy={sort.sortBy}
              sortDirection={sort.sortDirection as SortDirectionType}
              rowRenderer={getRowRenderer}
              rowClassName={index => getRowClassName(index)}
              // onRowClick={this.rowSelection}
              scrollToAlignment="auto"
              scrollToIndex={visibleReportsWithUnits.findIndex(row => row.id === selectedRow?.id)}
              // onRowsRendered={this.rowRendered}
            >
              {Object.keys(selectedFields).filter(f => selectedFields[f as keyof Fields].selected).map(field => (
                <Column
                  key={(fields as any)[field].dataKey}
                  label={(fields as any)[field].label}
                  dataKey={(fields as any)[field].dataKey}
                  flexGrow={1}
                  width={(fields as any)[field].width(width)}
                  headerRenderer={HeaderRenderer}
                  cellRenderer={({ cellData }) => {
                    const render = (fields as any)[field].render ?? ((s: string) => s);
                    return render(cellData) ?? '--';
                  }}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
      {openForm
        ? <TPFormDialog report={openForm} onClose={() => setOpenForm(null)} />
        : null}
    </Box>
  );
};

export default Analysisbox;
