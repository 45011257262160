import { Done } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { mutateActivateDevice, useGetDevicesAvailableForActivation, useGetProvisionState } from 'apis/rest/deviceProvision/hooks';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';

export const DeviceActivation = ({ device }: { device: DeviceBasic }): JSX.Element => {
  const t = useTranslations('pages.deviceView.activation');
  const ffEnabled = useFeatureFlag('tpcDeviceActivation')

  const [requestId, setRequestId] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  const { query, queryKey } = useGetDevicesAvailableForActivation()
  const activateMutation = mutateActivateDevice()
  const handleActivation = useCallback((deviceId: number) => {
    activateMutation.mutate(device.id, {
      onSuccess(data, variables, context) {
        setRequestId(data.requestId);
      },
      onError(error, variables, context) {
        setError(true);
      },
    })
  }, []);

  const requestState = useGetProvisionState(requestId)
  const requestCompleted = useMemo(() => {
    if (!requestState.query.data) {
      return null;
    }
    return requestState.query.data.hasCompleted && requestState.query.data.iridiumContractActivated;
  }, [requestState.query.data])

  if (!ffEnabled || !(query.data?.items.some(x => x.deviceId === device.id) ?? false)) {
    return <></>;
  }

  return (
    <SettingsSection title={t('title')} description={t('description')}>
      <DetailPanel p={3} spacing={3}>
        {!!requestId && requestCompleted && (
          <Alert severity="success" icon={<Done />}>
            <AlertTitle>{t('messages.completed')}</AlertTitle>
          </Alert>)}
        {!!requestId && !requestCompleted && (
          <Alert severity="info" icon={<Done />}>
            <AlertTitle>{t('messages.pending')}</AlertTitle>
          </Alert>)}
        {(error || requestState.query.data?.hasError) && (
          <Alert severity="error" icon={<Done />}>
            <AlertTitle>{t('messages.error')}</AlertTitle>
          </Alert>)}
        <Box>
          <Button
            variant="contained"
            onClick={() => handleActivation(device.id)}
            sx={theme => ({ padding: theme.spacing(2, 6), fontSize: '1rem' })}
            disabled={!!requestId || error}
          >
            {t('button')}
          </Button>
        </Box>
      </DetailPanel>
    </SettingsSection>
  );
};
