import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import {
  useGetAmsSettings,
  useGetIncidentEscalationSettings,
  useSetIncidentEscalationSettings
} from 'apis/rest/assets/hooks';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import useSnackbar from 'hooks/useSnackbar';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocale, useTranslations } from 'use-intl';

const timeOptions = [1, 2, 4, 6, 8, 12, 24, 36, 48];

const AssetIncidentEscalationView = ({ asset }: { asset: AssetBasic }): JSX.Element => {
  const t = useTranslations('pages.assetView.incidentSuppression');
  const hasDevice = asset.deviceId !== null;
  const incidentSettingsQuery = useGetIncidentEscalationSettings(asset.id, { enabled: hasDevice });
  const updateIncidentEscalation = useSetIncidentEscalationSettings();
  const snackbar = useSnackbar();
  const [time, setTime] = useState<number>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const locale = useLocale();

  const amsSettingsQuery = useGetAmsSettings(asset.id, { enabled: hasDevice });
  const isDeregisteredFromAms = amsSettingsQuery.data === undefined && !amsSettingsQuery.isLoading;

  const handleTimeChange = (e: SelectChangeEvent) => {
    const num = parseInt(e.target.value, 10);
    if (!Number.isNaN(num)) {
      setTime(num);
    }
  };

  const toSuspendUntil = useMemo(() => {
    if (time) {
      return DateTime.now()
        .plus({ hours: time || 0 })
        .startOf('minute');
    }
    return undefined;
  }, [time]);

  const suspendMonitoring = useCallback(() => {
    if (!toSuspendUntil || !isDeregisteredFromAms) { return; }
    setDialogOpen(false);
    updateIncidentEscalation.mutate({ assetId: asset.id, suspendedUntil: toSuspendUntil.toISO(), enabled: true }, {
      onSuccess: () => snackbar.display({ id: 'incidentEsclationSuspended', type: 'success', text: t('incidentEscalationSuspended') }),
      onError: () => snackbar.display({ id: 'incidentEsclationSuspendFailed', type: 'error', text: t('incidentEscalationSuspendFailed') }),
    });
  }, [asset.id, toSuspendUntil, isDeregisteredFromAms, updateIncidentEscalation, snackbar, t]);

  const resumeMonitoring = useCallback(() => {
    updateIncidentEscalation.mutate({ assetId: asset.id, suspendedUntil: undefined, enabled: true }, {
      onSuccess: () => snackbar.display({ id: 'incidentEsclationResumed', type: 'success', text: t('incidentEscalationResumed') }),
      onError: () => snackbar.display({ id: 'incidentEsclationResumeFailed', type: 'error', text: t('incidentEscalationResumeFailed') }),
    });
  }, [asset.id, t, snackbar, updateIncidentEscalation]);

  if (incidentSettingsQuery.error?.response.status === 404) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel>
          <Typography variant="h2" width="100%" textAlign="center" py={4} color="common.text">
            {t('noDeviceError')}
          </Typography>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (!incidentSettingsQuery.data) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel>
          <Typography variant="h2" width="100%" textAlign="center" py={2} color="common.text"><LoadingIcon size={50} /></Typography>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (incidentSettingsQuery.data.suspendedUntil) {
    const suspendedTo = DateTime.fromISO(incidentSettingsQuery.data.suspendedUntil);
    if (suspendedTo > DateTime.now()) {
      return (
        <SettingsSection title={t('title')} description={t('description')}>
          <DetailPanel px={3}>
            <Stack direction="row" height="4rem" justifyContent="space-between">
              <Stack justifyContent="center">
                <Typography variant="h3" lineHeight="1.8rem">
                  <span style={{ display: 'inline-block' }}>{t('isNotMonitored')}&nbsp;</span>
                  <span style={{ display: 'inline-block' }}>{suspendedTo.setLocale(locale).toLocaleString(DateTime.DATETIME_FULL)}</span>
                </Typography>
              </Stack>
              <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => resumeMonitoring()}>
                {updateIncidentEscalation.isPending ? <CircularProgress size="2rem" color="secondary" /> : t('enableMonitoring')}
              </Button>
            </Stack>
          </DetailPanel>
        </SettingsSection>
      );
    }
  }

  return (
    <>
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel px={3}>
          <Stack sx={{ minHeight: '4rem' }} justifyContent="space-between" spacing={3}>
            <Stack justifyContent="center" spacing={2}>
              <Typography variant="h3" width="100%">{t('isMonitored')}</Typography>
              <Typography width="100%">{t('isMonitoredDescription')}</Typography>
            </Stack>
            <Stack direction="row" sx={{ minHeight: '4rem' }} spacing={3} justifyContent="right">
              <Collapse in={!isDeregisteredFromAms} sx={{ flex: 1 }}>
                <Alert severity="info" sx={{ alignItems: 'center' }}>
                  {t('suppressDisabledAlert')}
                </Alert>
              </Collapse>
              <FormControl>
                <InputLabel id="demo-simple-select-label">{t('suspendFor')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={time?.toString() || ''}
                  label={t('suspendFor')}
                  sx={{ minWidth: '10rem' }}
                  disabled={!isDeregisteredFromAms}
                  onChange={handleTimeChange}
                >
                  {timeOptions.map(n => <MenuItem value={n} key={n}>{t('timeHours', { hours: n })}</MenuItem>)}
                </Select>
              </FormControl>
              <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => setDialogOpen(true)} color="warning" disabled={!time || !isDeregisteredFromAms}>
                {updateIncidentEscalation.isPending ? <CircularProgress size="2rem" color="secondary" /> : t('suspend')}
              </Button>
            </Stack>
          </Stack>
        </DetailPanel>
      </SettingsSection>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {t('confirmSuspendTitle')}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              {t('confirmSuspendDescription')}
            </Typography>
            <Typography textAlign="center" variant="h2">
              {
                toSuspendUntil
                  ?.setLocale(locale)
                  .toLocaleString(DateTime.DATETIME_FULL)
                || 'error'
              }
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={theme => ({ p: 3, borderTop: theme.border.default })}>
          <Stack direction="row" spacing={3} height="4rem">
            <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => setDialogOpen(false)} disabled={!time}>
              {t('cancel')}
            </Button>
            <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => suspendMonitoring()} color="warning" disabled={!time}>
              {updateIncidentEscalation.isPending ? <CircularProgress size="2rem" color="secondary" /> : t('suspend')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssetIncidentEscalationView;
