import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslations } from 'use-intl';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import Header from 'components/header';
import StatusBar from 'components/statusbar';
import Navigation from 'components/navigation';
import { ClassNameMap } from '@mui/styles';
import useStyles from './page-styles';
import { useUiSettings } from 'hooks/settings/useUiSettings';

interface PageProps {
  children: React.ReactNode,
  title?: string,
}

const useTitle = (title?: string): string => {
  const t = useTranslations('pageTitles');
  const location = useLocation();

  if (title) return title;

  console.warn(`Page at ${location.pathname} should be given a title`);

  // TODO: remove this fallback code once all pages provide their title
  let key = location.pathname.substring(1).replaceAll('/', '-');

  if (t.has(key as any)) return t(key as any);
  return '';
};

const Page = ({
  children,
  title,
}: PageProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();

  const titleText = useTitle(title);
  const isDisplayMode = useUiSettings().displayMode ?? false;
  const showBars = useMemo(() => !isDisplayMode, [isDisplayMode])
  return (
    <>
      <Helmet>
        <title>{titleText ? `${titleText} - TracPlus Cloud` : 'TracPlus Cloud'}</title>
      </Helmet>

      <Grid className={classes.gridContainer} container spacing={0} direction="column">
        {showBars && (<Grid item xs={12} className={classes.headerBar}>
          <Header />
        </Grid>)}

        <Grid item xs={12} className={!showBars ? classes.contentContainerDisplayMode : classes.contentContainer}>
          {showBars && (<Box className={classes.navWrapper}>
            <Navigation />
          </Box>)}

          <Box className={!showBars ? classes.contentDisplayMode : classes.content}>
            {children}
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.footerBar}>
          <StatusBar />
        </Grid>
      </Grid>
    </>
  );
};

export default Page;
