import {Box, Button, Paper, Stack, Typography} from '@mui/material';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';
import React, { useState } from 'react';
import { useTranslations } from 'use-intl';

interface RemoveMapListEntryProps {
  onDelete: () => void;
  isLoading: boolean;
  entryType: string;
  captionText?: string;
  deleteButtonText?: string;
  dialogTitleText?: string;
  dialogConfirmText?: string;
  deletePendingText?: string;
  dialogCancelText?: string;
  dialogBodyText?: string;
}

const RemoveMapListEntry = ({
  onDelete,
  isLoading,
  entryType,
  captionText,
  deletePendingText,
  deleteButtonText,
  dialogCancelText,
  dialogBodyText,
  dialogTitleText,
  dialogConfirmText,
}: RemoveMapListEntryProps) => {
  const [open, setOpen] = useState(false);
  const t = useTranslations('shared.deleteDialog');

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography fontWeight={700}>
          {captionText ?? t('caption', { type: entryType })}
        </Typography>
        <Button
          color="error"
          variant="outlined"
          sx={{ minWidth: '10rem', height: '4rem' }}
          onClick={() => setOpen(true)}
          disabled={open || isLoading}
        >
          {deleteButtonText ?? t('deleteButtonText')}
        </Button>
      </Stack>
      <ConfirmationDialog
        confirmText={isLoading ? (deletePendingText ?? t('pendingText')) : (dialogConfirmText ?? t('confirmText'))}
        title={dialogTitleText ?? t('title', { type: entryType })}
        confirmButtonProps={{
          color: 'error',
          variant: 'contained',
          size: 'large',
          sx: { minWidth: '10rem' },
          disabled: isLoading,
        }}
        cancelButtonProps={{
          color: 'primary',
          variant: 'outlined',
          size: 'large',
          sx: { minWidth: '10rem' },
          disabled: isLoading,
        }}
        cancelText={dialogCancelText ?? t('cancelText')}
        onConfirm={() => onDelete()}
        onCancel={() => setOpen(false)}
        dialogProps={{ open }}
      >
        <Box my={3}>{dialogBodyText ?? t('bodyText', { type: entryType.toLowerCase() })}</Box>
      </ConfirmationDialog>
    </Paper>
  );
};

export default RemoveMapListEntry;
