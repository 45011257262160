import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  Cancel,
  CenterFocusStrong,
  CropFree,
  Delete,
  Edit,
  Visibility,
  VisibilityOff,
  Save,
} from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import DeleteDialog from 'components/dialogs/markers/deleteDialog';
import EditDialog from 'components/dialogs/markers/editDialog';
import usePermissions from 'hooks/session/usePermissions';
import { Marker } from 'apis/rest/markers/types';
import { useCreateMarkers, useDeleteMarker, useUpdateMarker } from 'apis/rest/markers/hooks';
import Coordinate from 'components/shared/coordinate';
import { GeographicCoordinates } from 'components/pages/manage/markers/types';
import { LatitudeInput, LongitudeInput } from 'components/shared/CoordinatesInput';
import validateLatLong from 'helpers/validation/validateLatLong';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import markerConverter from 'services/markerConverter';
import useSnackbar from 'hooks/useSnackbar';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlag } from 'components/shared/featureFlag';

interface MarkersRowProps {
  marker: Marker;
  isFocused: boolean;
  onFocus: (id: number) => void;
  isHidden: boolean;
  onToggleHidden: (id: number) => void;
  onEdit: () => void;
  onDelete: () => void;
  loading?: boolean;
}

const MarkersRow = ({
  marker,
  isFocused,
  onFocus,
  isHidden,
  onToggleHidden,
  onEdit,
  onDelete,
  loading,
}: MarkersRowProps) => {
  const t = useTranslations('pages.manage.markers.table');
  const permissions = usePermissions();

  return (
    <TableRow>
      <TableCell>
        <Stack direction="row" spacing={2} sx={{ height: '100%' }} alignItems="center">
          <Box
            sx={{
              backgroundColor: marker.colour,
              width: '1rem',
              height: '1rem',
              borderRadius: 1,
              flexShrink: 0,
            }}
          />
          <Stack>
            <Typography variant="h6">{marker.name}</Typography>
            <Typography variant="body2">
              <Coordinate Component="span" latitude={marker.latitude} longitude={marker.longitude} />
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" justifyContent="end" spacing={1}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {!isHidden && (
                <Tooltip title={isFocused ? t('actions.unfocus') : t('actions.focus')}>
                  <IconButton onClick={() => onFocus(marker.id)}>
                    {isFocused ? <CropFree /> : <CenterFocusStrong />}
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={isHidden ? t('actions.show') : t('actions.hide')}>
                <IconButton onClick={() => onToggleHidden(marker.id)}>
                  {isHidden ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
              {permissions.canEditMarkers && (
                <>
                  <Tooltip title={t('actions.edit')}>
                    <IconButton onClick={onEdit}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('actions.delete')}>
                    <IconButton onClick={onDelete}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

interface MarkersEditProps {
  markers: Marker[];
  focusedMarker?: Marker;
  onFocus: (id: number) => void;
  hiddenMarkers: number[];
  onToggleHidden: (id: number) => void;
  isCreatingNew: boolean;
  onCreateNewMarker: (coordinates: GeographicCoordinates) => void;
  onToggleCreateNew: () => void;
}

const MarkersEdit = ({
  markers,
  focusedMarker,
  onFocus,
  hiddenMarkers,
  onToggleHidden,
  isCreatingNew,
  onCreateNewMarker,
  onToggleCreateNew,
}: MarkersEditProps) => {
  const t = useTranslations('pages.manage.markers');
  const permissions = usePermissions();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [dialogMarker, setDialogMarker] = useState<Marker | undefined>();
  const [deleteDialogMarker, setDeleteDialogMarker] = useState<Marker | undefined>();
  const [pendingMarkerPosition, setPendingMarkerPosition] = useState<GeographicCoordinates>({ latitude: undefined, longitude: undefined });

  const bulkCreateMutation = useCreateMarkers();
  const updateMutation = useUpdateMarker();
  const deleteMutation = useDeleteMarker();

  const snackbar = useSnackbar();

  const { coordinate } = useUnitSettings();

  const coordFormat = () => {
    switch (coordinate) {
      case 'coordinatesDMS':
        return 'dms';
      case 'coordinatesDDM':
        return 'dm';
      default:
        return 'n';
    }
  };

  const coordinateValidation = useMemo(
    () => validateLatLong(pendingMarkerPosition),
    [pendingMarkerPosition]
  );

  const handleOnSubmit = (marker: Marker) => updateMutation.mutate({
    markerId: marker.id,
    marker,
  }, {
    onSuccess: () => setDialogMarker(undefined),
  });

  const handleOnDelete = (markerId: number) => deleteMutation.mutate({ markerId });

  const handleFileUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const extension = file.name.split('.').pop()?.toLowerCase();

      if (extension === 'xml') {
        console.log('Reading Markers file...');
        const reader = new FileReader();
        reader.onload = e => {
          if (e.target && typeof e.target.result === 'string') {
            try {
              const markers = markerConverter(e.target.result);
              console.log(file.name, markers);
              bulkCreateMutation.mutate({ markers }, {
                onSuccess: (newMarkers) => {
                  snackbar.display({
                    type: 'success',
                    text: 'Markers uploaded successfully',
                    id: `markersUpload.${file.name}${newMarkers.map(m => m.id).join(',')}`,
                  });
                  onToggleCreateNew();
                },
              });
            } catch (e) {
              console.error('Error parsing markers', e);
              snackbar.display({
                type: 'error',
                text: 'Error parsing markers',
                id: `markersUpload.${file.name}${Date.now()}`,
              });
            }
          }
        };
        reader.readAsText(file);
      } else {
        console.warn(`Unknown extension: ${extension}. Only XML files are supported.`);
        snackbar.display({
          type: 'error',
          text: `Unknown extension: ${extension}. Only XML files are supported.`,
          id: `markersUpload.${file.name}`,
        });
      }
    }
  };

  useEffect(() => {
    if (!isCreatingNew) {
      setPendingMarkerPosition({ latitude: undefined, longitude: undefined });
    }
  }, [isCreatingNew]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (monitor && !bulkCreateMutation.isPending) {
        const { files } = monitor.getItem<{ files: FileList }>();
        handleFileUpload(files);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  });

  return (
    <Paper elevation={0} sx={{
      maxHeight: 800,
      overflowY: 'scroll',
    }}>
      <Table ref={drop}>
        <TableHead>
          <TableRow>
            {permissions.canEditMarkers && (
              <TableCell colSpan={2}>
                {isCreatingNew ? (
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Typography>{t('instruction')}</Typography>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                      <Button
                        variant="contained"
                        startIcon={<Save />}
                        disabled={!coordinateValidation.valid}
                        onClick={() => onCreateNewMarker(pendingMarkerPosition)}
                      >
                        {t('actions.save')}
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<Cancel />}
                        onClick={onToggleCreateNew}
                      >
                        {t('actions.cancel')}
                      </Button>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    <Button variant="contained" startIcon={<Add />} onClick={onToggleCreateNew}>{t('actions.create')}</Button>
                  </Stack>
                )}
                <Collapse in={isCreatingNew}>
                  <Stack direction="row" spacing={3} alignItems="top" sx={{ mt: 2, maxWidth: '80%' }}>
                    <LatitudeInput
                      label={t('fields.latitude')}
                      size="small"
                      value={pendingMarkerPosition?.latitude}
                      onChangeValue={value => setPendingMarkerPosition({ ...pendingMarkerPosition, latitude: value })}
                      displayFormat={coordFormat()}
                      fullWidth
                    />
                    <LongitudeInput
                      label={t('fields.longitude')}
                      size="small"
                      value={pendingMarkerPosition?.longitude}
                      onChangeValue={value => setPendingMarkerPosition({ ...pendingMarkerPosition, longitude: value })}
                      displayFormat={coordFormat()}
                      fullWidth
                    />
                  </Stack>
                  <>
                    <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>or</Typography>
                    <Paper
                      sx={{
                        marginTop: '12px',
                        textAlign: 'center',
                        bgcolor: isOver ? '#4A525C' : undefined,
                        cursor: 'pointer',
                      }}
                      ref={drop}
                      variant="outlined"
                      onClick={() => !bulkCreateMutation.isPending && fileInputRef.current?.click()}
                    >
                      {bulkCreateMutation.isPending ? (
                        <CircularProgress sx={{ my: 5 }} />
                      ) : (
                        <>
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept=".xml"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileUpload(e.target.files)}
                          />

                          <Typography style={{ userSelect: 'none', marginTop: '8px', whiteSpace: 'pre-wrap' }}>{(canDrop && isOver) ? t('dropFile') : t('dropFilesHere')}</Typography>
                          <Typography style={{
                            fontSize: '64px', opacity: '0.4', userSelect: 'none', marginTop: '-18px'
                          }}
                          >+
                          </Typography>
                        </>
                      )}
                    </Paper>
                  </>
                </Collapse>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {markers
            .map((marker: Marker) => (
              <MarkersRow
                key={marker.id}
                marker={marker}
                isFocused={focusedMarker?.id === marker.id}
                onFocus={onFocus}
                isHidden={hiddenMarkers.includes(marker.id)}
                onToggleHidden={onToggleHidden}
                onEdit={() => setDialogMarker(marker)}
                onDelete={() => setDeleteDialogMarker(marker)}
                loading={(updateMutation.isPending && updateMutation.variables?.markerId === marker.id) || (deleteMutation.isPending && deleteMutation.variables?.markerId === marker.id)}
              />
            ))}
        </TableBody>
      </Table>
      {permissions.canEditMarkers && (
        <>
          <EditDialog
            open={dialogMarker !== undefined}
            title={t('dialogs.edit.title', { name: dialogMarker?.name ?? '' })}
            latLng={dialogMarker ? { latitude: dialogMarker.latitude, longitude: dialogMarker.longitude } : undefined}
            marker={dialogMarker}
            onSubmit={handleOnSubmit}
            onClose={() => setDialogMarker(undefined)}
            isLoading={updateMutation.isPending}
          />
          <DeleteDialog
            open={deleteDialogMarker !== undefined}
            marker={deleteDialogMarker}
            onSubmit={handleOnDelete}
            onClose={() => setDeleteDialogMarker(undefined)}
          />
        </>
      )}
    </Paper>
  );
};

export default MarkersEdit;
