import { organisationQueryKeys } from "./queryKeys";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useOrganisationId from "hooks/session/useOrganisationId";
import { getOrganisationDetails, updateOrganisationDetails } from "./request";
import { OrganisationUpdateDetails } from "./types";

export const useGetOrganisationDetails = () => {
  const organisationId = useOrganisationId();
  const queryKey = organisationQueryKeys.details(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getOrganisationDetails(organisationId),
  });
  return { query, queryKey };
};

export const useUpdateOrganisationDetails = (onSuccess?: () => void, onError?: () => void) => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationFn: (organisation: OrganisationUpdateDetails) => updateOrganisationDetails(organisationId, organisation),
    onMutate: (organisation) => {
      const previousOrganisation = queryClient.getQueryData(organisationQueryKeys.details(organisationId)) as Organisation;
      queryClient.setQueryData(organisationQueryKeys.details(organisationId), (old: Organisation) => ({
        ...old,
        ...organisation,
      }));
      return { previousOrganisation };
    },
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (error, organisation, context) => {
      const { previousOrganisation } = context as { previousOrganisation: Organisation };
      queryClient.setQueryData(organisationQueryKeys.details(organisationId), previousOrganisation);
      onError?.();
    },
  });
  return { query };
};
