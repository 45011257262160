// helper function to calculate the map bounds given a collection of coordinates

// Latitude in degrees clamped to [-90, 90]
// Longitude in degrees wrapped within [-180, 180]
import {clampNumber} from "helpers/numberUtils";
import {GeographicCoordinates} from "components/pages/manage/markers/types";

export interface MapBounds {
  minBounds: GeographicCoordinates
  maxBounds: GeographicCoordinates
}

const MAX_LATITUDE = 90;
const MIN_LATITUDE = -90;
const MAX_LONGITUDE = 180;
const MIN_LONGITUDE = -180;

export const getMapBoundsForCoords = (coords: GeographicCoordinates[]): [[number, number], [number, number]] => {
  const defaultBounds: MapBounds = {
    minBounds: {
      latitude: MAX_LATITUDE,
      longitude: MAX_LONGITUDE,
    },
    maxBounds: {
      latitude: MIN_LATITUDE,
      longitude: MIN_LONGITUDE,
    }
  }
  const mapBounds = coords.reduce<MapBounds>((bounds, c) => {
    if (c.latitude === undefined || c.longitude === undefined) {
      return bounds;
    }
    const safeLatitude = clampNumber(MIN_LATITUDE, MAX_LATITUDE, c.latitude);
    const safeLongitude = clampNumber(MIN_LONGITUDE, MAX_LONGITUDE, c.longitude);

    return {
      minBounds: {
        latitude: Math.min(safeLatitude, bounds.minBounds.latitude ?? MAX_LATITUDE),
        longitude: Math.min(safeLongitude, bounds.minBounds.longitude ?? MAX_LONGITUDE),
      },
      maxBounds: {
        latitude: Math.max(safeLatitude, bounds.maxBounds.latitude ?? MIN_LATITUDE),
        longitude: Math.max(safeLongitude, bounds.maxBounds.longitude ?? MIN_LONGITUDE),
      }
    }
  }, defaultBounds)

  return [
    [
      mapBounds.minBounds.longitude ?? MAX_LONGITUDE
    , mapBounds.minBounds.latitude ?? MAX_LATITUDE
    ], [
      mapBounds.maxBounds.longitude ?? MIN_LONGITUDE,
      mapBounds.maxBounds.latitude ?? MIN_LATITUDE
    ]
  ]
}