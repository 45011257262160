import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Fab,
  Typography,
  Tooltip, Paper, IconButton, InputBase
} from '@mui/material';
import { Chat, Search as SearchIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useConversations } from 'apis/rest/conversations/conversationHooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslations } from 'use-intl';
import Page from 'components/pages/page';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import { useAssetLabel } from 'components/shared/assetLabel';
import { ClassNameMap } from '@mui/styles';
import uuid from 'utils/uuid';
import { useAppDispatch } from 'store/types';
import { useSelector } from 'react-redux';
import { markRead, textMessagesSelector } from 'slices/report.slice';
import Preview from './preview';
import ConversationView from './conversation';
import ParticipantNameListItem from './participantNameListItem';
import useStyles from './messaging-styles';
import NewConversationBar from './newConversationBar';
import { displaySnackbar } from 'slices/app.slice';
import { selectUser } from 'slices/session/session.slice';
import useOrganisationId from 'hooks/session/useOrganisationId';

const selectAssetsByDeviceId = (assets: AssetBasic[]) => assets.reduce<Record<number, AssetBasic>>((acc, asset) => {
  if (typeof asset.deviceId === 'number') acc[asset.deviceId] = asset;
  return acc;
}, {});

const MessagingPage = () => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslations('pages.messaging');
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const assetLabel = useAssetLabel();
  const [search, setSearch] = useState('');
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversationId, selectConversationId] = useState<string | null | undefined>(null);
  const assetsByDeviceIdQuery = useGetAssetsList({ select: selectAssetsByDeviceId }).query;
  const textMessages = useSelector(textMessagesSelector);
  const organisationId = useOrganisationId();
  const userId = useSelector(selectUser)!.id;

  const conversationsQuery = useConversations();

  useEffect(() => {
    if (conversationsQuery.isError) {
      dispatch(displaySnackbar({ id: 'getConversationsFailed', text: t('getConversationsFailed'), type: 'error' }));
    }
  }, [conversationsQuery.isError, t]);

  useEffect(() => {
    if (conversationsQuery.data) {
      const optimisticConversations = conversations.filter(c => !c.latestMessage && !conversationsQuery.data.find(d => d.deviceId === c.deviceId));
      setConversations([...optimisticConversations, ...conversationsQuery.data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationsQuery.data]);

  useEffect(() => {
    const data = queryClient.getQueryData<Conversation[]>([organisationId, 'conversations']);
    setConversations(data ?? []);
    selectConversationId(null);
  }, [organisationId, queryClient]);

  const createConversation = (participant: Participant): Conversation => {
    const asset = assetsByDeviceIdQuery.data?.[participant.deviceId];
    const newConvo: Conversation = {
      deviceId: participant.deviceId,
      assetName: assetLabel(asset),
      id: uuid(),
      latestMessage: null,
      participants: [participant],
      readCursor: null
    };
    setConversations(convos => [...convos, newConvo]);
    return newConvo;
  };

  // TODO should be useMemo?
  conversations.sort((a, b) => {
    if (!a.latestMessage) return -1; // New conversations go at start
    if (!b.latestMessage) return 1; // Same but for right-hand side of sort
    return b.latestMessage.timestamp - a.latestMessage.timestamp;
  });
  const selectedConversation = useMemo(() => {
    const selectedConv = conversations.find(c => c.id === selectedConversationId);
    // select the first conversation by default (on page load)
    if (!selectedConv && conversations.length > 0) {
      selectConversationId(conversations[0].id);
      return null;
    }
    return selectedConv;
  }, [conversations, selectedConversationId]);

  const [creatingConversation, setCreatingConversation] = useState(false);

  const createNewConversation = (participant: Participant): void => {
    const convo: Conversation = createConversation(participant);
    setCreatingConversation(false);
    selectConversationId(convo.id);
  };

  const assetParticipant = selectedConversation?.participants.find(p => p.type === 'asset');
  const recipients = assetParticipant ? [assetParticipant] : selectedConversation?.participants.filter(p => p.id !== userId);

  const matchConversations = (c: Conversation): boolean => {
    const lowercaseFilter = search?.trim().toLowerCase();
    if (!lowercaseFilter) return true;
    const latestMessageMatch = c.latestMessage?.content?.toLowerCase().includes(lowercaseFilter);
    const senderMatch = c.participants.find(p => {
      const asset = assetsByDeviceIdQuery.data?.[p.deviceId];
      return [
        asset?.name,
        asset?.callSign,
        asset?.tailNumber,
        asset?.make,
        asset?.model,
        p.imei,
        p.manufacturerSerial,
        p.tpSerial,
        p.ownerId,
      ].some(s => (s ? s.toLowerCase().includes(lowercaseFilter) : false));
    });
    return !!(latestMessageMatch || senderMatch);
  };

  return (
    <Page>
      <Box className={classes.container}>
        <Box className={classes.left}>
          <Box className={classes.conversationHeader}>
            <Paper className={classes.omniSearch}>
              <IconButton className={classes.searchIcon} aria-label="Search" size="large">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                type="search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={t('findConversation')}
              />
            </Paper>
            <Tooltip title={t('newConversation')}>
              <Fab
                color="primary"
                aria-label="chat"
                className={classes.newConversation}
                // className={clsx({ [classes.hidden]: !permissions.canCreateNewConversation })}
                onClick={(): void => setCreatingConversation(true)}
                size="medium"
                // disabled={!permissions.canCreateNewConversation}
                style={{ alignSelf: 'center' }}
              >
                <Chat />
              </Fab>
            </Tooltip>
          </Box>
          <Box className={classes.conversationWrapper}>
            {conversationsQuery.isLoading
              ? (<NoHeaderNoFooterLoadingPage />)
              : conversations.filter(matchConversations).map(conv => (
                // Conversation can't exist without a message
                <Preview
                  key={conv.id}
                  userId={userId}
                  // TODO: uncomment this when we get readCursor's back to set unread styling
                  isSeen={!textMessages[conv.deviceId]} // {conv.readCursor === conv.latestMessage?.id}
                  participants={conv.participants}
                  msg={conv.latestMessage}
                  handleClick={(): void => {
                    setCreatingConversation(false);
                    selectConversationId(conv.id);
                    dispatch(markRead({ deviceId: conv.deviceId }));
                  }}
                  isSelected={conv.id === selectedConversationId}
                  assetsByDeviceId={assetsByDeviceIdQuery.data}
                />
              ))}
          </Box>
        </Box>
        <Box className={classes.right}>
          <div className={classes.conversationContainer}>
            <div className={clsx(classes.messageHeader, { [classes.messageHeaderWithAutocomplete]: creatingConversation })}>
              {creatingConversation
                ? (
                  <NewConversationBar
                    // @ts-ignore
                    createNewConversation={createNewConversation}
                    conversations={conversations}
                    selectConversationId={selectConversationId}
                    setCreatingConversation={setCreatingConversation}
                    assetsByDeviceId={assetsByDeviceIdQuery.data}
                  />
                )
                : selectedConversation && (
                  <Typography variant="h4" style={{ textAlign: 'left' }}>
                    {recipients?.map((p, index) => {
                      const addComma = index < recipients.length - 1;
                      // @ts-ignore
                      return (
                        <ParticipantNameListItem
                          key={p.id}
                          // @ts-ignore - until we make Smart component type-safe
                          participant={p}
                          showDetails
                          addComma={addComma}
                          asset={assetsByDeviceIdQuery.data?.[p.deviceId]}
                        />
                      );
                    })}
                  </Typography>
                )}
            </div>
            {selectedConversation && (
              <ConversationView
                conversation={selectedConversation}
                organisationId={organisationId}
                displaySnackbar={displaySnackbar}
                assetsByDeviceId={assetsByDeviceIdQuery.data}
              />
            )}
          </div>
        </Box>
      </Box>
    </Page>
  );
};

export default MessagingPage;
