import { serenityFetch } from "helpers/api";
import { ConversationsResponse, MessagesResponse, RestMessage } from "./conversationTypes";
import { mapTSConversation } from "apis/trackstar/maps";

// This is a mapping function to make the object that is returned from the rest endpoint compatible with what the view expected from the soap api
const MapToMessage = (receivedMessage: RestMessage): Message => {
  return {
    id: receivedMessage.id.toString(),
    conversationId: `conv-${receivedMessage.deviceId}`,
    sender: {
      id: receivedMessage.sender?.toString() ?? '',
      type: receivedMessage.isMO ? 'asset' : 'user',
      ownerId: receivedMessage.sender ?? '',
      name: receivedMessage.sender ?? '',
    },
    recipient: receivedMessage.recipient,
    status: '',
    timestamp: receivedMessage.received,
    content: receivedMessage.content,
    isInbound: receivedMessage.isMO,
  };
};

export const fetchMessagesByDeviceId = async (org: string, deviceId: number, cursor: number = 0, pageSize: number = 100): Promise<Message[]> => {
  const response = await serenityFetch<MessagesResponse>('GET', `/organisations/${org}/devices/${deviceId}/messages?cursor=${cursor}&pageSize=${pageSize}`, null);
  if (!response.ok) {
    throw new Error('Failed to fetch messages');
  }
  const data = await response.json() as MessagesResponse;
  return data.messages.map(MapToMessage);
};


export const fetchConversations = async (org: string): Promise<Conversation[]> => {
  const response = await serenityFetch<ConversationsResponse>('GET', `/organisations/${org}/conversations`, null);
  if (!response.ok) {
    throw new Error('Failed to fetch conversations');
  }
  const data = await response.json() as ConversationsResponse;
  return data.conversations.map(mapTSConversation);
};
