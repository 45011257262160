import { getMembershipsForCurrentUser } from 'apis/rest/memberships/requests';
import { setCustomAttribute } from 'helpers/newRelic';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useInjectedDependency } from 'hooks/useInjectedDependency';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { loginFailure, userLoaded } from 'slices/session/session.slice';
import type { UserData } from 'slices/session/types';
import { useAppDispatch } from 'store/types';

const UserLoader = (): null => {
  const dispatch = useAppDispatch();
  const { auth } = useInjectedDependency('firebaseService', d => d.inject());
  const navigate = useNavigate();

  const enableCheckDisplayName = useFeatureFlag('frontendCheckDisplayName');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      console.log('Firebase auth state changed', user?.uid);
      setCustomAttribute('firebaseUID', user?.uid ?? null, true);
      if (!user || enableCheckDisplayName === undefined) {
        return;
      }

      if (enableCheckDisplayName && !user.displayName) {
        navigate('/set-display-name');
      }

      console.time('fetchUser');
      const tokenResult = await user.getIdTokenResult(true);
      console.timeLog('fetchUser', 'got token');
      localStorage.setItem('bearerToken', tokenResult.token);
      const currentMemberships = await getMembershipsForCurrentUser();
      console.timeLog('fetchUser', 'got usercodes');
      console.timeLog('fetchUser', 'got global roles');
      console.timeEnd('fetchUser');

      const data: UserData = {
        id: user.uid,
        name: user.displayName,
        // biome-ignore lint/style/noNonNullAssertion: should always have an email
        email: user.email!,
        profilePicUrl: user.photoURL,
        isStaff: tokenResult.claims?.isStaff || false,
        memberOf: currentMemberships.map(m => ({
          id: m.organisationId,
          name: m.organisationName,
          role: m.role,
          publicKey: m.publicKey,
        })),
      };

      if (!(data.memberOf.length > 0)) {
        dispatch(loginFailure('contactSupport'));
      } else {
        dispatch(userLoaded(data));
      }
    });

    return unsubscribe;
  }, [auth, enableCheckDisplayName, navigate, dispatch]);

  return null;
};

export default UserLoader;
