import { Grid, Stack, TextField, Typography } from '@mui/material';
import { unlinkSSO } from 'apis/auth';
import DetailPanel from 'components/shared/DetailPanel';
import { TPButton } from 'components/shared/button/TPButton';
import SettingsSection from 'components/shared/settingsSection';
import { useStaff } from 'hooks/session/useStaff';
import React from 'react';
import { useTranslations } from 'use-intl';

interface UserProfileProps {
  name: string;
  onNameChange: (name: string) => void;
  email: string;
  onEmailChange: (name: string) => void;
  emailError: false | 'emailRequired' | 'invalidEmail';
  saveDisabled: boolean;
  onSave: () => void;
  onResetPassword: () => void;
  basicAuthentication?: boolean;
}

export const UserProfile = ({
  name,
  onNameChange,
  email,
  onEmailChange,
  emailError,
  saveDisabled,
  onSave,
  onResetPassword,
  basicAuthentication,
}: UserProfileProps) => {
  const t = useTranslations('pages.accountSettings');
  const isStaff = useStaff();

  return (
    <SettingsSection title={t('userTitle')} description={t('userDescription')}>
      <DetailPanel spacing={3}>
        <Grid container spacing={3} pr={6}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label={t('nameLabel')}
              value={name}
              onChange={evt => onNameChange(evt.target.value)}
              error={!name?.trim()}
              fullWidth
            />
            {!name?.trim() && (
              <Typography mt={1} color="red">
                {t('nameIsRequired')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={!basicAuthentication}
              variant="outlined"
              label={t('emailLabel')}
              value={email}
              onChange={evt => onEmailChange(evt.target.value)}
              error={!!emailError}
              fullWidth
            />
            {emailError && (
              <Typography mt={1} color="red">
                {t(emailError)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TPButton onClick={onSave} disabled={saveDisabled}>
              {t('userButton')}
            </TPButton>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            <TPButton variant="outlined" disabled={!!emailError || !basicAuthentication} onClick={onResetPassword}>
              {t('newPassword')}
            </TPButton>
          </Grid>
          {isStaff && (
            <Grid item xs={12} sm={12}>
              <Stack direction="row" gap={3} sx={{ justifyContent: 'start' }}>
                <TPButton onClick={() => unlinkSSO('google.com')} variant="destructive">
                  Unlink Google (STAFF ONLY)
                </TPButton>
                <TPButton onClick={() => unlinkSSO('microsoft.com')} variant="destructive">
                  Unlink Microsoft (STAFF ONLY)
                </TPButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </DetailPanel>
    </SettingsSection>
  );
};
