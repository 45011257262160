import { getRefreshedToken } from 'apis/auth';
import { DateTime } from 'luxon';
import { mapTSConversation, mapTSMessage, mapTrackstarReportToTPCReport } from './maps';
import { type SoapResult, objToXml, parseSOAP } from './soap-utils';

const SERENITY_SOAP_URL = import.meta.env.VITE_SERENITY_SOAP_URL;

/**
 * @deprecated
 */
const getSoapHeaders = (endpoint: string): Headers => {
  const headers = new Headers();
  headers.append('SOAPAction', `http://serenity.tracplus.com/services/${endpoint}`);
  headers.append('Content-Type', 'text/xml');
  return headers;
};

/**
 * @deprecated
 */
const getXMLBody = async (
  endpoint: string,
  extraXMLOptions?: object,
): Promise<string> => `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://serenity.tracplus.com/services/">
     <soapenv:Header/>
     <soapenv:Body>
        <ser:${endpoint}>
           <ser:userId>${localStorage.getItem('organisationId')}</ser:userId>
           <ser:user>${localStorage.getItem('organisationId')}</ser:user>
           <ser:password>${await getRefreshedToken()}</ser:password>
           ${objToXml(extraXMLOptions)}
        </ser:${endpoint}>
     </soapenv:Body>
  </soapenv:Envelope>`;

/**
 * Wraps a fetch and parsing call to serenity.
 *
 * @deprecated
 * @param endpoint The SOAP endpoint to call on /serenity.asmx
 * @param extraXMLOptions Extra xml options to provide aside from usercode/password
 * @returns parsed SOAP result, 4 levels down from root
 */
const fetchSerenity = async <T>(endpoint: string, extraXMLOptions?: object): Promise<SoapResult & T> => {
  const response = await fetch(`${SERENITY_SOAP_URL}?${endpoint}`, {
    method: 'POST',
    headers: getSoapHeaders(endpoint),
    body: await getXMLBody(endpoint, extraXMLOptions),
    redirect: 'follow',
    mode: 'cors',
  });
  if (!response.ok) throw new Error(`Failed to request serenity/${endpoint}.`);
  return parseSOAP<T>(await response.text());
};

// #-----------#
// # REPORTING #
// #-----------#
/**
 * @deprecated
 */
export const fetchMissionReports = async (pageSize = 5000, cursor = 0): Promise<MissionReport[]> => {
  const extraXMLOptions = { cursor, pageSize };

  const result = await fetchSerenity<{
    missionReports: { missionReport?: MissionReport[] };
  }>('getMissionReports', extraXMLOptions);
  const missionReports = result?.missionReports.missionReport || [];
  // reverse order by timestamp (newest messages first)
  return missionReports.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());
};

/**
 * @deprecated
 */
export const fetchEventReportsForDevice = async (
  deviceId: string,
  fromSeconds: number,
  untilSeconds: number,
  eventTypes: string,
): Promise<U1Report[]> => {
  const format = 'LL/dd/yyyy HH:mm:ss';
  const from = DateTime.fromSeconds(fromSeconds).toUTC().toFormat(format);
  const until = DateTime.fromSeconds(untilSeconds).toUTC().toFormat(format);

  const extraXMLOptions = {
    terminalId: deviceId,
    from,
    until,
    eventTypes,
  };

  const result = await fetchSerenity<{
    reports: { report?: TSReport[] };
  }>('getEventReportsForTerminal', extraXMLOptions);
  const reports = result?.reports.report ?? [];
  // reverse order by timestamp (newest messages first)
  return reports
    .map(mapTrackstarReportToTPCReport)
    .sort(
      (a, b) =>
        DateTime.fromISO(a.time, { zone: 'etc/UTC' }).toMillis() -
        DateTime.fromISO(b.time, { zone: 'etc/UTC' }).toMillis(),
    );
};
