import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';

interface GeofencesLayoutProps {
  pageHeading: React.ReactNode
  pageActions: React.ReactNode
  content: React.ReactNode
  map: React.ReactNode
  description: string
  limitHeight: boolean
}

const GeofencesLayoutView: React.FC<GeofencesLayoutProps> = ({ pageHeading, pageActions, content, map, description, limitHeight }) => {

  return (
    <SettingsMenuPageWrapper p={8} pb={0}>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="end" justifyContent="space-between" spacing={2} mb={3}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom sx={{ overflowWrap: 'break-word' }}>
              {pageHeading}
            </Typography>
            <Typography paragraph mb={0}>{description}</Typography>
          </Box>
          {pageActions}
        </Stack>
        <Box
          display="grid"
          minHeight={{ xs: 700, lg: 'auto' }}
          maxHeight={{ xs: limitHeight ? 'max(400px, calc(100vh - var(--header-bar-height) - var(--footer-bar-height) - 220px))' : undefined, lg: undefined }}
          gridTemplateColumns={{ xs: '1fr', lg: '450px 1fr' }}
          gridTemplateRows={theme => ({
            xs: '300px calc(100% - 300px)',
            lg: `max(400px, calc(100vh - var(--header-bar-height) - var(--footer-bar-height) - 220px - ${theme.spacing(4)}))`,
          })}
          gridTemplateAreas={{ xs: "'map' 'content'", lg: limitHeight ? "'content map'" : "'content map' 'content .'" }}
          columnGap={4}
          rowGap={4}
          mb={4}
        >
          <Box gridArea="map" position="relative">{map}</Box>
          <Box gridArea="content">{content}</Box>
        </Box>
      </Container>
    </SettingsMenuPageWrapper>
  );
};

export default GeofencesLayoutView;
