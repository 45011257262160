import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Base } from 'apis/rest/bases/bases-types';
import { isEqual } from 'lodash';
import {GeographicCoordinates} from "components/pages/manage/markers/types";

export type BasesFormData = Pick<Base, 'id' | 'name' | 'description' | 'assetIds' | 'notes'> & {coordinates: GeographicCoordinates};

export interface ManageBasesState {
  formData: BasesFormData;
  initialFormData: BasesFormData;
}

export const MANAGE_BASE_INITIAL_STATE: ManageBasesState = {
  formData: {
    id: '',
    name: '',
    description: '',
    assetIds: [],
    notes: [],
    coordinates: {},
  },
  initialFormData: {
    id: '',
    name: '',
    description: '',
    assetIds: [],
    notes: [],
    coordinates: {},
  },
};

export const manageBasesSlice = createSlice({
  name: 'manageBases',
  initialState: MANAGE_BASE_INITIAL_STATE,
  reducers: {
    createBase: () => MANAGE_BASE_INITIAL_STATE,
    editBase: (state, action: PayloadAction<Base | undefined>) => {
      if (action.payload) {
        state.formData = {
          id: action.payload.id,
          name: action.payload.name,
          description: action.payload.description,
          coordinates: {
            latitude: action.payload.latitude,
            longitude: action.payload.longitude,
          },
          notes: action.payload.notes,
          assetIds: action.payload.assetIds,
        };
        state.initialFormData = state.formData;
      } else {
        console.debug('Base not found with given ID');
        return MANAGE_BASE_INITIAL_STATE;
      }
    },
    resetBaseForm: state => {
      state.formData = state.initialFormData;
    },
    updateInitialForm: state => {
      state.initialFormData = state.formData;
    },
    setBaseName: (state, action: PayloadAction<BasesFormData['name']>) => {
      state.formData.name = action.payload;
    },
    setBaseDescription: (state, action: PayloadAction<BasesFormData['description']>) => {
      state.formData.description = action.payload;
    },
    setBaseLatitude: (state, action: PayloadAction<BasesFormData['coordinates']['latitude']>) => {
      state.formData.coordinates.latitude = action.payload;
    },
    setBaseLongitude: (state, action: PayloadAction<BasesFormData['coordinates']['longitude']>) => {
      state.formData.coordinates.longitude = action.payload;
    },
    setBaseNotes: (state, action: PayloadAction<BasesFormData['notes']>) => {
      state.formData.notes = action.payload;
    },
    setBaseAssetIds: (state, action: PayloadAction<BasesFormData['assetIds']>) => {
      state.formData.assetIds = action.payload;
    },
  },
  selectors: {
    selectFormData: state => state.formData,
    selectInitialFormData: state => state.initialFormData,
    selectHasChanges: state => {
      if (state.initialFormData.name !== state.formData.name) return true;
      if (state.initialFormData.description !== state.formData.description) return true;
      if (state.initialFormData.coordinates.longitude !== state.formData.coordinates.longitude) return true;
      if (state.initialFormData.coordinates.latitude !== state.formData.coordinates.latitude) return true;
      if (!isEqual(state.initialFormData.notes, state.formData.notes)) return true;
      if (!isEqual(state.initialFormData.assetIds, state.formData.assetIds)) return true;
    },
    selectValidations: state => {
      const validations = {
        name: state.formData.name.length > 0,
        latitude: state.formData.coordinates.latitude !== undefined,
        longitude: state.formData.coordinates.longitude !== undefined,
        all: false,
      };
      validations.all = validations.name && validations.latitude && validations.longitude;
      return validations;
    },
  },
});

export const { selectFormData, selectHasChanges } = manageBasesSlice.selectors;

export default manageBasesSlice.reducer;
