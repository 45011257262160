import { DndContext, type DragMoveEvent, useDraggable } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Box, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import useFeatureFlag from 'hooks/useFeatureFlag';
import mixpanel from 'mixpanel-browser';
import type React from 'react';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleAnalysisbox } from 'slices/ui.slice';
import { useAppDispatch, useAppSelector } from 'store/types';
import { useTranslations } from 'use-intl';
import Analysisbox from './analysisbox/analysisbox-view';
import { AnalysisBoxTimeline } from './timeline/AnalysisBoxTimeline';

const Wrapper = styled(Box)(({ theme }) => ({
  bottom: 0,
  width: '100%',
  height: 400,
  zIndex: 2,
  backgroundColor: theme.palette.background.overlay,
  display: 'flex',
  flexDirection: 'column',
}));

const Content = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  boxShadow: 'rgba(0, 0, 0, 0.1) -12px -12px 12px -12px',
  zIndex: 4,
  padding: '0 20px',
}));

const ResizerBox = styled(Box)(({ theme }) => ({
  height: 7,
  width: '100%',
  cursor: 'ns-resize!important', // !important is required since dnd-kit tries to override it
  padding: '4px 0 0',
  position: 'absolute',
  zIndex: '100',
  transition: 'all 0.15s',
  backgroundColor: theme.palette.background.overlay,
  '&:hover': {
    backgroundColor: theme.palette.primary.blue,
  },
}));

const CollapseButtonWrapper = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  padding: '0 100px 18px 100px',
  position: 'relative',
  backgroundColor: theme.palette.background.overlay,
}));

const CollapseButton = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  height: 30,
  margin: '-11px auto 0 auto',
  paddingTop: '6px',
  paddingBottom: '4px',
  paddingRight: '16px',
  paddingLeft: '16px',
  top: -20,
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  outline: '1px solid rgba(255,255,255,.2)',
  cursor: 'pointer',
  zIndex: -1,
  transition: 'all 0.05s',
  transform: 'translateY(7px) translateX(-75px)',
  '&:hover': {
    transform: 'translateY(1px) translateX(-75px)',
  },
}));

const Resizer: FC = () => {
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({ id: 'analysis-box-resizer' });

  return (
    <div ref={setNodeRef}>
      <ResizerBox {...listeners} {...attributes} />
    </div>
  );
};

interface AnalysisBoxContainerProps {
  selectedAsset: AssetBasic | null;
}

const AnalysisBoxContainer: FC<AnalysisBoxContainerProps> = ({ selectedAsset }) => {
  const t = useTranslations('analysisbox');

  const analysisboxOpen = useAppSelector(state => state.ui.analysisboxOpen);
  const highContrastControls = useSelector<ReduxState, ReduxState['settings']['map']['highContrastControls']>(
    state => state.settings.map.highContrastControls,
  );

  const dispatch = useAppDispatch();

  const timeline = useFeatureFlag('frontendAnalysisBoxTimeline');
  const [drawerHeight, setDrawerHeight] = useState(analysisboxOpen ? 400 : 0);
  const [initialHeight, setInitialHeight] = useState(analysisboxOpen ? 400 : 0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDragStart = () => {
    setInitialHeight(wrapperRef.current ? wrapperRef.current.offsetHeight : 400);
  };

  const handleDragMove = (e: DragMoveEvent) => {
    const newHeight = initialHeight - e.delta.y;
    if (newHeight > 182 && newHeight < window.innerHeight - 56) {
      setDrawerHeight(newHeight);
    }
  }

  const handleCloseButtonClick = useCallback(() => {
    dispatch(toggleAnalysisbox());
    mixpanel.track('Toggle Analysis Box', {
      open: !(analysisboxOpen && selectedAsset),
    });
    if (analysisboxOpen && selectedAsset) {
      setDrawerHeight(35);
    } else {
      setDrawerHeight(400);
    }
  }, [analysisboxOpen, selectedAsset, dispatch]);

  useEffect(() => {
    if (!analysisboxOpen) {
      setDrawerHeight(0);
    } else {
      setDrawerHeight(400);
    }
  }, [analysisboxOpen]);

  if (!timeline && !selectedAsset?.deviceId) {
    return null;
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      modifiers={[restrictToVerticalAxis]}
    >
      <Wrapper ref={wrapperRef} aria-label="analysisboxWrapper" sx={{ height: drawerHeight }}>
        <Resizer />
        <Content aria-label="analysisbox">
          <CollapseButtonWrapper aria-label="collapseButtonWrapper">
            <Tooltip
              title={analysisboxOpen ? t('collapsePanel') : t('expandPanel')}
              placement="top"
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        enabled: true,
                        offset: [0, 10],
                      },
                    },
                  ],
                },
              }}
            >
              <CollapseButton
                direction="row"
                spacing={1}
                aria-label="collapseButton"
                sx={{ backgroundColor: highContrastControls ? 'mapControls.bgHighContrast' : 'rgba(0,0,0,.4)' }}
                onClick={handleCloseButtonClick}
                role="button"
              >
                <Typography whiteSpace="nowrap" color="common.whiteConstant">
                  {timeline
                    ? t(analysisboxOpen ? 'timeline.hideTimeline' : 'timeline.showTimeline')
                    : t(analysisboxOpen ? 'hideReports' : 'showReports')}
                </Typography>
                <SvgIcon
                  aria-label="icon"
                  sx={{ transition: 'all 0.05s', transform: analysisboxOpen ? 'rotate(270deg)' : 'rotate(90deg)' }}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <polygon fill="#fff" points="10,13.4 4.6,8 10,2.6 11.4,4 7.4,8 11.4,12" />
                </SvgIcon>
              </CollapseButton>
            </Tooltip>
          </CollapseButtonWrapper>
          {analysisboxOpen && (
            <Box display="flex" flex="1" flexDirection="column" minHeight="0" aria-label="timelineWrapper">
              {timeline && <AnalysisBoxTimeline selectedAsset={selectedAsset} drawerHeight={drawerHeight} />}
              {!timeline && selectedAsset?.deviceId && (
                <Box flex="1" minHeight="0" display="flex">
                  <Analysisbox />
                </Box>
              )}
            </Box>
          )}
        </Content>
      </Wrapper>
    </DndContext>
  );
};

export default AnalysisBoxContainer;
