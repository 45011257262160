export interface AnalysisBoxColumn {
  dataKey: keyof AnalysisBoxReport;
  label: string;
  width: () => number;
  render: (row: AnalysisBoxReport) => string | number;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
