import React, { useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import DeletePersonDialog, { DeletePersonDialogStatus } from 'components/dialogs/people/deletePerson';
import { useMutatePerson } from 'apis/rest/people/hooks';
import useValidateName from 'hooks/people/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import { PersonLanguageCode } from 'constants/person';
import Fields from '../common/fields-view';

interface BasicDetailsProps {
  person?: Person
}

export const BasicDetails = ({ person }: BasicDetailsProps): JSX.Element => {
  const navigate = useNavigate();
  const t = useTranslation('pages.manage.person.basicDetails');
  const [name, setName] = useState<string>();
  const [role, setRole] = useState<string>();
  const [languageCode, setLanguageCode] = useState<PersonLanguageCode>();
  const snackbar = useSnackbar();

  const reset = (): void => {
    setName(undefined);
    setRole(undefined);
    setLanguageCode(undefined);
  };

  const mutation = useMutatePerson();

  const nameValidation = useValidateName(person ?? null, name);

  const savePerson = (): void => {
    if (!person) { return; }

    mutation.mutate(
      {
        id: person.id,
        name: name?.trim() ?? person.name,
        role: role?.trim() ?? person.role,
        languageCode: languageCode ?? person.languageCode,
        version: person.version,
      },
      {
        onSuccess: () => {
          snackbar.display({
            id: 'personUpdated',
            text: t('snackbar.updatedSuccessfully', { name }),
            type: 'success',
          });
          reset();
        },
        onError: error => {
          if (error.response.status === 409) {
            snackbar.display({
              id: 'personUpdatedError',
              text: t('snackbar.errorUpdating409'),
              type: 'warning',
            });
          }

          snackbar.display({
            id: 'personUpdatedError',
            text: t('snackbar.errorUpdating'),
            type: 'error',
          });
        },
      },
    );
  };

  let hasChanged = false;
  if (person) {
    if (nameValidation.changed) hasChanged = true;
    else if (languageCode !== undefined && languageCode !== person.languageCode) hasChanged = true;
    else if (role !== undefined && role !== (person.role ?? '')) hasChanged = true;
  }

  const canSave = hasChanged && nameValidation.valid && !nameValidation.isLoading;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const onDeletePerson = (status: DeletePersonDialogStatus) => {
    setIsDeleteDialogOpen(false);
    if (status === DeletePersonDialogStatus.Removed) navigate('/manage/people');
  };

  return (
    <SettingsSection title={t('title')}>
      <DetailPanel>
        <Stack spacing={3} mx={3}>
          <Stack direction="row" spacing={3}>
            <Fields
              name={name ?? person?.name ?? ''}
              setName={setName}
              role={role ?? person?.role ?? ''}
              setRole={setRole}
              languageCode={languageCode ?? person?.languageCode ?? PersonLanguageCode.EN}
              setLanguageCode={setLanguageCode}
              disabled={mutation.isPending}
              validation={nameValidation}
            />
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="flex-end" height="4rem">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={() => reset()}
              disabled={mutation.isPending || !hasChanged}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={() => savePerson()}
              disabled={mutation.isPending || !canSave}
            >
              {t(mutation.isPending ? 'saving' : 'save')}
            </Button>
          </Stack>
        </Stack>
      </DetailPanel>

      <DetailPanel mt={3}>
        <Stack direction="row" spacing={3} px={3} justifyContent="space-between" height="4em">
          <Stack justifyContent="center">
            <Typography>{t('removePerson')}</Typography>
          </Stack>
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            onClick={() => setIsDeleteDialogOpen(true)}
            color="error"
            disabled={mutation.isPending}
          >
            {t('remove')}
          </Button>
        </Stack>
      </DetailPanel>

      <DeletePersonDialog open={isDeleteDialogOpen} personId={person?.id} onClose={onDeletePerson} />
    </SettingsSection>
  );
};
