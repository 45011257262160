import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { Stack } from '@mui/material';
import { Navigate, useNavigate, useSearchParams } from 'react-router';
import type { MultiPolygon, Polygon } from 'geojson';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import usePermissions from 'hooks/session/usePermissions';
import { useCreateGeofence } from 'apis/rest/geofence/hooks';
import useSnackbar from 'hooks/useSnackbar';
import GeofencePropertiesTable from './GeofenceProperties';
import GeofenceImportModalView from '../import/GeofenceImportModal.view';
import type { GeofenceCreateTableDetails } from '../types';
import { getId, getValidMultiPolygon } from '../helpers';
import GeofenceBlocker from './GeofenceBlocker';
import GeofenceImport from '../import/GeofenceImport';
import { useFitMapToFeatures } from '../map/hooks';

const GeofenceCreate = () => {
  const t = useTranslations('pages.manage.geofencing');
  const dispatch = useAppDispatch();
  const permissions = usePermissions();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const displayImportModal = searchParams.has('import');

  const closeImportModal = useCallback(() => {
    setSearchParams(p => {
      p.delete('import');
      return p;
    });
  }, [setSearchParams]);

  const fitMap = useFitMapToFeatures();
  const fitAfterImport = useRef<boolean>(false);

  const onCompleteImport = useCallback((name: string, success: boolean) => {
    if (success) {
      fitAfterImport.current = true;
    } else {
      snackbar.display({
        id: `geofence.import.error.${getId()}`,
        type: 'error',
        text: t('snackbar.fileImportError', { name })
      });
    }
    closeImportModal();
  }, [closeImportModal, snackbar, t]);

  const { features } = useSelector(slice.selectors.selectMapDraw);

  useEffect(() => {
    if (!fitAfterImport.current) return;
    fitAfterImport.current = false;
    fitMap(features);
  }, [features, fitMap]);

  useEffect(() => {
    dispatch(slice.actions.createGeofence());
  }, [dispatch]);

  const createMutation = useCreateGeofence();

  const onSave = (data: GeofenceCreateTableDetails) => {
    if (features.length === 0) return;

    const coordinates = features.flatMap(f => (
      f.geometry.type === 'MultiPolygon' ? (f.geometry as MultiPolygon).coordinates : [(f.geometry as Polygon).coordinates]
    ));

    createMutation.mutate({
      name: data.name,
      description: data.description,
      category: data.category,
      altitudeRestriction: data.altitudeRestriction,
      geometry: getValidMultiPolygon({ type: 'MultiPolygon', coordinates })
    }, {
      onSuccess: () => {
        snackbar.display({
          id: `geofence.create.${getId()}`,
          type: 'success',
          text: t('snackbar.createSuccess', { name: data.name }),
        });
      },
      onError: () => {
        snackbar.display({
          id: `geofence.create.error.${getId()}`,
          type: 'error',
          text: t('snackbar.createError'),
        });
      },
    });
  };
  const onDiscard = () => navigate('..');

  const hasChanges = useSelector(slice.selectors.selectHasChanges);

  if (createMutation.isSuccess) return <Navigate to=".." />;

  return (
    <Stack spacing={3}>
      <GeofencePropertiesTable
        onSave={onSave}
        onDiscard={onDiscard}
        isViewOnly={!permissions.canEditGeofences}
        enableCancel
        isSaving={createMutation.isPending}
      />

      <GeofenceImport onComplete={onCompleteImport}>
        {onImport => (
          <GeofenceImportModalView
            open={displayImportModal}
            onCancel={closeImportModal}
            onConfirm={onImport}
          />
        )}
      </GeofenceImport>

      <GeofenceBlocker enabled={hasChanges} />
    </Stack>
  );
};

export default GeofenceCreate;
