import React, { useState } from 'react';
import { Dialog, DialogContent, Box } from '@mui/material';
import { useTranslations } from 'use-intl';
import { useAppDispatch } from 'store/types';
import { useNavigate } from 'react-router';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { TPButton } from 'components/shared/button/TPButton';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { leaveOrganisationAction } from 'slices/session/thunks';
import { setOrganisationIdAction } from 'slices/session/thunks';
import { useStaff } from 'hooks/session/useStaff';
import { useUser } from 'hooks/session/useUser';
import { UserOrganisationsView } from './UserOrganisations.view';

interface DialogState {
  open: boolean;
  org: MemberOf | null;
}

export const UserOrganisations = () => {
  const t = useTranslations('pages.accountSettings');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useUser();
  const isStaff = useStaff();

  const organisationId = useOrganisationId();

  const initialDialogState: DialogState = {
    open: false,
    org: null,
  };
  const [dialog, setDialog] = useState<DialogState>({ ...initialDialogState });

  const onTableRowClick = (orgId: string) => {
    dispatch(setOrganisationIdAction(orgId));
    navigate('/settings/organisation');
  };

  const onClose = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const onLeave = () => {
    if (!user || !dialog.org) {
      return;
    }
    // @ts-ignore
    dispatch(leaveOrganisationAction({
      userId: user.id,
      organisationId: dialog.org.id,
    }));
    onClose();
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <UserOrganisationsView
        user={user}
        isStaff={isStaff}
        organisationId={organisationId}
        onTableRowClick={orgId => onTableRowClick(orgId)}
        onDialogOpen={org => setDialog({ open: true, org })}
      />

      <Dialog
        open={dialog.open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
      >
        <TPDialogTitle>{t('leaveOrg', { orgName: dialog.org?.name ?? '' })}</TPDialogTitle>
        <DialogContent>
          <Box pt={3}>
            {t('leaveOrgConfirm', { orgName: dialog.org?.name ?? '' })}
          </Box>
        </DialogContent>
        <TPDialogActions>
          <TPButton variant="outlined" onClick={onClose}>
            {t('cancel')}
          </TPButton>
          <TPButton onClick={onLeave}>
            {t('leave')}
          </TPButton>
        </TPDialogActions>
      </Dialog>
    </>
  );
};
