import geomagnetism from 'geomagnetism';
import moment from 'moment';
import LatLon from 'geodesy/latlon-spherical';
import { MomentInput } from 'moment-timezone';
import { Format } from 'geodesy';
import { Duration } from 'luxon';

const distanceUnitsDetails = (units: DistanceUnit) => {
  switch (units.toLowerCase().replace(' ', '')) {
    case 'kilometres':
    case 'km':
      return {
        ratio: 1 / 1000,
        label: 'km'
      };
    case 'statutemiles':
    case 'miles':
      return {
        ratio: 1 / (1000 * 1.609344),
        label: 'mi'
      };
    case 'nauticalmiles':
      return {
        ratio: 1 / 1852,
        label: 'nm'
      };
    case 'metres':
      return {
        ratio: 1,
        label: 'm'
      };
    case 'feet':
      return {
        ratio: 3.28084,
        label: 'ft'
      };
    default:
      throw new Error(`No conversion for distance to ${units}`);
  }
};

type DistanceUnit = ReduxState['unitSettings']['units']['distance'];

export const distance = {
  fromSI: (distanceInMetres: number, targetUnits: DistanceUnit) => distanceInMetres * distanceUnitsDetails(targetUnits).ratio,
  toSI: (distanceInSourceUnits: number, sourceUnits: DistanceUnit) => distanceInSourceUnits / distanceUnitsDetails(sourceUnits).ratio,
  // Returns distance along surface of the earth between two points (using haversine formula)
  distanceTo: (lat1: number, long1: number, lat2: number, long2: number) => (
    new LatLon(lat1, long1).distanceTo(new LatLon(lat2, long2))
  ),
  withUnits: (distanceInUnits: number, targetUnits: DistanceUnit, decimalPlaces = 0) => (
    `${distanceInUnits.toFixed(decimalPlaces)}${distanceUnitsDetails(targetUnits).label}`
  ),
  label: (units: DistanceUnit) => distanceUnitsDetails(units).label,
  fromSIWithLabel: (distanceInMetres: number, targetUnits: DistanceUnit, decimalPlaces = 0) => distance.withUnits(distance.fromSI(distanceInMetres, targetUnits), targetUnits, decimalPlaces),
  fromLine: (points: { lat: number, lon: number }[]) => {
    let totalDistance = 0;
    for (let i = 1; i < points.length; i++) {
      totalDistance += new LatLon(points[i - 1].lat, points[i - 1].lon).distanceTo(new LatLon(points[i].lat, points[i].lon));
    }
    return totalDistance;
  }
};
export const altitude = distance;

interface LatLngObj {
  latitude?: number
  longitude?: number
}
export const calculateDistance = (from: LatLngObj, to: LatLngObj, distanceUnits: DistanceUnit) => {
  if (!from.latitude || !from.longitude || !to.latitude || !to.longitude) return '--';
  return distance.fromSI(
    distance.distanceTo(from.latitude, from.longitude, to.latitude, to.longitude),
    distanceUnits
  );
};

export type SpeedUnit = ReduxState['unitSettings']['units']['speed'];
const speedUnitsDetails = (units: SpeedUnit) => {
  switch (units.toLowerCase()) {
    case 'kmh':
    case 'km/h':
      return {
        ratio: 1,
        label: 'km/h'
      };
    case 'mph':
      return {
        ratio: 0.621371,
        label: 'mph'
      };
    case 'knots':
      return {
        ratio: 0.539957,
        label: 'kt'
      };
    default:
      throw new Error(`No conversion for speed to ${units}`);
  }
};

export const speed = {
  fromKmh: (speedInKilometresPerHour: number, targetUnits: SpeedUnit) => (
    speedInKilometresPerHour * speedUnitsDetails(targetUnits).ratio
  ),
  withUnits: (speedInUnits: number, targetUnits: SpeedUnit, decimalPlaces = 0) => (
    `${speedInUnits.toFixed(decimalPlaces)}${speedUnitsDetails(targetUnits).label}`
  ),
  label: (units: SpeedUnit) => speedUnitsDetails(units).label
};

const timestampWithinMagneticModelRange = (timestamp: MomentInput) => {
  const timestampMoment = moment(timestamp);
  return (timestampMoment.isAfter('2014-12-15') && timestampMoment.isBefore('2029-11-13'));
};

type BearingUnit = ReduxState['unitSettings']['units']['bearing'];
const bearingUnitsDetails = (units: BearingUnit) => {
  switch (units.toLowerCase()) {
    case 'degreestrue':
    case 'degreesgeographic':
      return {
        fromSI: (x: number) => x,
        toSI: (x: number) => x,
        label: () => '°T',
      };
    case 'degreesmagnetic': {
      // Magnetic course is calculated using the World Magnetic Model (WMM). The model must change over time as the earth's
      // magnetic field changes and so every 5 years a new model is produced. The current model is only valid for dates between
      // 13 Nov 2024 and 13 Nov 2029. Previous models will automatically be used back to Dec 2014.
      // If the timestamp of the report is from before Dec 2014 or after Nov 2029, the below code falls back to °T.
      return {
        fromSI: (bearingInDegreesTrue: number, timestamp: MomentInput, latitude: number, longitude: number) => {
          if (!timestampWithinMagneticModelRange(timestamp)) return bearingInDegreesTrue;
          let dateTime: Date;
          if (moment.isMoment(timestamp)) {
            dateTime = timestamp.toDate();
          } else if (typeof timestamp === 'string') {
            dateTime = new Date(timestamp);
          } else if (typeof timestamp === 'number') {
            dateTime = new Date(timestamp);
          } else if (timestamp instanceof Date) {
            dateTime = timestamp;
          } else {
            console.error('Unknown timestamp type.');
            // let it crash the magnetism model
            dateTime = timestamp as Date;
          }
          const { decl } = geomagnetism.model(dateTime).point([latitude, longitude]);
          return (bearingInDegreesTrue + decl + 360) % 360;
        },
        toSI: (bearingInUnit: number, timestamp: MomentInput, latitude: number, longitude: number) => {
          if (!timestampWithinMagneticModelRange(timestamp)) return bearingInUnit;
          let dateTime: Date;
          if (moment.isMoment(timestamp)) {
            dateTime = timestamp.toDate();
          } else if (typeof timestamp === 'string') {
            dateTime = new Date(timestamp);
          } else if (typeof timestamp === 'number') {
            dateTime = new Date(timestamp);
          } else if (timestamp instanceof Date) {
            dateTime = timestamp;
          } else {
            console.error('Unknown timestamp type.');
            // let it crash the magnetism model
            dateTime = timestamp as Date;
          }
          const { decl } = geomagnetism.model(dateTime).point([latitude, longitude]);
          return (bearingInUnit - decl + 360) % 360;
        },
        label: (timestamp: MomentInput) => (timestampWithinMagneticModelRange(timestamp) ? '°m' : '°T'),
      };
    }
    default:
      throw new Error(`No conversion for speed to ${units}`);
  }
};

interface LatLngObjectNonNull {
  latitude: number
  longitude: number
}
export const bearing = {
  fromSI: (bearingInDegreesTrue: number, timestamp: MomentInput, { latitude, longitude }: LatLngObjectNonNull, targetUnits: BearingUnit) => (
    bearingUnitsDetails(targetUnits).fromSI(bearingInDegreesTrue, timestamp, latitude, longitude)
  ),
  toSI: (bearingInUnit: number, timestamp: MomentInput, { latitude, longitude }: LatLngObjectNonNull, fromUnits: BearingUnit) => (
    bearingUnitsDetails(fromUnits).toSI(bearingInUnit, timestamp, latitude, longitude)
  ),
  withUnits: (bearingInUnits: number, units: BearingUnit, timestamp: MomentInput, decimalPlaces = 0) => (
    `${bearingInUnits.toFixed(decimalPlaces)}${bearingUnitsDetails(units).label(timestamp)}`
  ),
  label: (units: BearingUnit, timestamp: MomentInput) => bearingUnitsDetails(units).label(timestamp)
};

type CoordinateUnit = ReduxState['unitSettings']['units']['coordinate'];
const coordinateUnitsDetails = (units: CoordinateUnit): { decimalPlaces: number, label: Format } => {
  switch (units.toLowerCase()) {
    case 'coordinatesdd':
      return {
        label: 'd',
        decimalPlaces: 5,
      };
    case 'coordinatesddm':
      return {
        label: 'dm',
        decimalPlaces: 3,
      };
    case 'coordinatesdms':
      return {
        label: 'dms',
        decimalPlaces: 1,
      };
    default:
      throw new Error(`No conversion for coordinate to ${units}`);
  }
};
export const coordinate = {
  fromLatLon: (lat: number, long: number, targetUnits: CoordinateUnit) => {
    const dp = coordinateUnitsDetails(targetUnits).decimalPlaces;
    if (targetUnits === 'coordinatesDD') return `${lat.toFixed(dp)}, ${long.toFixed(dp)}`;
    return new LatLon(lat, long).toString(
      coordinateUnitsDetails(targetUnits).label,
      coordinateUnitsDetails(targetUnits).decimalPlaces
    );
  },
  label: (units: CoordinateUnit) => coordinateUnitsDetails(units).label,
};

type AreaUnit = ReduxState['unitSettings']['units']['area'];
const areaUnitsDetails = (units: AreaUnit) => {
  switch (units.toLowerCase()) {
    case 'squarekilometres':
      return {
        label: 'km²'
      };
    case 'acres':
      return {
        label: 'acres'
      };
    case 'hectares':
      return {
        label: 'ha'
      };
    case 'squaremiles':
      return {
        label: 'mi²'
      };
    case 'squarenauticalmiles':
      return {
        label: 'nm²'
      };
    default:
      throw new Error(`No conversion for area to ${units}`);
  }
};
export const area = {
  label: (units: AreaUnit) => areaUnitsDetails(units).label
};

type VolumeUnit = ReduxState['unitSettings']['units']['volume'];

const volumeUnitsDetails = (units: VolumeUnit) => {
  switch (units.toLowerCase()) {
    case 'litres':
      return {
        label: 'L',
        ratio: {
          litres: 1,
        },
      };
    case 'gallons':
      return {
        label: 'gal',
        ratio: {
          litres: 0.26417,
        },
      };
    default:
      throw new Error(`No conversion for volume to ${units}`);
  }
};

export const volume = {
  toUnit: (litres: number, toUnit: VolumeUnit) => {
    const details = volumeUnitsDetails(toUnit);
    switch (toUnit) {
      case 'gallons':
        return litres * details.ratio.litres;
      default:
        return litres;
    }
  },
  label: (units: VolumeUnit) => volumeUnitsDetails(units).label,
};

export type DurationUnit = ReduxState['unitSettings']['units']['duration'];

const durationUnitsDetails = (units: DurationUnit) => {
  switch (units) {
    case 'decimalTime':
      return {
        label: 'Decimal',
        ratio: { minutes: 1 / 60 },
      };
    case 'hoursMinutes':
    default:
      return {
        label: 'HH:mm',
        ratio: { minutes: 1 },
      };
  }
};

export interface DurationFormatOptions {
  format?: string,
  hoursFormatter?: (time: Duration) => string
}

const defaultHoursFormatter = (time: Duration) => {
  const seconds = time.as('seconds');
  const hours = time.as('hour').toFixed(seconds < 3600 ? 2 : 1);
  return `${hours} h`;
};

export const duration = {
  toUnit: (
    time: Duration,
    toUnit: DurationUnit,
    {
      format = "h'h' m'm' s's'",
      hoursFormatter = defaultHoursFormatter,
    }: DurationFormatOptions = {},
  ) => {
    const seconds = time.as('seconds');
    if (seconds <= 0) {
      return '—';
    }

    if (toUnit === 'decimalTime') {
      // only use decimal hours from 6 mins (0.1h)
      if (seconds < 60) return time.toFormat('s\'s\'');
      if (seconds < 360) return time.toFormat('m\'m\' s\'s\'');
      return hoursFormatter(time);
    }

    return time.toFormat(format);
  },
  label: (units: DurationUnit) => durationUnitsDetails(units).label,
};
