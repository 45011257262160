import React, { useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Button, Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslations } from 'use-intl';
import { useGetTemporalSharesToList } from 'apis/rest/temporalShares/hooks';
import { TemporalSharesResult } from 'apis/rest/temporalShares/types';
import { useUpdateAssetCallsign } from 'apis/rest/assets/hooks';
import usePermissions from 'hooks/session/usePermissions';
import { isInPeriod, Period } from 'components/pages/sharing/helpers';
import useSnackbar from 'hooks/useSnackbar';
import { useAssetLabel } from 'components/shared/assetLabel';

interface AssetEditCallsignProps {
  asset: AssetBasic
}

const AssetEditCallsign = ({ asset }: AssetEditCallsignProps): JSX.Element | null => {
  const t = useTranslations('pages.assetView.editCallSign');
  const permissions = usePermissions();
  const [open, setOpen] = useState(false);
  const [callSign, setCallSign] = useState<string | null>();
  const snackbar = useSnackbar();
  const assetLabel = useAssetLabel();

  const mutation = useUpdateAssetCallsign();

  const query = useGetTemporalSharesToList({
    select: useCallback(
      ({ shares, groupShares }: TemporalSharesResult) => (
        [...shares, ...groupShares].some(share => share.deviceId === asset.deviceId && share.canEditCallSign && isInPeriod(share, Period.Present, DateTime.now()))
      ),
      [asset.deviceId],
    ),
    enabled: asset.deviceId !== undefined && permissions.canEditAssets,
  });

  if (!query.data || !permissions.canEditAssets) return null;

  const canSave = mutation.isPending || callSign === null ? false : callSign !== asset.callSign;

  const onExited = () => {
    setCallSign(null);
    mutation.reset();
  };

  const onOpen = () => {
    setOpen(true);
    setCallSign(asset.callSign)
  }

  const onSave = () => {
    mutation.mutate(
      { id: asset.id, callSign: (callSign || null) },
      {
        onSuccess: () => {
          setOpen(false);
          snackbar.display({
            id: `assetCallsignUpdated.${asset.id}`,
            text: t('snackbar.success'),
            type: 'success',
          });
        },
      },
    );
  };

  return (
    <>
      <InputAdornment position="end">
        <Button variant="contained" onClick={onOpen}>
          {t('edit')}
        </Button>
      </InputAdornment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        TransitionProps={{ onExited }}
      >
        <DialogTitle>{t('dialog.title', { asset: assetLabel(asset) })}</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography>{t('dialog.description', { owner: asset.ownerName })}</Typography>
            <TextField
              label={t('dialog.callsign')}
              value={callSign || ''}
              onChange={event => setCallSign(event.target.value)}
              disabled={mutation.isPending}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
          <Box flex={1}>
            <Collapse in={mutation.isError}>
              <Alert sx={{ mb: 3 }} severity="error">{t('dialog.error')}</Alert>
            </Collapse>
            <Stack spacing={3} direction="row" height="4em" justifyContent="flex-end">
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                disabled={mutation.isPending}
                onClick={() => setOpen(false)}
              >
                {t('dialog.cancel')}
              </Button>
              <Button
                size="large"
                variant="contained"
                sx={{ minWidth: '10rem' }}
                onClick={onSave}
                disabled={!canSave}
              >
                {t(mutation.isPending ? 'dialog.saving' : 'dialog.save')}
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssetEditCallsign;
