import React, { ReactNode, useMemo, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit, ExpandMore, ExpandLess, ErrorOutline } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { Link, LinkProps } from 'react-router';
import { UseQueryResult } from '@tanstack/react-query';
import { LocationState, LocationDescriptor, Location } from 'history';
import LimitedList from 'components/shared/LimitedList';
import { useGetPeople } from 'apis/rest/people/hooks';
import usePeopleWithExtractedContacts from 'hooks/people/usePeopleWithExtractedContacts';
import { personCanBeImportantIce } from 'hooks/people/usePersonCanBeImportantIce';

export interface ContactGroupPeopleTableComponents {
  PersonDetails?: (props: { person: Person, order: number, children: ReactNode }) => JSX.Element
}

const DefaultPersonDetails: ContactGroupPeopleTableComponents['PersonDetails'] = ({ children }) => <>{children}</>;

interface ContactGroupPeopleTableProps<S = LocationState> {
  group: ContactGroup
  edit?: boolean
  size?: 'small' | 'medium'
  noEndBorder?: boolean
  components?: ContactGroupPeopleTableComponents
  editLinkProps?: (person: Person['id']) => (Pick<LinkProps, 'to'> & { state?: S })
}

const useTableData = (peopleQuery: UseQueryResult<Person[]>, group: ContactGroup) => {
  const orderMap = useMemo(
    () => group.peopleWithOrder.reduce<Record<number, number>>((acc, item) => {
      acc[item.personId] = item.order;
      return acc;
    }, {}),
    [group.peopleWithOrder],
  );

  const relevantPeople = useMemo(
    () => peopleQuery.data?.filter(person => orderMap[person.id] !== undefined) ?? [],
    [peopleQuery, orderMap],
  );

  const peopleWithExtractedContacts = usePeopleWithExtractedContacts(relevantPeople);

  const rows = useMemo(
    () => peopleWithExtractedContacts.map(person => ({ person, order: orderMap[person.id] }))
      .sort((a, b) => a.order - b.order),
    [peopleWithExtractedContacts, orderMap],
  );

  return rows;
};

export const ContactGroupPeopleTable = <S, >({
  group,
  edit,
  size,
  noEndBorder,
  editLinkProps = personId => ({ to: `/manage/people/${personId}` }),
  components: {
    PersonDetails = DefaultPersonDetails,
  } = {},
}: ContactGroupPeopleTableProps<S>): JSX.Element => {
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupPeopleTable');

  const { query: peopleQuery } = useGetPeople();
  const rows = useTableData(peopleQuery, group);

  const [expandedRowId, setExpandedRowId] = useState<number>();

  return (
    <TableContainer>
      <Table size={size}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 0 }} />
            <TableCell>{t('columns.nameAndRole')}</TableCell>
            <TableCell align="center">{t('columns.language')}</TableCell>
            {edit && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const isExpanded = expandedRowId === row.person.id;
            const endBorder = noEndBorder && index === rows.length - 1 ? 0 : undefined;

            return (
              <React.Fragment key={row.person.id}>
                <TableRow>
                  <TableCell sx={{ border: 0 }}>
                    <IconButton
                      size="small"
                      aria-label={t(isExpanded ? 'collapseRow' : 'expandRow')}
                      onClick={() => setExpandedRowId(id => (id === row.person.id ? undefined : row.person.id))}
                    >
                      {isExpanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <PersonDetails person={row.person} order={row.order}>
                        <Stack>
                          <Typography sx={{ whiteSpace: 'nowrap' }}>{row.person.name}</Typography>
                          <Typography sx={{ whiteSpace: 'nowrap' }} variant="body3">{row.person.role}</Typography>
                        </Stack>
                        {!personCanBeImportantIce(row.person) && (
                          <Tooltip title={t('tooltips.missingRequiredContacts')}>
                            <ErrorOutline color="error" fontSize="large" />
                          </Tooltip>
                        )}
                      </PersonDetails>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ border: 0 }} align="center">
                    <Typography>{row.person.languageCode}</Typography>
                  </TableCell>
                  {edit && (
                    <TableCell sx={{ border: 0 }} align="right">
                      <Tooltip title={t('tooltips.edit')}>
                        {/* TODO */}
                        <IconButton component={Link} {...editLinkProps(row.person.id)}>
                          <Edit sx={theme => ({ color: theme.palette.common.text })} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{ py: 0, border: endBorder }} />
                  <TableCell sx={{ py: 0, border: endBorder }} colSpan={edit ? 3 : 2}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <Box mr={3} mb={3}>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">{t('columns.voice')}</TableCell>
                              <TableCell>
                                {row.person.voice.length ? (
                                  <Typography>
                                    <LimitedList<string>
                                      items={row.person.voice.map(x => x.value)}
                                      limit={1}
                                      renderMore={({ items, children }) => (
                                        <Tooltip title={items.map(x => <div key={x}>{x}</div>)}>
                                          {children}
                                        </Tooltip>
                                      )}
                                    />
                                  </Typography>
                                ) : (
                                  <Typography fontStyle="italic">
                                    {t('noContactValues', { type: 'voice' })}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">{t('columns.sms')}</TableCell>
                              <TableCell>
                                {row.person.sms.length ? (
                                  <Typography>
                                    <LimitedList<string>
                                      items={row.person.sms.map(x => x.value)}
                                      limit={1}
                                      renderMore={({ items, children }) => (
                                        <Tooltip title={items.map(x => <div key={x}>{x}</div>)}>
                                          {children}
                                        </Tooltip>
                                      )}
                                    />
                                  </Typography>
                                ) : (
                                  <Typography fontStyle="italic">
                                    {t('noContactValues', { type: 'sms' })}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row" sx={{ border: 0 }}>{t('columns.email')}</TableCell>
                              <TableCell sx={{ border: 0 }}>
                                {row.person.email.length ? (
                                  <Typography>
                                    <LimitedList<string>
                                      items={row.person.email.map(x => x.value)}
                                      limit={1}
                                      renderMore={({ items, children }) => (
                                        <Tooltip title={items.map(x => <div key={x}>{x}</div>)}>
                                          {children}
                                        </Tooltip>
                                      )}
                                    />
                                  </Typography>
                                ) : (
                                  <Typography fontStyle="italic">
                                    {t('noContactValues', { type: 'email' })}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
