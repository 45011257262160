import type { GetPickingInfoParams, PickingInfo } from '@deck.gl/core';
import { ScatterplotLayer } from '@deck.gl/layers';
import { hexToRGBArray } from 'helpers/color';
import { usePickingPriority } from 'hooks/usePickingPriority';
import { useCallback, useMemo } from 'react';
import type { MapSettings } from 'slices/map.slice';
import { mapToColors } from 'utils/colormap';
import ConstantSizeScatterplotShader from './shaders/constant-size-scatterplot-vertex.glsl';

const WHITE: Color = [255, 255, 255];

interface DataItem {
  position: [number, number, number];
  terrainElevation?: number;
  color: ColorAlpha;
  reportId: number;
  assetId: number;
  pickingKey: string;
  event: string;
}

interface ExtraProps {
  getPick?: (item: DataItem) => DataItem;
}

interface DataPickingInfo extends PickingInfo {
  object?: DataItem;
}

class ConstantSizeScatterplotLayer extends ScatterplotLayer<DataItem, ExtraProps> {
  static layerName = 'ConstantSizeScatterplotLayer';
  getShaders() {
    return { ...super.getShaders(), vs: ConstantSizeScatterplotShader };
  }

  getPickingInfo(params: GetPickingInfoParams): DataPickingInfo {
    const info = super.getPickingInfo(params);
    if (!this.props.getPick || !info.object) return info;

    const object = this.props.getPick(info.object as DataItem);
    return { ...info, object };
  }
}

const useReportDotLayers = (
  visible: boolean,
  asset: AssetBasic | undefined,
  reports: Report[],
  radius: number,
  coloring: MapSettings['assetTrailColouring'],
  zoom: number,
  use3d = false,
) => {
  const fallbackColor = useCallback((r: Report) => hexToRGBArray(asset?.colour ?? '#fff'), [asset]);

  const data: DataItem[] = useMemo(() => {
    const reportColors = mapToColors(reports, coloring, fallbackColor);

    const newData: DataItem[] = reports.map((report, index) => {
      const color = reportColors.at(index) ?? fallbackColor(report);
      const position = [report.longitude, report.latitude, use3d ? report.altitude : 0] as [number, number, number];
      return {
        reportId: report.id,
        assetId: report.assetId,
        position,
        color,
        event: report.events[0],
        pickingKey: position.join(),
      };
    });

    return newData;
  }, [reports, coloring, fallbackColor, use3d]);

  const getPick = usePickingPriority(data);

  const triplicateData = useMemo(
    () =>
      data.flatMap<DataItem>(item => [
        item,
        { ...item, position: [item.position[0] - 360, item.position[1], item.position[2]] },
        { ...item, position: [item.position[0] + 360, item.position[1], item.position[2]] },
      ]),
    [data],
  );

  return useMemo(
    () =>
      [
        new ConstantSizeScatterplotLayer({
          id: 'report-dot-background-layer',
          data: triplicateData,
          pickable: true,
          billboard: true,
          filled: true,
          opacity: visible ? 1 : 0,
          getFillColor: d => [...WHITE, 255],
          radiusUnits: 'pixels',
          getRadius: radius + 1,
          parameters: { depthTest: use3d && zoom > 12 },
          getPick,
        }),
        new ConstantSizeScatterplotLayer({
          id: 'report-dot-layer',
          data: triplicateData,
          pickable: false,
          billboard: true,
          filled: true,
          visible,
          getFillColor: d => [d.color[0], d.color[1], d.color[2], 255],
          radiusUnits: 'pixels',
          getRadius: radius,
          parameters: { depthTest: use3d && zoom > 12 },
        }),
      ] as const,
    [getPick, radius, triplicateData, use3d, visible, zoom],
  );
};

export default useReportDotLayers;
