import { PlayArrow, Stop } from '@mui/icons-material';
import { Box, Fade, Paper, Popper, type PopperProps, Stack, Typography } from '@mui/material';
import useDuration from 'hooks/units/useDuration';
import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { formatDateTime } from 'utils/time';

interface LegPopperProps {
  leg: Leg | undefined;
  anchorEl: PopperProps['anchorEl'];
  timezone: string;
}

const LegPopper = ({ leg, anchorEl, timezone }: LegPopperProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.popper');
  const duration = useDuration();
  const open = useMemo(() => !!leg && !!anchorEl, [leg, anchorEl]);
  return (
    <Popper open={open} anchorEl={anchorEl} transition>
      {({ TransitionProps }) =>
        leg ? (
          <Fade {...TransitionProps} timeout={200}>
            <Paper elevation={8} sx={{ m: 1 }}>
              <Box
                display="grid"
                gridTemplateColumns="max-content 1fr"
                gridTemplateRows="max-content 1fr 1fr"
                gridTemplateAreas="'header header' 'line from' 'line duration' 'line to'"
                rowGap={1}
                columnGap={1}
                p={2}
              >
                <Typography variant="h4" gridArea="header">
                  {t('trip')}
                </Typography>
                <Stack gridArea="line" py={1}>
                  <PlayArrow />
                  <Box alignSelf="center" flex="1" borderLeft="2px dashed #999" />
                  {leg.complete && <Stop />}
                </Stack>
                <Box alignSelf="start" gridArea="from">
                  <Typography alignSelf="start" variant="h5">
                    {leg.from}
                  </Typography>
                  <Typography alignSelf="start">{formatDateTime(leg.start * 1000, timezone, true)}</Typography>
                </Box>
                <Typography
                  alignSelf="center"
                  gridArea="duration"
                >{`${t('duration')}: ${duration.fromMillis((leg.end - leg.start) * 1000)}`}</Typography>
                {leg.complete && (
                  <Box alignSelf="end" gridArea="to">
                    <Typography alignSelf="end" variant="h5">
                      {leg.to}
                    </Typography>
                    <Typography alignSelf="start">{formatDateTime(leg.end * 1000, timezone, true)}</Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Fade>
        ) : null
      }
    </Popper>
  );
};

export default LegPopper;
