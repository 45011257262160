import { Alert, AlertTitle, Box, Button, Container, Typography } from '@mui/material';
import LoadingPage from 'components/pages/loading/loading-view';
import Page from 'components/pages/page';
import DetailPanel from 'components/shared/DetailPanel';
import LinkAbove from 'components/shared/linkAbove';
import MissingPage from 'components/shared/missingPage';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import SettingsSection from 'components/shared/settingsSection';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import { useTranslations } from 'use-intl';

// named to avoid clash with global Organisation type 🤬
import { useGetOrganisationDetails } from 'apis/rest/organisations/hooks';
import OrganisationComponent from './organisation';

const OrganisationSettings: React.FC = () => {
  const user = useSelector<ReduxState, ReduxState['session']['user']>(state => state.session.user);
  const t = useTranslations('pages.organisationSettings');

  const { isPending, data: organisation } = useGetOrganisationDetails().query;

  if (isPending) return <LoadingPage />;
  if (!organisation) {
    return (
      <MissingPage
        title={t('missing.title')}
        description={t('missing.description')}
        linkText={t('missing.link')}
        to=".."
      />
    );
  }

  return (
    <Page>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <LinkAbove />
          <Typography variant="h1" component="h1" gutterBottom>{t('title', { orgName: organisation.name })}</Typography>
          <OrganisationComponent organisation={organisation} user={user} />
          <SettingsSection title={t('emergencyContacts.title')}>
            <DetailPanel p={0} pt={0} pb={0}>
              <Alert severity="warning" icon={false} variant="outlined" sx={{ py: 2 }}>
                <AlertTitle>{t('emergencyContacts.movedTitle')}</AlertTitle>
                {t('emergencyContacts.movedMessage')}
                <Box mt={2}>
                  <Button
                    size="large"
                    variant="contained"
                    component={Link}
                    to="/manage"
                    sx={{ height: '4rem' }}
                  >
                    {t('emergencyContacts.movedLink')}
                  </Button>
                </Box>
              </Alert>
            </DetailPanel>
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default OrganisationSettings;
