import { useGetEngineUsageForAssets } from 'apis/rest/engineUsage/hooks';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import TripReportsAssetTable from './assetTable';

interface TripReportsTableProps {
  query: { assets: number[]; from: number; until: number };
  trips: Trip[];
  assets: AssetWithDevice[];
  timezone: string;
  displayEngineUsage: boolean;
}

const TripReportsTable = ({ query, trips, assets, timezone, displayEngineUsage }: TripReportsTableProps) => {
  const queriedAssets = useMemo(() => assets.filter(asset => query.assets?.includes(asset.id)), [assets, query.assets]);

  const { state } = useLocation();
  const [expandedAssetId, setExpandedAssetId] = React.useState<number | undefined>(state?.selectedAssetId);
  const engineUsageQuery = useGetEngineUsageForAssets(query.assets, query.from, query.until, {
    enabled: displayEngineUsage,
  });

  useEffect(() => {
    if (!assets.some(asset => asset.id === expandedAssetId)) setExpandedAssetId(undefined);
  }, [assets, expandedAssetId]);

  useEffect(() => {
    if (expandedAssetId) {
      mixpanel.track('Trip Analytics Table Expand Asset', { assetId: expandedAssetId });
    }
  }, [expandedAssetId]);

  return (
    <>
      {queriedAssets.map(asset => (
        <TripReportsAssetTable
          key={asset.id}
          asset={asset}
          trips={trips}
          timezone={timezone}
          query={query}
          engineUsageQuery={engineUsageQuery}
          displayEngineUsage={displayEngineUsage}
          isExpanded={asset.id === expandedAssetId}
          setExpanded={setExpandedAssetId}
        />
      ))}
    </>
  );
};

export default TripReportsTable;
