import { Typography } from '@mui/material';
import type { Base } from 'apis/rest/bases/bases-types';
import { DetailsGrid, OverlayWrapper, ScoreCard } from 'components/maps/reactmapgl/overlays/common';
import Coordinate from 'components/shared/coordinate';
import React from 'react';
import { useTranslations } from 'use-intl';

interface HoveredBasesOverlayProps {
  base?: Base;
  highContrast: boolean;
}

export const HoveredBasesOverlay = ({ base, highContrast }: HoveredBasesOverlayProps) => {
  const t = useTranslations('pages.map.bases');
  const maxNotesShown = 3;

  return base ? (
    <OverlayWrapper highContrast={highContrast}>
      <DetailsGrid>
        <ScoreCard label={t('base')} wide>
          <Typography variant="h3">{base.name}</Typography>
        </ScoreCard>
      </DetailsGrid>
      <DetailsGrid>
        <ScoreCard label={t('coordinates')} wide>
          <Coordinate latitude={base.latitude} longitude={base.longitude} />
        </ScoreCard>
        {base.description && <ScoreCard label={t('description')} value={base.description} wide />}
        {base.notes && (
          <>
            {base.notes.slice(0, maxNotesShown).map((note, index) => (
              <ScoreCard key={note} label={index > 0 ? '' : t('notes')} value={note} maxValueLines={2} wide />
            ))}
            {base.notes.length > maxNotesShown && (
              <ScoreCard
                label={''}
                value={t('overflow', { n: base.notes.length - maxNotesShown })}
                maxValueLines={2}
                wide
              />
            )}
          </>
        )}
      </DetailsGrid>
    </OverlayWrapper>
  ) : null;
};
