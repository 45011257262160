import { GetApp } from '@mui/icons-material';
import type { SpeedDialProps } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SpeedDial from '@mui/material/SpeedDial';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';

const DownloadSpeedDial = ({ children, sx, ...rest }: SpeedDialProps) => {
  const [downloadSpeedDialOpen, setDownloadSpeedDialOpen] = useState(false);
  const theme = useTheme();

  const speedDialSx = {
    position: 'absolute',
    zIndex: 1,
    '& .MuiFab-root': {
      height: 36,
      width: 36,
    },
    '& .MuiSpeedDialAction-fab': {
      display: 'none',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&.MuiSpeedDial-directionDown .MuiSpeedDial-actions': {
      marginTop: 0,
      paddingTop: 0,
    },
    '& .MuiSpeedDialAction-staticTooltipLabel': {
      position: 'relative',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: '0.875rem',
      fontWeight: '500',
      marginTop: 1.5,
      padding: 1.5,
      marginRight: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: theme.palette.common.text,
      },
      cursor: 'pointer',
      width: '100%',
    },
    ...sx,
  };

  const defaultProps: SpeedDialProps = {
    ariaLabel: 'Download SpeedDial',
    icon: <GetApp />,
    onClick: () => setDownloadSpeedDialOpen(!downloadSpeedDialOpen),
    open: downloadSpeedDialOpen,
    direction: 'down',
    sx: speedDialSx,
  };

  return (
    <ClickAwayListener onClickAway={() => setDownloadSpeedDialOpen(false)}>
      <SpeedDial {...defaultProps} {...rest}>
        {children}
      </SpeedDial>
    </ClickAwayListener>
  );
};

export default DownloadSpeedDial;
