import React, { useMemo, useState } from 'react';
import { useGetPeople } from 'apis/rest/people/hooks';
import Page from 'components/pages/page';
import { Alert, Box, Button, Chip, Container, Stack, Tooltip, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import PersistentTable from 'components/shared/persistentTable';
import insensitiveSort from 'utils/insensitiveSort';
import { Localization, MaterialTableProps } from '@material-table/core';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import LinkAbove from 'components/shared/linkAbove';
import LimitedList from 'components/shared/LimitedList';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import DetailPanel from 'components/shared/DetailPanel';
import StickyPagination from 'components/shared/stickyPagination';
import DeletePersonDialog from 'components/dialogs/people/deletePerson';
import AddPersonDialog from 'components/dialogs/people/addPerson';
import usePeopleWithExtractedContacts, { PersonWithExtractedContacts } from 'hooks/people/usePeopleWithExtractedContacts';
import usePermissions from 'hooks/session/usePermissions';
import useSnackbar from 'hooks/useSnackbar';

type ContactType = 'sms' | 'email' | 'voice';

type TableProps = MaterialTableProps<PersonWithExtractedContacts>;

const PeoplePage = (): JSX.Element => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const t = useTranslation('pages.manage.people');
  const snackbar = useSnackbar();

  const { query: peopleQuery } = useGetPeople();
  const peopleWithExtractedContacts = usePeopleWithExtractedContacts(peopleQuery?.data);

  const [addPerson, setAddPerson] = useState(false);
  const [createdPersonId, setCreatedPersonId] = useState<number>();
  const [deletePersonId, setDeletePersonId] = useState<number>();

  const onDeletePerson = () => {
    setDeletePersonId(undefined);
  };

  const columns = useMemo<TableProps['columns']>(() => ([
    {
      title: t('columns.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a: Person, b: Person) => insensitiveSort(a.name, b.name),
      render: person => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{person.name}</Typography>
          {person.id === createdPersonId && <Chip label={t('created')} color="success" />}
        </Stack>
      ),
    },
    {
      title: t('columns.role'),
      field: 'role',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a: Person, b: Person) => insensitiveSort(a.role, b.role),
    },
    ...(['sms', 'voice', 'email'] as ContactType[]).map((type): TableProps['columns'][number] => ({
      title: t(`columns.${type}`),
      field: type,
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customFilterAndSearch: (filter, rowData) => rowData[type].some(x => x.value.includes(filter)),
      sorting: false,
      render: person => (
        <Stack direction="row" spacing={1} alignItems="center">
          <LimitedList<string>
            items={person[type].map(x => x.value)}
            limit={1}
            renderMore={({ items, children }) => (
              <Tooltip title={items.map(x => <div key={x}>{x}</div>)}>
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Stack>
      ),
    })), {
      title: t('columns.language'),
      field: 'languageCode',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      width: '150px',
    }
  ]), [t, createdPersonId]);

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  const localization: Localization = { header: { actions: '' } };
  if (peopleWithExtractedContacts?.length === 0) {
    localization.body = {
      emptyDataSourceMessage: <Box my={3}><Alert severity="warning">{t('noPeopleAlert')}</Alert></Box>,
    };
  }

  const actions = useMemo<TableProps['actions']>(() => {
    if (permissions.canEditPeople) {
      return [
        person => ({
          icon: () => <Edit sx={{ color: 'common.text' }} />,
          tooltip: t('editPerson'),
          onClick: () => navigate(`/manage/people/${person.id}`)
        }),
        person => ({
          icon: () => <DeleteIcon sx={{ color: 'common.text' }} />,
          tooltip: t('deletePerson'),
          onClick: () => setDeletePersonId(person.id),
        }),
      ];
    }

    return [];
  }, [permissions, t, navigate, setDeletePersonId]);

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
          </Box>
          <DetailPanel spacing={3} pb={0} mb={8}>
            {permissions.canEditPeople && (
              <Stack direction="row" justifyContent="flex-end" px={3} height="4em">
                <Button
                  onClick={() => setAddPerson(true)}
                  size="large"
                  variant="contained"
                >
                  {t('addPerson')}
                </Button>
              </Stack>
            )}
            <Box>
              <PersistentTable
                settingsCategory="peopleTable"
                data={peopleWithExtractedContacts}
                isLoading={peopleQuery.isLoading || !peopleWithExtractedContacts}
                columns={columns}
                onRowClick={permissions.canEditPeople ? (e, row) => {
                  if (row) navigate(`/manage/people/${row.id}`);
                } : undefined}
                actions={actions}
                localization={localization}
                components={{
                  Toolbar: () => null,
                  Container: Box,
                  Pagination,
                }}
                options={{
                  filtering: true,
                  draggable: false,
                  showTitle: false,
                  search: false,
                  header: true,
                  actionsColumnIndex: -1,
                  searchFieldVariant: 'outlined',
                  paging: true,
                  emptyRowsWhenPaging: false,
                  headerStyle: { position: 'sticky', top: 0 },
                }}
                sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }}
              />
              <Box
                ref={footerRef}
                position="sticky"
                bottom={0}
                sx={{ '.MuiToolbar-regular': { padding: 10 / 3 } }}
              />
            </Box>
          </DetailPanel>
        </Container>
      </SettingsMenuPageWrapper>
      {deletePersonId ? <DeletePersonDialog open onClose={onDeletePerson} personId={deletePersonId} /> : null}
      <AddPersonDialog
        open={addPerson}
        cancel={() => setAddPerson(false)}
        created={person => {
          snackbar.display({
            id: 'personCreated',
            text: t('snackbar.createdSuccessfully', { name: person.name }),
            type: 'success',
          });
          setCreatedPersonId(person.id);
          setAddPerson(false);
        }}
      />
    </Page>
  );
};

export default PeoplePage;
