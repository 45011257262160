import { Stack } from '@mui/material';
import type { AdsbAircraft } from 'apis/adsb/types';
import type { GeofenceResponseItem } from 'apis/rest/geofence/types';
import type { Marker } from 'apis/rest/markers/types';
import MultiMeasurementPanel from 'components/maps/reactmapgl/overlays/measurementDetails/multiMeasurementPanel-view';
import { FeatureFlag } from 'components/shared/featureFlag';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useFeatureFlag from 'hooks/useFeatureFlag';
import type { MapTemplate } from 'mapTemplates/reactmapgl/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { type MapSettings, getSelectedMapId } from 'slices/map.slice';
import type { AnnotationPath, AnnotationPoint } from 'slices/map/annotations.slice';
import { useAppSelector } from 'store/useAppSelector';
import { HoveredAnnotationOverlay } from './HoveredAnnotationOverlay';
import { HoveredMarkerOverlay } from './HoveredMarkerOverlay';
import { MapIntervalOverlay } from './MapIntervalOverlay';
import { SearchPatternsOverlay } from './SearchPatternsOverlay';
import { AdsbDetailsOverlay } from './adsbDetails';
import { AssetTrailColouringLegend } from './assetTrailColouringLegend';
import { type HoveredElement, HoveredElementOverlay } from './hoveredElementOverlay';
import { HoveredGeofenceOverlay } from './hoveredGeofenceOverlay';
import MeasurementPanel from './measurementDetails';
import ReportDetails from './reportDetails';
import {HoveredBasesOverlay} from "components/maps/reactmapgl/overlays/hoveredBasesOverlay";
import {Base} from "apis/rest/bases/bases-types";
import {useUiSettings} from "hooks/settings/useUiSettings";

interface OverlayProps {
  measureTool: boolean;
  selectedReport?: Report | null;
  mapIsSelected?: boolean;
  config: MapSettings;
  hoveredBase?: Base;
  hoveredElement?: HoveredElement;
  hoveredAdsbAircraft?: AdsbAircraft;
  hoveredGeofences?: GeofenceResponseItem[];
  hoveredMarker?: Marker;
  hoveredPoint?: AnnotationPoint;
  hoveredPath?: AnnotationPath;
  selectedAsset?: AssetBasic;
  template: MapTemplate;
}

export default ({
  measureTool,
  config,
  mapIsSelected,
  selectedReport,
  hoveredElement,
  hoveredAdsbAircraft,
  hoveredBase,
  hoveredGeofences,
  hoveredMarker,
  hoveredPoint,
  hoveredPath,
  selectedAsset,
  template,
}: OverlayProps) => {
  const highContrastControls = useSelector<ReduxState, boolean>(state => state.settings.map.highContrastControls);
  const enableSearchPatterns = useFeatureAssets('map.searchPatterns').some;
  const selectedMapId = useAppSelector(getSelectedMapId);
  const uiSettings = useUiSettings();

  return (
    <Stack
      direction="row"
      px={3}
      spacing={3}
      pt={4}
      top={uiSettings.displayMode ? 60 : 0 }
      justifyContent="end"
      alignItems="start"
      position="absolute"
      width="100%"
      zIndex={2}
      sx={{ pointerEvents: 'none' }}
    >
      {enableSearchPatterns && <SearchPatternsOverlay enabled={config.id === selectedMapId} />}
      <Stack spacing={3} width="260px">
        <AssetTrailColouringLegend config={config} highContrastControls={highContrastControls} />
        <MapIntervalOverlay template={template} highContrast={highContrastControls} />
      </Stack>
      <div style={{ flex: 1 }} />
      {measureTool && (
        <FeatureFlag
          feature="multipointMeasurementTool"
          enabled={
            <MultiMeasurementPanel
              highContrastControls={highContrastControls}
              mapId={config.id}
              selected={mapIsSelected}
            />
          }
          disabled={
            <MeasurementPanel highContrastControls={highContrastControls} mapId={config.id} selected={mapIsSelected} />
          }
        />
      )}
      {(selectedReport ||
        hoveredElement ||
        hoveredAdsbAircraft ||
        hoveredBase ||
        (hoveredGeofences?.length ?? 0) > 0 ||
        hoveredMarker ||
        hoveredPoint ||
        hoveredPath) && (
        <Stack spacing={3} width="260px">
          {selectedReport && (
            <ReportDetails selectedReport={selectedReport} highContrastControls={highContrastControls} />
          )}
          <HoveredElementOverlay hoveredElement={hoveredElement} highContrast={highContrastControls} />
          <AdsbDetailsOverlay aircraft={hoveredAdsbAircraft} highContrast={highContrastControls} />
          {hoveredGeofences?.map(geofence => (
            <HoveredGeofenceOverlay key={geofence.id} hoveredGeofence={geofence} highContrast={highContrastControls} />
          ))}
          <HoveredMarkerOverlay
            marker={hoveredMarker}
            selectedAsset={selectedAsset}
            highContrast={highContrastControls}
          />
          <HoveredBasesOverlay
            base={hoveredBase}
            highContrast={highContrastControls}
          />
          <HoveredAnnotationOverlay
            point={hoveredPoint}
            path={hoveredPath}
            selectedAsset={selectedAsset}
            highContrast={highContrastControls}
          />
        </Stack>
      )}
    </Stack>
  );
};
