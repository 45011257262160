import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import { useTranslations } from 'use-intl';
import { useMap } from 'react-map-gl';
import type { FeatureCollection, MultiPolygon } from 'geojson';
import { safeBounds } from 'helpers/geo';
import type { Geofence } from '../types';
import ManageListMapView from "components/shared/manageMapList/manageListMap";
import {ManageListMapObject} from "components/shared/manageMapList/manageListMap/manage-list-map-view";

export interface GeofenceTableProps {
  geofences: Geofence[]
  onRowHover: (id: number[]) => void
  hoveredIds: number[]
}

const GeofencesListListView: React.FC<GeofenceTableProps> = ({ geofences, onRowHover, hoveredIds }) => {
  const t = useTranslations('pages.manage.geofencing');
  const hovering = useRef<boolean>(false);
  const { geofencesMap: mapRef } = useMap();

  useEffect(() => {
    const map = mapRef?.getMap();
    if (!map) return;

    const geoJson: FeatureCollection<MultiPolygon> = {
      type: 'FeatureCollection',
      features: geofences.map(f => ({
        type: 'Feature',
        properties: {
          id: f.id,
          name: f.name,
        },
        geometry: f.geometry,
      })),
    };
    const bounds = geoJson.features.length > 0 ? safeBounds(geoJson) : undefined;
    if (!bounds || Number.isNaN(bounds[0][0])) return;

    try {
      map.fitBounds(bounds, { padding: 20 });
    } catch (error) {
      // do nothing
    }
  }, [mapRef, geofences]);

  const getColor = (category: string) => {
    switch (category) {
      case 'AreaOfOperation': return 'green';
      case 'RestrictedArea': return 'red';
      default: return 'yellow';
    }
  };

  const breakTypeString = (breakType:string): string => {
    switch (breakType) {
      case 'RestrictedArea': {
        return t('list.category.enter');
      }
      case 'AreaOfOperation': {
        return t('list.category.exit');
      }
      case 'Generic': {
        return t('list.category.both');
      }
      default:
        return ' ';
    }
  };

  const [scrollToId] = hoveredIds;
  useEffect(() => {
    if (hovering.current || scrollToId === undefined) return;
    document.getElementById(`geofence-list-item-${scrollToId}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [scrollToId]);

  const rows: ManageListMapObject[] = useMemo( () => geofences.map(g => ({
    name: g.name,
    id: g.id.toString(10),
    colour: getColor(g.category),
    secondaryText: breakTypeString(g.category)
  })), [geofences]);

  const listOnRowHover = useCallback((id: string) => {
    const numericId = parseInt(id, 10);
    if (Number.isNaN(numericId)) {
      return;
    }
    onRowHover([numericId]);
  }, [onRowHover]);

  const listHoveredIds = useMemo(() => hoveredIds.map(id => id.toString(10)), [hoveredIds]);

  return (
    <ManageListMapView
      objs={rows}
      onRowHover={listOnRowHover}
      hoveredIds={listHoveredIds}
    />
  );
};

export default GeofencesListListView;
