import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Collapse,
  InputLabel,
  Stack,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import type { FeatureCollection, MultiPolygon } from 'geojson';
import { useMap } from 'react-map-gl';
import { safeBounds } from 'helpers/geo';
import { FeatureFlag } from 'components/shared/featureFlag';
import { manageGeofencesSlice } from 'slices/manageGeofences.slice';
import { useAltitude } from 'hooks/units/useAltitude';
import { distance } from 'helpers/unitsOfMeasure';
import { NumberInput } from 'components/shared/numberInput/NumberInput';
import type { GeofenceCreateTableDetails, GeofenceListItem } from '../types';
import ReturnToListButton from "components/shared/manageMapList/returnToListButton";

const { actions, selectors } = manageGeofencesSlice;

export interface GeofenceCreateTableProps {
  onSave: (newGeofence: GeofenceCreateTableDetails) => void
  onDiscard: () => void
  selectedGeofence?: GeofenceListItem
  isViewOnly: boolean
  isSaving: boolean
  enableCancel?: boolean
}

const GeofencePropertiesTable = ({ onSave, onDiscard, selectedGeofence, isViewOnly, isSaving, enableCancel }: GeofenceCreateTableProps) => {
  const t = useTranslations('pages.manage.geofencing');
  const { geofencesMap: mapRef } = useMap();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const map = mapRef?.getMap();
    if (!map) return;
    if (!selectedGeofence) return;

    const geoJson: FeatureCollection<MultiPolygon> = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {
          id: selectedGeofence.id,
        },
        geometry: selectedGeofence.geometry,
      }],
    };
    const bounds = geoJson.features.length > 0 ? safeBounds(geoJson) : undefined;
    if (!bounds || Number.isNaN(bounds[0][0])) return;

    try {
      map.fitBounds(bounds, { padding: 20 });
    } catch (error) {
      // do nothing
    }
  }, [mapRef, selectedGeofence]);

  const data = useSelector(selectors.selectFormData);
  const altitudeLimitsEnabled = useSelector(selectors.selectHasAltitudeRestriction);
  const minAltitudeType = data.altitudeRestriction?.minimumAltitude.type ?? 'MSL';
  const maxAltitudeType = data.altitudeRestriction?.maximumAltitude.type ?? 'MSL';

  const hasChanges = useSelector(selectors.selectHasChanges);
  const validations = useSelector(selectors.selectValidations);
  const altitude = useAltitude();

  const [pendingMinDisplay, setPendingMinDisplay] = useState<string>();
  const [pendingMaxDisplay, setPendingMaxDisplay] = useState<string>();

  const validationSummary = useMemo(() => [
    !validations.name ? t('validationMessages.name') : undefined,
    !validations.features ? t('validationMessages.features') : undefined,
  ].filter(x => x), [validations, t]);

  const minDisplayAltitude = useMemo(() => {
    if ((data?.altitudeRestriction?.minimumAltitude === undefined) || Number.isNaN(data.altitudeRestriction?.minimumAltitude.altitude)) {
      return '';
    }
    const rawAltitude = altitude.create(data?.altitudeRestriction?.minimumAltitude.altitude).unitValue;
    return pendingMinDisplay ?? rawAltitude.toFixed(1).toString();
  }, [data.altitudeRestriction?.minimumAltitude, pendingMinDisplay]);

  const maxDisplayAltitude = useMemo(() => {
    if ((data?.altitudeRestriction?.maximumAltitude === undefined) || Number.isNaN(data.altitudeRestriction?.maximumAltitude.altitude)) {
      return '';
    }
    const rawAltitude = altitude.create(data?.altitudeRestriction?.maximumAltitude.altitude).unitValue;
    return pendingMaxDisplay ?? rawAltitude.toFixed(1).toString();
  }, [data.altitudeRestriction?.maximumAltitude, pendingMaxDisplay]);

  const handleAltitudeChange = useCallback((value: string, field: 'min' | 'max') => {
    const valueSI = distance.toSI(parseFloat(value), altitude.unit);
    if (field === 'min') {
      setPendingMinDisplay(value);
    } else if (field === 'max') {
      setPendingMaxDisplay(value);
    }

    dispatch(actions.setAltitudeRestrictionValue({ value: valueSI, place: field }));
  }, [altitude.unit, dispatch]);

  const handleAltitudeChangeType = (type: 'MSL' | 'AGL', field: 'min' | 'max') => {
    dispatch(actions.setAltitudeRestrictionType({ value: type, place: field }));
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    dispatch(actions.setCategory(event.target.value as GeofenceCreateTableDetails['category']));
  };

  const handleSave = () => {
    onSave({
      ...data,
      id: selectedGeofence?.id ?? 0,
    });
  };

  const addAltitudeButton = () => {
    if (altitudeLimitsEnabled) {
      return <Button variant="outlined" onClick={() => dispatch(actions.enableAltitudeRestriction(false))} color="error" disabled={isSaving}>{t('create.removeAltitude')}</Button>;
    }
    return <Button variant="outlined" onClick={() => dispatch(actions.enableAltitudeRestriction(true))} disabled={isSaving}>{t('create.addAltitude')}</Button>;
  };
  // styles and props

  const altitudeInputProps = {
    endAdornment: <InputAdornment position="end">{altitude.unitLabel}</InputAdornment>,
  };

  return (
    <Stack direction="column" spacing={3}>
      <Paper elevation={0}>
        <ReturnToListButton label={t('create.allGeofences')}/>
      </Paper>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <TextField
              label={`${t('fields.name')} *`}
              variant="outlined"
              onChange={event => dispatch(actions.setName(event.target.value))}
              value={data.name ?? ''}
              inputProps={{ maxLength: 100 }}
              disabled={isSaving || isViewOnly}
            />
          </FormControl>
          <TextField
            label={t('fields.description')}
            variant="outlined"
            onChange={event => dispatch(actions.setDescription(event.target.value))}
            value={data.description ?? ''}
            inputProps={{ maxLength: 1000 }}
            disabled={isSaving || isViewOnly}
            spellCheck
          />
          <FormControl fullWidth>
            <InputLabel id="createSelectCategoryLabel">{`${t('list.category.geofenceCategory')}`}</InputLabel>
            <Select
              labelId="createSelectCategoryLabel"
              id="createSelectCategory"
              value={data.category ?? 'Generic'}
              onChange={handleCategoryChange}
              disabled={isSaving || isViewOnly}
              label={`${t('list.category.geofenceCategory')}*`}
            >
              <MenuItem value="RestrictedArea">{t('list.category.enter')}</MenuItem>
              <MenuItem value="AreaOfOperation">{t('list.category.exit')}</MenuItem>
              <MenuItem value="Generic">{t('list.category.both')}</MenuItem>
            </Select>
          </FormControl>
          <FeatureFlag
            feature="altitudeControlsNewGeofenceUi"
            enabled={(
              <>
                <Collapse sx={{ mt: -2 }} in={altitudeLimitsEnabled}>
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                      <NumberInput
                        label={t('fields.minAltitude')}
                        variant="outlined"
                        onChange={e => handleAltitudeChange(e.target.value, 'min')}
                        onBlur={() => setPendingMinDisplay(undefined)}
                        value={minDisplayAltitude ?? ''}
                        disabled={isSaving || isViewOnly}
                        InputProps={altitudeInputProps}
                        inputProps={{ step: '0.1' }}
                      />
                      <NumberInput
                        label={t('fields.maxAltitude')}
                        variant="outlined"
                        onChange={e => handleAltitudeChange(e.target.value, 'max')}
                        onBlur={() => setPendingMaxDisplay(undefined)}
                        value={maxDisplayAltitude ?? ''}
                        disabled={isSaving || isViewOnly}
                        InputProps={altitudeInputProps}
                        inputProps={{ step: '0.1' }}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                      <FormControl fullWidth>
                        <InputLabel id="selectMinAltitudeRefLabel">{`${t('create.minAltitudeType')}`}</InputLabel>
                        <Select
                          labelId="selectMinAltitudeRefLabel"
                          label={t('create.minAltitudeType')}
                          value={minAltitudeType}
                          onChange={event => handleAltitudeChangeType(event.target.value as 'MSL' | 'AGL', 'min')}
                          disabled={isSaving || isViewOnly}
                        >
                          <MenuItem value="AGL">{t('create.AGL')}</MenuItem>
                          <MenuItem value="MSL">{t('create.MSL')}</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="selectMaxAltitudeRefLabel">{`${t('create.maxAltitudeType')}`}</InputLabel>
                        <Select
                          labelId="selectMaxAltitudeRefLabel"
                          label={t('create.maxAltitudeType')}
                          value={maxAltitudeType}
                          onChange={event => handleAltitudeChangeType(event.target.value as 'MSL' | 'AGL', 'max')}
                          disabled={isSaving || isViewOnly}
                        >
                          <MenuItem value="AGL">{t('create.AGL')}</MenuItem>
                          <MenuItem value="MSL">{t('create.MSL')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Stack>
                </Collapse>
                {!isViewOnly && addAltitudeButton()}
              </>
            )}
          />
          {!isViewOnly && (
            <Stack direction="row" spacing={3} sx={{ justifyContent: 'flex-end' }}>
              <Button size="large" variant="outlined" onClick={onDiscard} disabled={isSaving || !enableCancel && !hasChanges}>
                {t('fields.cancel')}
              </Button>
              <Tooltip placement="top" title={validations.all ? '' : validationSummary.map((x, i) => (<div key={`validation-${i}`}>{x}</div>))}>
                <span>
                  <Button size="large" variant="contained" onClick={() => handleSave()} disabled={isSaving || !hasChanges || !validations.all} sx={{ minWidth: '10rem', height: '4rem' }}>{t('fields.save')}</Button>
                </span>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default GeofencePropertiesTable;
