import { ArrowForwardIos } from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useRef } from 'react';
import { Link } from 'react-router';
import { useAppDispatch } from 'store/useAppDispatch';

export interface ManageListMapObject {
  id: string;
  name: string;
  secondaryText: string;
  colour: string;
}

export interface ManageListMapViewProps {
  objs: ManageListMapObject[];
  onRowHover: (id: string) => void;
  hoveredIds: string[];
}

const ManageListMapView = ({ objs, onRowHover, hoveredIds }: ManageListMapViewProps) => {
  const hovering = useRef<boolean>(false);

  return (
    <List sx={{ p: 0 }}>
      {objs.map(row => (
        <ListItem
          key={row.id}
          id={`geofence-list-item-${row.id}`}
          sx={{ width: '100%', borderTop: theme => theme.border.default }}
          disablePadding
        >
          <ListItemButton
            component={Link}
            to={row.id.toString()}
            sx={{ justifyContent: 'space-between', pt: 2, pb: 2 }}
            onClick={event => event.stopPropagation()}
            selected={hoveredIds.includes(row.id)}
            onMouseEnter={() => {
              hovering.current = true;
              onRowHover(row.id);
            }}
            onMouseLeave={() => {
              hovering.current = false;
              onRowHover(row.id);
            }}
          >
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', maxWidth: '90%' }}>
              <Box
                sx={{
                  backgroundColor: row.colour,
                  width: '1rem',
                  height: '1rem',
                  borderRadius: 1,
                  flexShrink: 0,
                }}
              />
              <ListItemText
                primary={row.name}
                primaryTypographyProps={{ sx: { overflowWrap: 'break-word' } }}
                secondary={row.secondaryText}
              />
            </Stack>
            <ArrowForwardIos sx={{ color: 'common.text' }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ManageListMapView;
