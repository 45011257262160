import { Stack } from '@mui/system';
import React, { useMemo } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  useGetNotificationRulesByGeofenceId,
} from 'apis/rest/eventNotifications/hooks';
import type { GeofenceResponseItem } from 'apis/rest/geofence/types';
import type { EventNotificationRuleWithGroupName } from 'apis/rest/eventNotifications/types';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeature from 'hooks/features/useFeature';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

interface NotificationGroupRowsProps{
  uniqueGroups: EventNotificationRuleWithGroupName[]
  onRowOpen: (id: number) => void;
  eventText: string;
}
//
const NotificationGroupRows = ({
  uniqueGroups,
  onRowOpen,
  eventText,
}: NotificationGroupRowsProps) => {
  const t = useTranslations('pages.manage.geofencing');
  return (
    <List disablePadding style={{ marginLeft: '-18px', marginRight: '-18px', marginBottom: '-18px' }}>
      {uniqueGroups.map(row => (
        <Box key={row.notificationGroupId}>
          <Divider />
          <ListItem sx={{ width: '100%' }} disablePadding>
            <Tooltip title={t('list.goToGroup')} placement="right">
              <ListItemButton
                sx={{ justifyContent: 'space-between', pt: 2, pb: 2 }}
                onClick={e => {
                  e.stopPropagation();
                  onRowOpen(row.notificationGroupId);
                }}
              >
                <Stack direction="column" spacing={0}>
                  <Typography>{row.notificationGroupName}</Typography>
                  <Typography>{`${t('list.events')}: ${eventText}`}</Typography>
                </Stack>
                <ArrowForwardIos sx={{ color: 'common.text' }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

const LoadingIndicator = () => (
  <Stack direction="row" sx={{ justifyContent: 'center', mb: 2 }}>
    <CircularProgress sx={{ m: 2 }} />
  </Stack>
);

const NotificationsList: React.FC<GeofenceTableProps> = ({ selectedGeofence, onRowOpen }) => {
  const t = useTranslations('pages.manage.geofencing');
  const { query } = useGetNotificationRulesByGeofenceId(selectedGeofence.id);
  const notificationRulesData = useMemo(() => query.data, [query.data]);
  const uniqueGroups = [...new Map(notificationRulesData?.map(v => [v.notificationGroupId, v])).values()];

  const eventText = useMemo(() => {
    switch (selectedGeofence?.category) {
      case 'AreaOfOperation':
        return t('list.exit');
      case 'RestrictedArea':
        return t('list.enter');
      case 'Generic':
        return t('list.both');
      default:
        return t('list.invalid');
    }
  }, [selectedGeofence?.category, t]);

  if (query.isLoading) return <LoadingIndicator />;
  if (uniqueGroups.length > 0) {
    return (
      <NotificationGroupRows
        onRowOpen={onRowOpen}
        uniqueGroups={uniqueGroups}
        eventText={eventText}
      />
    );
  }
  return <Alert severity="info">{t('errorsAndWarnings.noNotificationGroups')}</Alert>;
};

export interface GeofenceTableProps {
  selectedGeofence: Pick<GeofenceResponseItem, 'id' | 'category'>;
  onRowOpen: (id: number) => void;
}

export const GeofenceNotificationsTable = ({ selectedGeofence, onRowOpen }: GeofenceTableProps) => {
  const t = useTranslations('pages.manage.geofencing');
  const featureModules = useFeatureFlag('featureModules');
  const featureToggle = useFeature('manage.notifications');
  const featureAssets = useFeatureAssets('manage.notifications');

  const loading = featureModules === undefined
    || (!featureModules && featureToggle === undefined)
    || (featureModules && featureAssets.isLoading);

  const displayNotificatons = (featureModules && featureAssets.some) || (featureModules === false && featureToggle);

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Paper elevation={0}>
            <Typography fontWeight={700}>
              {t('notificationGroups')}
            </Typography>
          </Paper>
        </Stack>
        {loading && <LoadingIndicator />}
        {!loading && displayNotificatons && <NotificationsList selectedGeofence={selectedGeofence} onRowOpen={onRowOpen} />}
        {!loading && !displayNotificatons && <Alert severity="info">{t('errorsAndWarnings.eventNotificationsNotEnabled')}</Alert>}
      </Stack>
    </Paper>
  );
};
