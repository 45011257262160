import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import useTimezone from 'hooks/session/useTimezone';
import type { Moment } from 'moment';
import { type FC, type MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLatestPositionsForAssets } from 'repositories/reports/hooks';
import { getSelectedDay, getSelectedItem } from 'slices/app.slice';
import { hideAssetsGroup, hideAssetsOnMap, removeFromHiddenAssetGroups, showAssetsOnMap } from 'slices/map.slice';
import { clearAssetAndUrl } from 'slices/map/mapThunks';
import { useAppDispatch } from 'store/types';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import useStyles from '../results-styles';
import GroupedResultsList from './grouped-results-list.view';
import type { BucketHeader, BucketResult, GroupBy } from './types';

export interface GroupedResultsProps {
  results: AssetBasic[];
  groupBy: GroupBy;
  sortBy: string;
  selectAsset: (asset: AssetBasic) => void;
}

const timeBucketOrder = ['fifteenMinutes', 'hour', 'day', 'week', 'month', 'older', 'never'] as const;

const useBucketList = (results: AssetBasic[], groupBy: GroupBy, sortBy: string) => {
  const classes = useStyles();
  const t = useTranslations('omnibox.modules.groupQueryResults');
  const dispatch = useAppDispatch();

  const latestPositionsByAsset = useLatestPositionsForAssets(results);
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const hiddenInactiveAssets = useSelector<ReduxState, ReduxState['map']['hiddenInactiveAssets']>(
    state => state.map.hiddenInactiveAssets,
  );
  const hiddenAssets = useSelector<ReduxState, ReduxState['map']['hiddenAssets']>(state => state.map.hiddenAssets);
  const hiddenAssetGroups = useSelector<ReduxState, ReduxState['map']['hiddenAssetGroups']>(
    state => state.map.hiddenAssetGroups,
  );
  const selectedItem = useSelector(getSelectedItem);

  const getBucketNameForLatestActivity = useCallback(
    (latestActivity: Moment) => {
      const now = selectedDay ? moment.tz(selectedDay, timezone) : moment();
      if (latestActivity.isAfter(now.clone().subtract(15, 'minutes'))) return t('timeBuckets.fifteenMinutes');
      if (latestActivity.isAfter(now.clone().subtract(1, 'hour'))) return t('timeBuckets.hour');
      if (latestActivity.isAfter(now.clone().subtract(1, 'day'))) return t('timeBuckets.day');
      if (latestActivity.isAfter(now.clone().subtract(1, 'week'))) return t('timeBuckets.week');
      if (latestActivity.isAfter(now.clone().subtract(1, 'month'))) return t('timeBuckets.month');
      return t('timeBuckets.older');
    },
    [selectedDay, timezone, t],
  );

  const assetLabel = useAssetLabel();

  // group into buckets
  const buckets: Record<string, AssetBasic[]> = useMemo(() => {
    const sortedResults = [...results].sort((assetA, assetB) => {
      if (sortBy === 'activity') {
        return (latestPositionsByAsset[assetB.id]?.received || 0) - (latestPositionsByAsset[assetA.id]?.received || 0);
      }
      return assetLabel(assetA, '').localeCompare(assetLabel(assetB, ''));
    });

    return sortedResults.reduce<Record<string, AssetBasic[]>>((acc, r) => {
      let field = r[groupBy as keyof AssetBasic];
      let time: number | undefined;
      switch (groupBy) {
        case 'category':
          field = r.category;
          break;
        case 'owner':
          field = r.ownerName;
          break;
        case 'latestActivity':
          time = latestPositionsByAsset[r.id]?.received;
          field = time ? getBucketNameForLatestActivity(moment.unix(time)) : t('timeBuckets.never');
          break;
        case 'assetGroup':
          if (assetGroupsQuery.isLoading) {
            field = t('loading');
          } else {
            const filteredAssetGroups =
              assetGroupsQuery.data?.filter(ag => ag.assets.some((x: AssetBasic) => x.id === r.id)) ?? [];

            for (const ag of filteredAssetGroups) {
              acc[ag.name] = acc[ag.name] || [];
              if (!acc[ag.name].includes(r)) {
                acc[ag.name].push(r);
              }
            }

            if (filteredAssetGroups.length > 0) {
              field = 'InAssetGroup';
            }
          }
          break;
        default:
          break;
      }

      if (field && groupBy !== 'assetGroup') {
        acc[field as string] ??= [];
        acc[field as string].push(r);
      }
      if (!field || field.toString().trim() === '') {
        const unknownBucket = t('other');
        acc[unknownBucket] ??= [];
        acc[unknownBucket].push(r);
      }

      return acc;
    }, {});
  }, [
    latestPositionsByAsset,
    getBucketNameForLatestActivity,
    groupBy,
    sortBy,
    results,
    t,
    assetLabel,
    assetGroupsQuery,
  ]);

  const sortedBuckets = useMemo((): string[] => {
    if (groupBy === 'latestActivity') {
      return timeBucketOrder.map(bucket => t(`timeBuckets.${bucket}`));
    }

    if (groupBy === 'assetGroup') {
      return [
        ...Object.keys(buckets)
          .sort()
          .filter(x => x !== t('other')),
        t('other'),
      ];
    }

    return Object.keys(buckets).sort();
  }, [groupBy, buckets, t]);

  const toggleVisibility = useCallback(
    (e: MouseEvent, groupName: string): void => {
      e.stopPropagation();

      const assetsToBeToggledOnMap = buckets[groupName].filter(
        a => !hiddenInactiveAssets.find(asset => asset.id === a.id),
      );

      if (
        !hiddenAssetGroups.includes(groupName) &&
        !buckets[groupName].every(asset => !!hiddenAssets.find(a => a.id === asset.id))
      ) {
        const otherVisibleEntries = Object.entries(buckets)
          .filter(([bucketName]) => bucketName !== groupName && !hiddenAssetGroups.includes(bucketName))
          .flatMap(x => x[1])
          .map(x => x.id);
        const assetsToBeHiddenOnMap = assetsToBeToggledOnMap.filter(x => !otherVisibleEntries.includes(x.id));

        dispatch(hideAssetsGroup(groupName));
        dispatch(hideAssetsOnMap(assetsToBeHiddenOnMap));
      } else {
        dispatch(removeFromHiddenAssetGroups(groupName));
        dispatch(showAssetsOnMap(assetsToBeToggledOnMap));
      }
    },
    [buckets, hiddenAssetGroups, hiddenInactiveAssets, hiddenAssets, dispatch],
  );

  const displayVisibilityIcon = useCallback(
    (groupName: string): JSX.Element | null => {
      if (!buckets[groupName]) return null;

      if (
        groupBy !== 'assetGroup' &&
        buckets[groupName].every(asset => hiddenInactiveAssets.find(a => a.id === asset.id))
      ) {
        return <VisibilityOff className={classes.visibilityIconDisabled} />;
      }

      if (
        hiddenAssetGroups.includes(groupName) ||
        buckets[groupName].every(asset => hiddenAssets.find(a => a.id === asset.id))
      ) {
        if (!!selectedItem && hiddenAssets.find(a => a.id === selectedItem.id)) {
          dispatch(clearAssetAndUrl());
        }
        return (
          <Tooltip title={t('showOnMap')}>
            <Visibility onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
          </Tooltip>
        );
      }
      return (
        <Tooltip title={t('hideOnMap')}>
          <VisibilityOff onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
        </Tooltip>
      );
    },
    [
      buckets,
      hiddenAssetGroups,
      t,
      hiddenInactiveAssets,
      selectedItem,
      hiddenAssets,
      dispatch,
      toggleVisibility,
      groupBy,
      classes,
    ],
  );

  const getBucketResults = useCallback((bucket: string): AssetBasic[] => buckets[bucket] ?? [], [buckets]);

  const theme = useTheme();

  const bucketList = useMemo(
    () =>
      sortedBuckets
        .map<(BucketHeader | BucketResult)[]>(bucket => {
          const bucketResults = getBucketResults(bucket);
          if (!bucketResults.length) return [];
          const visibleItems = bucketResults
            .map<BucketResult>(result => ({
              type: 'assetResult',
              result,
              isHidden: hiddenAssetGroups.includes(bucket) || !!hiddenAssets.find(ha => ha.id === result.id),
              isSelected: !!selectedItem && selectedItem.id === result.id,
              height: 57,
            }))
            .filter(({ isHidden }) => !isHidden);

          if (visibleItems.length) visibleItems[visibleItems.length - 1].height += theme.spacingNumber(3);

          return [
            {
              type: 'header',
              bucket,
              visibilityIcon: displayVisibilityIcon(bucket),
              height: theme.spacingNumber(visibleItems.length ? 4 : 7),
            },
            ...visibleItems,
          ];
        })
        .reduce((acc, curr) => acc.concat(...curr), []),
    [sortedBuckets, displayVisibilityIcon, getBucketResults, hiddenAssets, selectedItem, theme, hiddenAssetGroups],
  );

  return bucketList;
};

const GroupedResults: FC<GroupedResultsProps> = ({ results, groupBy, sortBy, selectAsset }) => {
  const activeQuery = useSelector<ReduxState, ReduxState['app']['query']>(state => state.app.query);
  const bucketList = useBucketList(results, groupBy, sortBy);

  return <GroupedResultsList activeQuery={activeQuery} bucketList={bucketList} selectAsset={selectAsset} />;
};

export default GroupedResults;
