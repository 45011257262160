import { Localization, MaterialTableProps } from '@material-table/core';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useGetAllMarketSectorOrganisations, useGetMarketSector, useMutateUpdateMarketSector } from 'apis/rest/marketSector/hooks';
import { MarketSector } from 'apis/rest/marketSector/types';
import { useGetAllOrganisations } from 'apis/rest/memberships/hook';
import LoadingPage from 'components/pages/loading/loading-view';
import Page from 'components/pages/page';
import DetailPanel from 'components/shared/DetailPanel';
import LinkAbove from 'components/shared/linkAbove';
import PersistentTable from 'components/shared/persistentTable';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import SettingsSection from 'components/shared/settingsSection';
import StickyPagination from 'components/shared/stickyPagination';
import useSnackbar from 'hooks/useSnackbar';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

interface RowData {
  id: string;
  isPrimary: boolean;
  isStrategic?: boolean;
  name: string;
  enabled: boolean;
  userCount?: number;
}

const MarketSectorEditPage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [priority, setPriority] = useState<string>();
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(p => <StickyPagination container={footerRef.current} {...p} />, []);
  const localization: Localization = { header: { actions: '' } };

  const marketSectorId = params.id ?? '';
  const marketSectorQuery = useGetMarketSector(marketSectorId);
  const updateMutation = useMutateUpdateMarketSector();
  const marketSectorOrgs = useGetAllMarketSectorOrganisations();
  const allOrgs = useGetAllOrganisations();

  const current: MarketSector = useMemo(() => marketSectorQuery.data ?? { id: '', name: '', description: '' }, [marketSectorQuery]);

  const data: RowData[] = useMemo(() => {
    if (marketSectorOrgs.isSuccess && allOrgs.query.isSuccess) {
      const orgIds = marketSectorOrgs.data.find(x => x.marketSectorId === marketSectorId)?.organisationIds ?? [];
      const secondaryOrgIds = marketSectorOrgs.data.find(x => x.marketSectorId === marketSectorId)?.secondaryOrganisationIds ?? [];
      const strategicOrgIds = marketSectorOrgs.data.find(x => x.marketSectorId === marketSectorId)?.strategicOrganisationIds ?? [];
      return allOrgs.query.data.map(x => {
        const isPrimary = orgIds.some(y => y === x.id);
        const isSecondary = secondaryOrgIds.some(y => y === x.id);
        const isStrategic = strategicOrgIds.some(y => y === x.id);
        return isPrimary || isSecondary ? {
          id: x.id,
          isPrimary,
          isStrategic,
          name: x.name,
          enabled: x.enabled,
          userCount: x.userCount
        } : null;
      }).filter(x => x);
    }
    return [];
  }, [marketSectorOrgs, allOrgs, marketSectorId]);

  const save = useCallback(() => {
    updateMutation.mutate(
      {
        id: marketSectorId,
        name: name ?? current.name,
        description: description ?? current.description,
        priority: priority ?? current.priority,
      },
      {
        onSuccess: () => {
          snackbar.display({ id: 'marketSectorUpdated', text: 'Market sector data saved', type: 'success' });
        },
        onError: () => {
          snackbar.display({ id: 'marketSectorUpdateError', text: 'Failed to save market sector', type: 'error' });
        },
      },
    );
  }, [current, description, marketSectorId, name, priority, snackbar, updateMutation]);

  const reset = useCallback(() => {
    setName(undefined);
    setDescription(undefined);
    setPriority(undefined);
  }, []);

  const columns = useMemo<MaterialTableProps<RowData>['columns']>(() => ([
    {
      title: 'Organisation Name',
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (<Typography>{row.name}</Typography>)
    },
    {
      title: 'Identifier',
      field: 'id',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (<Typography>{row.id}</Typography>)
    },
    // {
    //   title: 'Enabled',
    //   field: 'enabled',
    //   headerStyle: { textAlign: 'left' },
    //   cellStyle: { textAlign: 'left' },
    //   defaultSort: 'asc',
    //   filtering: false,
    //   render: row => (<Typography>{row.enabled.toString()}</Typography>)
    // },
    {
      title: 'Primary Sector',
      field: 'isPrimary',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      filtering: false,
      render: row => (<Typography>{row.isPrimary.toString()}</Typography>)
    },
    {
      title: 'Strategic',
      field: 'isStrategic',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      filtering: false,
      render: row => (<Typography>{row.isStrategic?.toString()}</Typography>)
    },
    {
      title: 'Users',
      field: 'userCount',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      filtering: false,
      render: row => (<Typography>{row.userCount}</Typography>)
    },
  ]), []);

  if (marketSectorQuery.isLoading) {
    return (
      <LoadingPage />
    );
  }

  return (
    <Page title="Market Sector Edit">
      <SettingsMenuPageWrapper p={8} id="pageScrollParent">
        <Container maxWidth={false}>
          <LinkAbove />
          <Box>
            <Typography variant="h1" gutterBottom>Market Sectors</Typography>
            <Typography paragraph>Edit Market Sector: {marketSectorQuery.data?.name}</Typography>
          </Box>

          <SettingsSection title="Details">
            <DetailPanel>
              <Stack spacing={3} mx={3}>
                <Stack direction="column" spacing={3}>
                  <TextField
                    label="ID"
                    value={current.id}
                    disabled
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: !!current.id }}
                    fullWidth />
                  <TextField
                    label="Name"
                    value={name ?? current?.name}
                    onChange={event => setName(event.target.value)}
                    disabled={updateMutation.isPending}
                    inputProps={{ maxLength: 100 }}
                    InputLabelProps={{ shrink: !!(name ?? current?.name) }}
                    fullWidth />
                  <TextField
                    label="Description"
                    value={description ?? current?.description}
                    onChange={event => setDescription(event.target.value)}
                    disabled={updateMutation.isPending}
                    inputProps={{ maxLength: 1000 }}
                    InputLabelProps={{ shrink: !!(description ?? current?.description) }}
                    multiline
                    rows={4}
                    spellCheck
                    fullWidth />
                  <TextField
                    label="Priority"
                    value={priority ?? current?.priority}
                    onChange={event => setPriority(event.target.value)}
                    disabled={updateMutation.isPending}
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: !!(priority ?? current?.priority) }}
                    fullWidth />
                </Stack>
                <Stack direction="row" spacing={3} justifyContent="flex-end" height="4rem">
                  <Button variant="outlined" size="large" sx={{ minWidth: '10rem' }} onClick={() => reset()}>
                    Cancel
                  </Button>
                  <Button variant="contained" size="large" sx={{ minWidth: '10rem' }} onClick={() => save()}>
                    Save
                  </Button>
                </Stack>
              </Stack>
            </DetailPanel>
          </SettingsSection>

          <SettingsSection title="Organisations">
            <DetailPanel spacing={3}>
              <PersistentTable<RowData>
                settingsCategory="marketSectorsTable"
                isLoading={marketSectorOrgs.isLoading || allOrgs.query.isLoading}
                components={{
                  Pagination, Container: Box, Toolbar: () => null
                }}
                data={data}
                columns={columns}
                localization={localization}
                options={{
                  selection: false,
                  search: false,
                  filtering: true,
                  draggable: false,
                  showTitle: false,
                  actionsColumnIndex: -1,
                  paging: true,
                  emptyRowsWhenPaging: false,
                  headerStyle: { position: 'sticky', top: 0 }
                }}
                sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }} />
              <Box ref={footerRef} bottom={0} sx={{
                '& .MuiToolbar-regular': { padding: 10 / 3 },
                margin: '0 !important'
              }} />

            </DetailPanel>
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default MarketSectorEditPage;
