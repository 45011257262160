import { Box, Button, List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { AssignAssetGroupDialog } from 'components/dialogs/assetGroups/assignAssetsDialog';
import { useAssetsAndDevices } from 'hooks/assetGroup/useAssetAndDevices';
import React, {useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { manageBasesSlice } from 'slices/manageBases.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { useTranslations } from 'use-intl';

const { actions, selectors } = manageBasesSlice;

export interface BasesAssetListProps {
  onSubmit: (assetIds: number[]) => void;
}

export const BasesAssetList = ({ onSubmit }: BasesAssetListProps) => {
  const t = useTranslations('pages.manage.bases.assets');
  const assetsQuery = useGetAssetsList();
  const assets = assetsQuery.query.data;
  const dispatch = useAppDispatch();

  const isLoading = assetsQuery.query.isPending;
  const [isAssignDevicesDialogOpen, setIsAssignDevicesDialogOpen] = useState(false);
  const assetIds = useSelector(selectors.selectFormData).assetIds ?? [];

  const assetRows = useMemo(
    () =>
      assetIds.reduce<AssetBasic[]>((acc, id) => {
        const asset = assets?.find(a => a.id === id);
        if (asset) {
          acc.push(asset);
        }
        return acc;
      }, []),
    [assets, assetIds],
  );

  const assetRowIds = useMemo( () => assetRows.map(m => ({
      assetId: m.id,
      deviceId: m.deviceId ?? 0,
    })), [assetRows])

  const assetsAndDevices = useAssetsAndDevices(assetRowIds);

  const handleSaveAssetList = (assetIds: number[]) => {
    dispatch(actions.setBaseAssetIds(assetIds));
    onSubmit(assetIds);
    setIsAssignDevicesDialogOpen(false);
  };

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography fontWeight={700}>{t('title')}</Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            sx={{ minWidth: '10rem', height: '4rem' }}
            onClick={() => setIsAssignDevicesDialogOpen(true)}
          >
            {t('manage')}
          </Button>
        </Stack>
        {/*  Add asset list here*/}
        <List sx={{ p: 0 }}>
          {assetRows.map(row => (
            <ListItem
              key={row.id}
              id={`bases-asset-item-${row.id}`}
              sx={{ width: '100%', borderTop: theme => theme.border.default }}
              disablePadding
            >
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center', pt: 2, pb: 2 }}>
                <Box
                  sx={{
                    backgroundColor: row.colour,
                    width: '1rem',
                    height: '1rem',
                    borderRadius: 1,
                    flexShrink: 0,
                  }}
                />
                <ListItemText
                  primary={row.name}
                  secondary={
                    <Typography variant="body3">
                      {row?.make} {row?.model} {row?.variant}
                    </Typography>
                  }
                />
              </Stack>
            </ListItem>
          ))}
        </List>
      </Stack>
      <AssignAssetGroupDialog
        title={t('dialogTitle', { name: 'currentBase' })}
        isReady={!isLoading}
        ariaLabel="Assign assets to base"
        assetDevicesPairs={assetsAndDevices}
        onClose={() => setIsAssignDevicesDialogOpen(false)}
        onSubmit={ids => {
          handleSaveAssetList(ids);
        }}
        open={isAssignDevicesDialogOpen}
      />
    </Paper>
  );
};
