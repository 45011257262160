import { ArrowBack } from '@mui/icons-material';
import { Box, CircularProgress, Grid, IconButton, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import type { UseQueryResult } from '@tanstack/react-query';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import SelectAssets from 'components/pages/sharing/selectAssets';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel, { useAssetLabel } from 'components/shared/assetLabel';
import { isAssetWithDevice } from 'helpers/assets';
import { type PreProcessedLegs, getLegMatches, preProcessLegs, useLegsForAssets } from 'helpers/legs';
import { paginateArray } from 'helpers/pagination';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import useTimezone from 'hooks/session/useTimezone';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import { useStartOfDay } from 'hooks/useStartOfDay';
import { groupBy } from 'lodash/fp';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAssetsInferredEventsByReportId } from 'repositories/inferredEvents/hooks';
import { useAssetsReports, useLatestPositionsForAssets } from 'repositories/reports/hooks';
import { getSelectedDay, selectLeg } from 'slices/app.slice';
import { getSelectedLeg, getSelectedMapId, setTrailHighlight } from 'slices/map.slice';
import { clearAssetAndUrl, selectAssetAndSetUrl } from 'slices/map/mapThunks';
import { updateSetting } from 'slices/settings.slice';
import { updateLocalUiSettingsField, updateUiSettingField } from 'slices/settings/uiSettings.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { getTimes } from 'suncalc';
import { useTranslations } from 'use-intl';
import Analysisbox from '../analysisbox';
import { AssetTimelineGraph, MARGIN_TOP } from './AssetTimelineGraph';
import { AssetTimelineGraphAxis } from './AssetTimelineGraphAxis';
import LegPopper from './LegPopper';

const HEIGHT_PER_ASSET = 32;

interface AnalysisBoxTimelineProps {
  selectedAsset: AssetWithDevice | AssetBasic | null;
  drawerHeight: number;
}

export const AnalysisBoxTimeline = ({ drawerHeight, selectedAsset }: AnalysisBoxTimelineProps) => {
  const t = useTranslations('analysisbox.timeline');
  const assetsQuery = useGetAssetsList({ select: a => a.filter(isAssetWithDevice) }).query;
  const selectedDay = useSelector(getSelectedDay);
  const selectedLeg = useSelector(getSelectedLeg);
  const selectedReport = useSelector<ReduxState, Report | null>(
    state => state.reports.selectedReportPerMap[state.map.selectedMapId],
  );
  const selectedMapId = useSelector(getSelectedMapId);
  const assetLabel = useAssetLabel();
  const timezone = useTimezone();
  const startOfDay = useStartOfDay();
  const dispatch = useAppDispatch();
  const isUsercodeUser = useIsUsercodeLogin();
  const uiSettings = useUiSettings();

  //  searchAssets
  const assets = assetsQuery.data ?? [];
  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const devicesById = devicesByIdQuery.data;
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>([]);

  const assetsToUse = useMemo(() => (selectedAsset ? [selectedAsset] : assets), [selectedAsset, assets]);

  const reportsForAssets = useAssetsReports(assetsToUse);
  const inferredEvents = useAssetsInferredEventsByReportId(assetsToUse);

  const preProcessedLegsForAssets = useMemo(
    () =>
      assetsToUse.reduce<Record<number, PreProcessedLegs>>((acc, currentAsset) => {
        if (!reportsForAssets[currentAsset.id]) {
          return acc;
        }

        const reportWithInferredEvents = reportsForAssets[currentAsset.id].map(r => ({
          ...r,
          inferredEvents: inferredEvents?.[currentAsset.id]?.[r.id] ?? null,
        }));

        acc[currentAsset.id] = preProcessLegs(
          reportWithInferredEvents,
          [],
          currentAsset.category,
          currentAsset?.deviceMake,
        );
        return acc;
      }, {}),
    [assetsToUse, reportsForAssets, inferredEvents],
  );

  // Find assets with at least one report later than the start of the day
  const todayAssets = useMemo(
    () =>
      assetsToUse
        .filter(a => {
          const id = a.id;
          const legData = preProcessedLegsForAssets[id];

          if (!legData || legData.legIndices.length === 0 || legData.reportsAsc.length === 0) {
            return false;
          }
          const { legStringMapped, legIndices, reportsAsc } = legData;

          const legMatches = getLegMatches(legStringMapped, legIndices, reportsAsc);

          const hasReportedToday = reportsForAssets[id].some(r => r.received * 1000 > startOfDay.toMillis());
          const legIsActive = legMatches.some(leg => leg.end === undefined || leg.end === leg.start);
          const legEndedToday = reportsAsc[legIndices.at(-1) ?? 0].received * 1000 > startOfDay.toMillis();

          return hasReportedToday && (legIsActive || legEndedToday);
        })
        .sort((assetA, assetB) => assetLabel(assetA, '').localeCompare(assetLabel(assetB, ''))),
    [reportsForAssets, startOfDay, preProcessedLegsForAssets, assetsToUse, assetLabel],
  );

  const todayAssetsWithDevice = todayAssets.filter(isAssetWithDevice);
  const assetGroups = useMemo(() => {
    const todayAssetsIds = todayAssets.map(a => a.id);
    return assetGroupsQuery?.data?.filter(g => g.assets.some(a => todayAssetsIds.includes(a.id))) ?? [];
  }, [assetGroupsQuery, todayAssets]);
  const deviceIds = useDeviceIdByAssets(todayAssetsWithDevice);
  const assetsByDeviceId = useAssetsByDeviceId(todayAssetsWithDevice);

  const paginatedAssets = useMemo(() => {
    const assetArray = selectedDeviceIds.length
      ? selectedDeviceIds.reduce<AssetWithDevice[]>((acc, id) => {
          if (assetsByDeviceId[id]) {
            acc.push(assetsByDeviceId[id]);
          }
          return acc;
        }, [])
      : todayAssets;
    return paginateArray<AssetWithDevice | AssetBasic>(assetArray, currentPage, uiSettings.rowsPerPage);
  }, [currentPage, uiSettings.rowsPerPage, todayAssets, selectedDeviceIds]);

  const legsByAssetData = useLegsForAssets(paginatedAssets);
  const legsByAsset = useMemo(
    () => ({
      data: Object.entries(legsByAssetData.data ?? {}).reduce<Record<number, Leg[]>>((acc, [assetId, legs]) => {
        acc[assetId as unknown as number] = legs.filter(leg => leg.start * 1000 > startOfDay.toMillis());
        return acc;
      }, {}),
      pending: legsByAssetData.pending,
    }),
    [startOfDay, legsByAssetData],
  );

  const positions = useLatestPositionsForAssets(paginatedAssets);

  const assetsAndSunsetTimes = useMemo(
    () =>
      paginatedAssets.flatMap(a => {
        const position = positions[a.id];
        if (!position) {
          return [];
        }
        // NOTE: `getTimes` returns invalid dates if you pass a negative altitude :(
        const times = getTimes(
          startOfDay.toUTC().toJSDate(),
          position.latitude,
          position.longitude,
          Math.max(position.altitude, 0),
        );
        const sunrise = DateTime.fromJSDate(times.sunrise, { zone: 'utc' }).setZone(timezone).toMillis();
        const sunset = DateTime.fromJSDate(times.sunset, { zone: 'utc' }).setZone(timezone).toMillis();
        const timesNext = getTimes(
          startOfDay.plus({ day: 1 }).toUTC().toJSDate(),
          position.latitude,
          position.longitude,
          Math.max(position.altitude, 0),
        );
        const sunriseNext = DateTime.fromJSDate(timesNext.sunrise, { zone: 'utc' }).setZone(timezone).toMillis();
        const sunsetNext = DateTime.fromJSDate(timesNext.sunset, { zone: 'utc' }).setZone(timezone).toMillis();
        return { ...a, sunrise, sunset, next: { sunrise: sunriseNext, sunset: sunsetNext } };
      }),
    [paginatedAssets, positions, startOfDay, timezone],
  );

  const [chartWidth, setChartWidth] = useState(0);
  const [legPopper, setLegPopper] = useState<{ leg?: Leg; element?: SVGElement }>({});

  const dispatchSelectAsset = useCallback(
    (a?: AssetWithDevice | AssetBasic) => {
      dispatch(selectAssetAndSetUrl({ mapId: selectedMapId, asset: a }));
    },
    [dispatch, selectedMapId],
  );

  const dispatchClearSelection = useCallback(() => {
    dispatch(clearAssetAndUrl());
  }, [dispatch]);

  const dispatchSelectLeg = useCallback(
    (l: Leg) => {
      const legToSelect = l === null || selectedLeg?.id === l?.id ? null : l;
      if (selectedAsset?.id !== l.assetId) {
        const legAsset = assets.find(a => a.id === l?.assetId);
        if (legAsset) {
          dispatchSelectAsset(legAsset);
        }
      }
      dispatch(selectLeg({ leg: legToSelect }));
    },
    [selectedLeg, selectedAsset, dispatch, assets, dispatchSelectAsset],
  );

  const dispatchTrailHighlight = useCallback(
    (l?: Leg | null, e?: SVGSVGElement) => {
      setLegPopper({ leg: l ?? undefined, element: e });
      if (!l) {
        dispatch(setTrailHighlight({ highlightTrail: null }));
        return;
      }
      const reports = reportsForAssets[l.assetId].filter(r => r.received >= l.start && r.received <= l.end);
      if (reports.length >= 0) {
        dispatch(setTrailHighlight({ highlightTrail: { assetId: l.assetId, reports } }));
      }
    },
    [dispatch, reportsForAssets],
  );

  const updateRowsPerPage = (pageSize: number) => {
    if (isUsercodeUser) {
      updateSetting({ category: 'ui', field: 'rowsPerPage', value: pageSize });
      dispatch(updateLocalUiSettingsField({ field: 'rowsPerPage', value: pageSize }));
    } else {
      dispatch(updateUiSettingField({ field: 'rowsPerPage', value: pageSize }));
    }
  };

  const isLoading =
    !selectedAsset &&
    (assetsQuery.isPending || legsByAsset.pending || devicesByIdQuery.isPending || assetGroupsQuery.isPending);

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minHeight: 0,
      })}
      aria-label={'timelineContainer'}
    >
      <Box
        sx={{
          overflow: 'scroll',
          overflowX: 'visible',
          scrollbarGutter: 'stable',
          pr: 3,
        }}
      >
        {selectedAsset && (
          <Tooltip title={t('allAssets')} placement="top">
            <IconButton
              sx={{ position: 'absolute', top: 0, left: 0, mt: 3, ml: 2, zIndex: 1 }}
              size="small"
              onClick={dispatchClearSelection}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
        )}
        {!selectedAsset && (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box sx={{ width: '75%', py: 2 }}>
              <SelectAssets
                label={t('searchAssets')}
                deviceIds={deviceIds}
                selectedDeviceIds={selectedDeviceIds}
                assetsByDeviceId={assetsByDeviceId}
                devicesById={devicesById}
                setSelectedDeviceIds={value => {
                  setSelectedDeviceIds(value);
                }}
                assetGroups={assetGroups}
                isLoading={isLoading}
                textFieldProps={{
                  size: 'small',
                }}
                maxAssets={6}
              />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            position: 'sticky',
            top: 0,
            bgcolor: 'common.white',
          }}
        >
          <AssetTimelineGraphAxis startTimeIso={selectedDay} width={chartWidth} />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: "'. axis' 'assets graph'",
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'top',
            maxHeight: drawerHeight - 135 - MARGIN_TOP,
          }}
        >
          <Box display="grid" gridAutoRows="1fr" height="min-content">
            {assetsAndSunsetTimes.map((a, i) => (
              <Stack
                key={a.id}
                direction="row"
                alignItems="center"
                spacing={1}
                py={1}
                pr={2}
                borderBottom={i === assetsAndSunsetTimes.length - 1 ? undefined : 'border.default'}
                height={HEIGHT_PER_ASSET}
              >
                <AssetColourMarker assetId={a.id} />
                <Typography variant="h3">
                  <AssetLabel asset={a} />
                </Typography>
              </Stack>
            ))}
          </Box>
          <LegPopper leg={legPopper.leg} anchorEl={legPopper.element} timezone={timezone} />
          <AssetTimelineGraph
            assets={assetsAndSunsetTimes}
            legsByAsset={legsByAsset.data}
            heightPerAsset={HEIGHT_PER_ASSET}
            startTime={startOfDay}
            setWidth={setChartWidth}
            onLegHover={dispatchTrailHighlight}
            onLegClick={dispatchSelectLeg}
            onAssetClick={dispatchSelectAsset}
            selectedLeg={selectedLeg}
            selectedReport={selectedReport}
          />
        </Box>
      </Box>
      {selectedAsset && (
        <Box mt={2} pt={2} flex="1" borderTop={theme => theme.border.default} minHeight="0" display="flex">
          <Analysisbox drawerHeight={drawerHeight - 90} timeline />
        </Box>
      )}
      {!selectedAsset && (
        <Grid container justifyContent={'flex-end'} flex={1} alignSelf={'flex-end'} flexDirection={'column'}>
          <TablePagination
            count={todayAssets.length}
            page={currentPage}
            rowsPerPage={uiSettings.rowsPerPage}
            onRowsPerPageChange={e => updateRowsPerPage(Number.parseInt(e.target.value, 10))}
            onPageChange={(_, page) => setCurrentPage(page)}
            aria-label={'timelinePagination'}
            sx={{
              border: 0,
            }}
          />
        </Grid>
      )}
    </Box>
  );
};
