import { Tab } from '@mui/material';
import { Link } from 'react-router';
import { LocationDescriptor } from 'history';
import React from 'react';
import { StyledTabs } from './linkTabs-styles';

interface LinkTabsProps {
  value: string
  label?: string
  items: {
    value: string,
    to: LocationDescriptor
    label: React.ReactNode
  }[]
}

const LinkTabs = ({ value, label, items }: LinkTabsProps) => {
  return (
    <StyledTabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      variant="standard"
      aria-label={label}
    >
      {items.map(item => (
        <Tab key={item.value} value={item.value} component={Link} to={item.to} label={item.label} />
      ))}
    </StyledTabs>
  );
};

export default LinkTabs;
