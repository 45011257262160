import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AnalysisboxContainer from 'components/analysisboxContainer';
import AssetContextbox from 'components/contextbox/asset';
import EditDialog from 'components/dialogs/markers/editDialog';
import IMAGES from 'resources/resources';
import MapContainer from 'components/mapContainer';
import Omnibox from '../../omnibox';
import Page from 'components/pages/page';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useTimezone from 'hooks/session/useTimezone';
import { CreateSearchPatternDialog, DeleteSearchPatternDialog } from 'components/dialogs/searchPatterns';
import { EditPointAnnotationDialog } from 'components/dialogs/annotations/editPointDialog';
import { closeCreateMarkerDialog, selectCreateMarkerDialog, setIsPlacingMarker } from 'slices/markers.slice';
import { getSelectedDay, getSelectedItem } from 'slices/app.slice';
import {
  createOrUpdatePath,
  createOrUpdatePoint,
  selectEditPathDialog,
  selectEditPointDialog, setEditPathDialog,
  setEditPointDialog
} from 'slices/map/annotations.slice';
import { useAppDispatch } from 'store/types';
import { useCreateMarker } from 'apis/rest/markers/hooks';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import { EditPathAnnotationDialog } from 'components/dialogs/annotations/editPathDialog';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useStyles from './map-styles';

const MapPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const t = useTranslations();
  const createMarkerDialog = useSelector(selectCreateMarkerDialog);
  const createMarkerMutation = useCreateMarker();

  const editPointDialog = useSelector(selectEditPointDialog);
  const editPathDialog = useSelector(selectEditPathDialog);

  const selectedItem = useSelector(getSelectedItem);
  const organisationId = useOrganisationId();
  const contextboxOpen = useSelector((state: ReduxState) => state.ui.contextboxOpen);

  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);
  const uiSettings = useUiSettings();

  const enableSearchPatterns = useFeatureAssets('map.searchPatterns').some;

  return (
    <Page title={t('pageTitles.map')}>
      <Omnibox />

      <Stack className={classes.mapWrapper}>
        {(uiSettings.displayMode ?? false) && (
          <img className="bg-slate-800/80 absolute top-[8.1px] left-3.5 w-48 rounded-xl py-2 pl-2 pr-4 m-2" alt="tp-logo" src={IMAGES.logo_light} style={{ zIndex: 2 }} />
        )}
        <MapContainer key={organisationId} />
        <AnalysisboxContainer selectedAsset={selectedItem} />
      </Stack>

      {selectedItem && <AssetContextbox selectedAsset={selectedItem} now={now} contextboxOpen={contextboxOpen} />}

      <EditDialog
        isLoading={createMarkerMutation.isPending}
        open={createMarkerDialog.open}
        title={t('dialogs.markers.create.title')}
        latLng={createMarkerDialog.latLng}
        onClose={() => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
        }}
        onSubmit={marker => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
          createMarkerMutation.mutate({ marker });
        }}
      />

      <EditPointAnnotationDialog
        open={!!editPointDialog}
        point={editPointDialog}
        isLoading={false}
        onClose={() => dispatch(setEditPointDialog(undefined))}
        onSubmit={p => dispatch(createOrUpdatePoint(p))}
        title={t(editPointDialog?.id ? 'dialogs.annotations.point.title.edit' : 'dialogs.annotations.point.title.create')}
      />

      <EditPathAnnotationDialog
        open={!!editPathDialog}
        path={editPathDialog}
        isLoading={false}
        onClose={() => dispatch(setEditPathDialog(undefined))}
        onSubmit={p => dispatch(createOrUpdatePath(p))}
        title={t(editPathDialog?.id ? 'dialogs.annotations.path.title.edit' : 'dialogs.annotations.path.title.create')}
      />

      {enableSearchPatterns && (
        <>
          <CreateSearchPatternDialog />
          <DeleteSearchPatternDialog />
        </>
      )}
    </Page>
  );
};

export default MapPage;
