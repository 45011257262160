import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { Box, ToggleButtonGroup, ToggleButton, FormControl, Paper, Stack, Typography, IconButton } from '@mui/material';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import Page from 'components/pages/page';
import { Route, Routes, useMatch, useSearchParams } from 'react-router';
import sortBy from 'lodash/fp/sortBy';
import { useGetBasicTripsForAssets } from 'apis/rest/trips/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { isAssetWithDevice, isAviationAsset } from 'helpers/assets';
import AssetLabel, { useAssetLabel } from 'components/shared/assetLabel';
import AssetColourMarker from 'components/shared/assetColourMarker';
import useTimezone from 'hooks/session/useTimezone';
import { selectAssetsNoDrops } from 'slices/statsFilter.slice';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import SelectAssets from 'components/pages/sharing/selectAssets';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import { parseSearchParams } from 'utils/parseSearchParams';
import useFeature from 'hooks/features/useFeature';
import LinkAbove from 'components/shared/linkAbove';
import { useGetDropsForAssets } from 'apis/rest/firefighting/hooks';
import { DropGroup } from 'apis/rest/firefighting/types';
import { TripBasic } from 'apis/rest/trips/types';
import LoadingPage from 'components/pages/loading';
import { useSize } from 'hooks/useSize';
import CloseIcon from '@mui/icons-material/Close';
import { uniq } from 'lodash/fp';
import { DateRangeSelection } from '../tripAnalysis/dateRangeSelection';
import { useCSVFirefightingFormatter } from './statistics';
import FirefightingMap from './FirefightingMap';
import GroupedDropsScorecard from './GroupedDropsScorecard';
import HoveredDropGroupOverlay from './HoveredDropGroupOverlay';
import HoveredDropClusterOverlay from './HoveredDropClusterOverlay';
import HoveredFillClusterOverlay from './HoveredFillClusterOverlay';
import HoveredInfoOverlay from './HoveredInfoOverlay';
import { FirefightingSelection } from './types';
import { updateSetting } from 'slices/settings.slice';

type Query = ReduxState['settings']['tripAnalysis']['query'];

const NO_ASSETS: never[] = [];

export interface OutletContextValue {
  visibleSuppressants: Record<Suppressant, boolean>
  setVisibleSuppressants: Dispatch<SetStateAction<Record<Suppressant, boolean>>>
  setHoveredDropGroup: Dispatch<SetStateAction<string | undefined>>
  setSelectedDropGroup: Dispatch<SetStateAction<string | undefined>>
  assetIds: number[]
  dropGroups: DropGroup[]
  trips: TripBasic[]
}

const selectAviationAssetsWithDevices = (assets: AssetBasic[]) => assets.filter((f): f is AssetWithDevice => isAviationAsset(f) && isAssetWithDevice(f));

const FirefightingLayout = (): JSX.Element => {
  const t = useTranslations('pages.reporting.firefighting');

  const matches = useMatch('/insights/firefighting/asset/:assetId');
  const persistedQuery = useSelector<ReduxState, Query>(state => state.settings.tripAnalysis.query);
  const timezone = useTimezone();
  const [searchParams, setSearchParams] = useSearchParams({
    from: persistedQuery?.from ? DateTime.fromMillis(persistedQuery.from).toISODate() : DateTime.now().startOf('day').minus({ days: 6 }).toISODate(),
    until: persistedQuery?.until ? DateTime.fromMillis(persistedQuery.until).toISODate() : DateTime.now().endOf('day').toISODate()
  });
  const assetId = matches ? parseInt(matches?.params?.assetId ?? '0', 10) : undefined;
  const { fromUrl, untilUrl, devicesUrl } = parseSearchParams(searchParams, timezone);
  const query = useMemo<Query & { from: number, until: number }>(() => ({
    ...persistedQuery,
    from: fromUrl ?? persistedQuery.from ?? DateTime.now().startOf('day').minus({ days: 6 }).toMillis(),
    until: untilUrl ?? persistedQuery.until ?? DateTime.now().endOf('day').toMillis(),
  }), [fromUrl, persistedQuery, untilUrl]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>(devicesUrl);

  const tz = useTimezone();
  const [datePickerRange, setDatePickerRange] = useState<{ from: string, until: string }>({
    from: DateTime.fromMillis(query.from).toISODate(),
    until: DateTime.fromMillis(query.until).toISODate(),
  });

  const dispatch = useAppDispatch();
  const setQuery = useCallback((newQuery: Query) => dispatch(updateSetting({ category: 'tripAnalysis', field: 'query', value: newQuery })), [dispatch]);

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const assetIds = useMemo(() => (assetId === undefined ? (query?.assets ?? NO_ASSETS) : [assetId]), [assetId, query?.assets]);

  const dropsQuery = useGetDropsForAssets(
    assetIds,
    query.from,
    query.until
  );

  const data = useMemo(() => ({
    dropGroups: dropsQuery.data?.dropGroups ?? [],
    dropClusters: dropsQuery.data?.dropClusters ?? [],
    fillClusters: dropsQuery.data?.fillClusters ?? [],
  }), [dropsQuery.data]);

  const tripsQuery = useGetBasicTripsForAssets<TripBasic[]>(
    assetIds,
    query.from,
    query.until,
  );

  const trips = useMemo(() => (
    tripsQuery.data ? sortBy(['assetId', 'startTimeMs'], Object.values(tripsQuery.data)) : []
  ), [tripsQuery.data]);

  const setDateRange = useCallback((from: string, until: string) => {
    setDatePickerRange({ from, until });
    const fromMillis = DateTime.fromISO(from, { zone: tz }).startOf('day').toMillis();
    const untilMillis = DateTime.fromISO(until, { zone: tz }).endOf('day').toMillis();
    setQuery({ ...query, from: fromMillis, until: untilMillis });
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set('from', from);
      newParams.set('until', until);
      return newParams;
    });
  }, [setQuery, query, tz, setSearchParams]);

  const [visibleSuppressants] = useState<Record<Suppressant, boolean>>({
    Water: true,
    FreshWater: true,
    SaltWater: true,
    Retardant: true,
    Foam: true,
    Gel: true,
    Unknown: true,
  });

  const [hoveredDropGroup, setHoveredDropGroup] = useState<string | undefined>();
  const [hoveredDropCluster, setHoveredDropCluster] = useState<string | undefined>();
  const [hoveredFillCluster, setHoveredFillCluster] = useState<string | undefined>();

  const assetsQuery = useGetAssetsList<AssetWithDevice[]>({ select: selectAviationAssetsWithDevices }).query;

  const assets = assetsQuery.data ?? NO_ASSETS;
  const deviceIds = useDeviceIdByAssets(assets);
  const assetsByDeviceId = useAssetsByDeviceId(assets);

  const selectedAsset = assetsQuery.data?.find(asset => asset.id === assetId);

  useEffect(() => {
    setDateRange(datePickerRange.from, datePickerRange.until);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tz]);

  useEffect(() => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId)).map(a => a.id);
    setQuery({ ...query, assets: selectedAssets });
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), devices: selectedDeviceIds.map(String) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceIds, assets]);

  const devicesById = devicesByIdQuery.data ?? [];
  const assetGroups = assetGroupsQuery.data;

  const isLoading = devicesByIdQuery.isLoading || assetGroupsQuery.isLoading || assetsQuery.isLoading || dropsQuery.isLoading || tripsQuery.isLoading;

  const [dropFilter, setDropFilter] = useState<Partial<DropGroup>>({});
  const [grouping, setGrouping] = useState<'asset' | 'source' | 'incident'>('asset');
  const [selection, setSelection] = useState<FirefightingSelection | undefined>(undefined);

  const dataForMap = useMemo(() => {
    if (selection === undefined) {
      return data;
    }
    if (selection[0] === 'asset') {
      const selectedAssetId = +selection[1];
      return {
        dropGroups: data.dropGroups.filter(dg => dg.assetId === selectedAssetId),
        fillClusters: data.fillClusters.filter(fc => fc.assetIds.includes(selectedAssetId)),
        dropClusters: data.dropClusters.filter(dc => dc.assetIds.includes(selectedAssetId)),
      };
    }
    if (selection[0] === 'source') {
      const sourceId = selection[1];
      const selectedDropGroups = data.dropGroups.filter(dg => dg.fills.some(f => f.clusterId === sourceId));
      const dropClusterIds = uniq(selectedDropGroups.map(dg => dg.clusterId));
      return {
        dropGroups: selectedDropGroups,
        fillClusters: data.fillClusters.filter(fc => fc.id === sourceId),
        dropClusters: data.dropClusters.filter(dc => dropClusterIds.includes(dc.id)),
      };
    }
    if (selection[0] === 'incident') {
      const incidentId = selection[1];
      const selectedDropGroups = data.dropGroups.filter(dg => dg.clusterId === incidentId);
      const fillClusterIds = uniq(selectedDropGroups.flatMap(dg => dg.fills.map(f => f.clusterId)));
      return {
        dropGroups: selectedDropGroups,
        fillClusters: data.fillClusters.filter(fc => fillClusterIds.includes(fc.id)),
        dropClusters: data.dropClusters.filter(dc => dc.id === incidentId),
      };
    }
    return data;
  }, [data, selection]);

  useEffect(() => {
    if (selection?.at(0) !== grouping || !selection) {
      return;
    }
    if (selection[0] === 'asset') {
      setGrouping('source');
    }
    if (selection[0] === 'source') {
      setGrouping('incident');
    }
    if (selection[0] === 'incident') {
      setGrouping('asset');
    }
  }, [grouping, selection, setGrouping]);

  useEffect(() => {
    const hovered = dataForMap.dropGroups.find(d => d.id === hoveredDropGroup);
    setDropFilter(hovered ? { assetId: hovered.assetId } : {});
  }, [hoveredDropGroup, dataForMap, setDropFilter]);

  const filteredDrops = useMemo(
    () => dataForMap.dropGroups.filter(dg => Object.entries(dropFilter).every(([k, v]) => dg[k] === v)),
    [dropFilter, dataForMap]
  );

  // const downloadCSVData = async () => {
  //   let records: Input;
  //   let options: Options;
  //   if (selectedAsset) {
  //     [records, options] = await createCSVAssetData(dropGroups, timezone, visibleSuppressants);
  //   } else {
  //     [records, options] = createCSVSummaryData(assets, selectedDeviceIds, dropGroups, trips, visibleSuppressants);
  //   }
  //   const csvString = csvStringify(records, options);

  //   const dateFormat = 'yyyy.MM.dd';
  //   const dayFrom = DateTime.fromMillis(query.from).setZone(timezone).toFormat(dateFormat);
  //   const dayUntil = DateTime.fromMillis(query.until).setZone(timezone).toFormat(dateFormat);

  //   let exportedFilename: string;
  //   if (selectedAsset) {
  //     exportedFilename = `AerialFirefighting-${selectedAsset.name}-${dayFrom}-${dayUntil}`.trim();
  //   } else {
  //     exportedFilename = `AerialFirefightingSummary-${dayFrom}-${dayUntil}`.trim();
  //   }

  //   downloadCSV(exportedFilename, csvString);
  // };

  const leftOverlayRef = useRef<HTMLDivElement>(null);
  const { width: leftOverlayWidth } = useSize(leftOverlayRef);

  const assetLabel = useAssetLabel();

  const selectedItemName = useMemo(() => {
    if (!selection) {
      return undefined;
    }

    if (selection[0] === 'asset') {
      return assetLabel(assets.find(a => a.id === selection[1]));
    }
    if (selection[0] === 'source') {
      const fillCluster = data.fillClusters.find(fc => fc.id === selection[1]);
      return fillCluster?.bodyOfWater ?? fillCluster?.h3Index;
    }
    if (selection[0] === 'incident') {
      const dropCluster = data.dropClusters.find(dc => dc.id === selection[1]);
      return dropCluster?.placeName ?? dropCluster?.h3Index;
    }
    return undefined;
  }, [assetLabel, data, selection, assets]);

  if (devicesByIdQuery.isLoading) {
    return (<LoadingPage />);
  }

  return (
    <Page title={t('summaryTitle')}>
      <FirefightingMap
        data={dataForMap}
        leftPadding={leftOverlayWidth + 24}
        hoveredDropGroup={hoveredDropGroup}
        setHoveredDropGroup={setHoveredDropGroup}
        setHoveredDropCluster={setHoveredDropCluster}
        setHoveredFillCluster={setHoveredFillCluster}
        setSelection={setSelection}
      />
      <Stack m={8} direction="row" sx={{ pointerEvents: 'none' }} justifyContent="space-between" zIndex={1} width="100%">
        <Box sx={{ backgroundColor: 'transparent' }} ref={leftOverlayRef}>
          <Box sx={{ pointerEvents: 'auto' }}>
            <LinkAbove />
          </Box>
          <Stack maxHeight="100%" pb={8} overflow="hidden" spacing={3}>
            <Paper sx={{ width: '30em', pointerEvents: 'auto' }} elevation={0}>
              <Stack p={3} spacing={3}>
                <Typography variant="h1" align="left">{t('title')}</Typography>
                <Routes>
                  <Route index element={(
                    <FormControl variant="outlined" sx={{ flex: 1 }}>
                      <SelectAssets
                        label={t('selectAssets.label')}
                        deviceIds={deviceIds}
                        selectedDeviceIds={selectedDeviceIds}
                        assetsByDeviceId={assetsByDeviceId}
                        devicesById={devicesById}
                        setSelectedDeviceIds={value => {
                          setSelectedDeviceIds(value);
                        }}
                        disabled={false}
                        assetGroups={assetGroups}
                        isLoading={isLoading}
                      />
                    </FormControl>
                  )} />
                  <Route path="asset/:assetId" element={selectedAsset ? (
                    <Box flex={1} alignSelf="center">
                      <Stack direction="row" alignItems="center" spacing={1} mb={0.5}>
                        <AssetColourMarker assetId={selectedAsset.id} />
                        <Typography fontWeight="bold" fontSize="1.5rem" lineHeight="1.5rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"><AssetLabel asset={selectedAsset} /></Typography>
                      </Stack>
                      <Typography fontSize="1rem">{selectedAsset.make} {selectedAsset.model} {selectedAsset.variant}</Typography>
                    </Box>
                  ) : <Box flex={1} />} />
                </Routes>
                <FormControl>
                  <DateRangeSelection
                    assets={selectedAsset ? [selectedAsset] : []}
                    from={datePickerRange.from}
                    until={datePickerRange.until}
                    onChange={setDateRange}
                  />
                </FormControl>
              </Stack>
            </Paper>
            <Paper sx={{ pointerEvents: 'auto' }}>
              <Stack p={3} spacing={1}>
                {selection && (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">{selection[0]} {selectedItemName}</Typography>
                    <IconButton onClick={() => setSelection(undefined)}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                )}
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="h2">Group By</Typography>
                  <ToggleButtonGroup value={grouping} onChange={e => setGrouping(e.target.value)}>
                    <ToggleButton value="asset" disabled={selection?.at(0) === 'asset'}>ASSET</ToggleButton>
                    <ToggleButton value="source" disabled={selection?.at(0) === 'source'}>SOURCE</ToggleButton>
                    <ToggleButton value="incident" disabled={selection?.at(0) === 'incident'}>INCIDENT</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Stack>
            </Paper>
            <GroupedDropsScorecard
              sx={{ width: '30em', pointerEvents: 'auto' }}
              assetIds={assetIds}
              data={dataForMap}
              grouping={grouping}
              trips={trips}
              visibleSuppressants={visibleSuppressants}
              setDropFilter={setDropFilter}
              setSelection={setSelection}
            />
          </Stack>
        </Box>
        <Stack spacing={3}>
          <HoveredInfoOverlay
            sx={{ width: '20em', pointerEvents: 'auto' }}
            dropGroups={filteredDrops}
            trips={trips}
          />
          <HoveredDropGroupOverlay
            sx={{ width: '20em', pointerEvents: 'auto' }}
            assetIds={assetIds}
            dropGroups={dataForMap.dropGroups}
            hoveredDrop={hoveredDropGroup}
            trips={trips}
            visibleSuppressants={visibleSuppressants}
          />
          <HoveredDropClusterOverlay
            sx={{ width: '20em', pointerEvents: 'auto' }}
            data={data}
            hoveredDropClusterId={hoveredDropCluster}
          />
          <HoveredFillClusterOverlay
            sx={{ width: '20em', pointerEvents: 'auto' }}
            data={data}
            hoveredFillClusterId={hoveredFillCluster}
          />
        </Stack>
      </Stack>
    </Page>
  );
};

export default FirefightingLayout;
