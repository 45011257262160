import {Base, BaseAssetAssignment, BaseRequest, BaseResponse} from "apis/rest/bases/bases-types";
import {HttpResponseError, serenityFetch} from "helpers/api";

export const getBases = async (organisationId: string): Promise<Base[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/bases`, null);
  HttpResponseError.detect(response);
  const baseResponses = (await response.json()).bases as BaseResponse[];
  return baseResponses.map<Base>(base => ({
    ...base,
    notes: base.notes.map(n => n.note),
    assetIds: base.assignments.map(a => a.assetId),
  }))
}

export const createBase = async (organisationId: string, base: BaseRequest): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/bases/${base.id}`, base);
  HttpResponseError.detect(response);
};

export const updateBase = async (organisationId: string, base: BaseRequest): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/bases/${base.id}`, base);
  HttpResponseError.detect(response);
};

export const deleteBase = async (organisationId: string, baseId: string): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/bases/${baseId}`, null);
  HttpResponseError.detect(response);
};

export const updateBaseAssignment = async (organisationId: string, baseId: string, baseAssignment: BaseAssetAssignment[]): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/bases/${baseId}/assignments`, baseAssignment);
  HttpResponseError.detect(response);
};