import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, type TextFieldProps } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router';

interface SearchQueryTextFieldProps {
  label: string;
  textFieldProps?: TextFieldProps;
}

const SearchQueryTextField = ({ label, textFieldProps }: SearchQueryTextFieldProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (value: string) => {
    setSearchParams(
      params => {
        params.set('filter', value);
        return params;
      },
      { replace: true },
    );
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      value={searchParams.get('filter') ?? ''}
      onChange={event => onChange(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" disableTypography>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...(textFieldProps ?? {})}
    />
  );
};

export default SearchQueryTextField;
