import React, { useMemo, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Collapse,
  Alert,
  TextField,
} from '@mui/material';
import { ArrowDownward, ArrowForward, ArrowUpward, Clear, Close, Edit } from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import { alpha, useTheme } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from '@hello-pangea/dnd';
import usePeopleWithExtractedContacts, { PersonWithExtractedContacts } from 'hooks/people/usePeopleWithExtractedContacts';
import { useGetPeople } from 'apis/rest/people/hooks';
import usePeopleById from 'hooks/people/usePeopleById';
import ScrollList from 'components/shared/scrollList';

export interface PeopleTableComponents {
  Person: (props: { person: Person, order?: number }) => JSX.Element
}

interface PeopleTableProps {
  selectedIds: number[]
  setSelectedIds: Dispatch<SetStateAction<number[]>>
  disabled: boolean
  addContacts: (person: Person) => void
  components: PeopleTableComponents
}

const PeopleTable = ({ selectedIds, setSelectedIds, disabled, addContacts, components: { Person } }: PeopleTableProps): JSX.Element => {
  const theme = useTheme();
  const t = useTranslations('dialogs.contactGroups.assignPeople');
  const { query: peopleQuery } = useGetPeople();
  const peopleWithExtractedContacts = usePeopleWithExtractedContacts(peopleQuery.data);
  const peopleById = usePeopleById<PersonWithExtractedContacts>(peopleWithExtractedContacts);

  const [selectedPeople, unselectedPeople] = useMemo(
    () => [
      selectedIds.map(id => peopleById?.[id]).filter((p): p is PersonWithExtractedContacts => !!p),
      Object.values(peopleById ?? {}).filter(person => !selectedIds.includes(person.id)),
    ],
    [peopleById, selectedIds],
  );

  const movePerson = useCallback((fromIndex: number, toIndex: number) => {
    setSelectedIds(value => {
      const next = [...value];
      const item = next.splice(fromIndex, 1)[0];
      next.splice(toIndex, 0, item);
      return next;
    });
  }, [setSelectedIds]);

  const onDragEnd: OnDragEndResponder = useCallback(result => {
    if (!result.source || !result.destination) return;
    movePerson(result.source.index, result.destination.index);
  }, [movePerson]);

  const removePerson = useCallback((id: number) => {
    setSelectedIds(value => value.filter(x => x !== id));
  }, [setSelectedIds]);

  const addPerson = useCallback((id: number) => {
    setSelectedIds(value => [...value, id]);
  }, [setSelectedIds]);

  const [filterText, setFilterText] = useState<string>('');

  const filteredPeople = useMemo(() => {
    const value = filterText.trim().toLowerCase();
    const filtered = value ? unselectedPeople.filter(person => person.name.toLowerCase().includes(value)) : unselectedPeople;

    return [...filtered].sort((a, b) => a.name.localeCompare(b.name));
  }, [unselectedPeople, filterText]);

  if (peopleQuery.isLoading) {
    return (
      <Box p={3}>
        <Typography>{t('peopleLoading')}</Typography>
      </Box>
    );
  }

  return (
    <Box mx={3} mt={3}>
      <Grid direction="row" container spacing={3}>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} height="3rem">
            <Typography variant="h5">{t('otherPeople')} ({filteredPeople.length}):</Typography>
            <TextField
              type="search"
              size="small"
              value={filterText}
              onChange={event => setFilterText(event.target.value)}
              InputProps={{
                placeholder: t('searchPeoplePlaceholder'),
                endAdornment: filterText && (
                  <IconButton size="small" onClick={() => setFilterText('')}>
                    <Clear sx={{ color: theme.palette.common.text }} />
                  </IconButton>
                ),
                sx: { pr: 1, width: '15rem' },
                'aria-label': t('searchPeople'),
              }}
            />
          </Stack>
          <Stack
            flex={1}
            borderTop={1}
            borderColor="common.midGrey"
          >
            <ScrollList
              height="30rem"
              ids={filteredPeople.map(p => p.id)}
              renderItem={(id, index) => (
                <Stack
                  direction="row"
                  borderTop={1}
                  borderBottom={1}
                  mt="-1px"
                  borderColor="common.midGrey"
                  p={2}
                  alignItems="center"
                  role="button"
                  sx={{ cursor: 'pointer', ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
                  onClick={() => addPerson(id)}
                >
                  <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
                    <Person person={filteredPeople[index]} />
                  </Stack>
                  <Tooltip title={t('tooltips.editPersonContacts')}>
                    <IconButton
                      onClick={event => {
                        addContacts(filteredPeople[index]);
                        event.stopPropagation();
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('tooltips.addPerson')}>
                    <IconButton>
                      <ArrowForward />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              empty={(
                <Collapse>
                  <Box mt={2}>
                    {unselectedPeople.length ? <Alert severity="info">{t('noFilteredPeople')}</Alert> : <Alert severity="warning">{t('noUnselectedPeople')}</Alert>}
                  </Box>
                </Collapse>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {/*
            NOTE: The selected people list isn't virtualized because making that work with drag and drop re-ordering
            requires more effort than it's worth for the unlikely case that a contact group has a large number of people
          */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} height="3rem">
            <Typography variant="h5">{t('selectedPeople')} ({selectedPeople.length}):</Typography>
            <Typography fontStyle="italic">{t('dragToReorder')}</Typography>
          </Stack>
          <Box
            flex={1}
            minHeight="30rem"
            maxHeight="30rem"
            borderTop={1}
            borderColor="common.midGrey"
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
              '::-webkit-scrollbar-thumb': {
                bgcolor: 'common.scrollBar',
              },
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="selectedPeople">
                {({ droppableProps, innerRef: droppableRef, placeholder }) => (
                  <Stack {...droppableProps} ref={droppableRef}>
                    <TransitionGroup>
                      {selectedPeople.map((person, index) => (
                        <Collapse key={person.id}>
                          <Draggable draggableId={person.id.toString()} index={index}>
                            {({ draggableProps, innerRef: draggableRef, dragHandleProps }, { isDragging }) => (
                              <Stack
                                ref={draggableRef}
                                {...draggableProps}
                                {...dragHandleProps}
                                style={draggableProps.style}
                                direction="row"
                                border={isDragging ? 1 : 0}
                                borderTop={1}
                                borderBottom={1}
                                mt="-1px"
                                borderColor="common.midGrey"
                                bgcolor="common.white"
                                p={2}
                                alignItems="center"
                                boxShadow={isDragging ? 2 : 0}
                                sx={{ ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
                              >
                                <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
                                  <Stack direction="row" spacing={1} alignItems="center" minWidth={0}>
                                    <Person person={person} order={index + 1} />
                                  </Stack>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                  <Tooltip title={t('tooltips.editPersonContacts')}>
                                    <IconButton
                                      onClick={event => {
                                        addContacts(person);
                                        event.stopPropagation();
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('tooltips.movePersonDown')}>
                                    <IconButton disabled={isDragging || index === selectedPeople.length - 1} onClick={() => movePerson(index, index + 1)}>
                                      <ArrowDownward />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('tooltips.movePersonUp')}>
                                    <IconButton disabled={isDragging || index === 0} onClick={() => movePerson(index, index - 1)}>
                                      <ArrowUpward />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('tooltips.removePerson')}>
                                    <IconButton disabled={isDragging} onClick={() => removePerson(person.id)}>
                                      <Close />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Stack>
                            )}
                          </Draggable>
                        </Collapse>
                      ))}
                      <Collapse>
                        {placeholder}
                      </Collapse>
                      {selectedPeople.length < 2 && (
                        <Collapse>
                          <Box mt={2}>
                            <Alert severity="warning">{t('minimumPeople')}</Alert>
                          </Box>
                        </Collapse>
                      )}
                    </TransitionGroup>
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PeopleTable;
