import { useQuery } from '@tanstack/react-query';
import { getRefreshedToken } from 'apis/auth';
import { HttpResponseError, fetchOptions, serenityFetch } from 'helpers/api';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

export interface DeviceSearchResult {
  id: number;
  assetId: string;
  assetName: string;
  assetModel: string;
  assetMake: string;
  assetVariant: string;
  assetCategory: string;
  assetOwner: string;
  assetIconId: string;
  deviceMake: string;
  deviceOwner: string;
  deviceModel: string;
  lastActive: string;
  tpSerial: string;
  imei: string;
  manufacturerSerial: string;
  status: string;
  errorCode: string;
  incidentHandling: string;
  config?: Rock7Configuration;
}

export const useGetDeviceSearch = (text: string) =>
  useQuery<DeviceSearchResult[]>({
    queryKey: ['deviceSearchResults', text],
    queryFn: async () => {
      const token = await getRefreshedToken();
      if (!token) throw new Error('Credentials are invalid');

      const headers = {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-TPC-Version': import.meta.env.PACKAGE_VERSION,
      };
      if (!text || text.length < 3) return undefined;
      const response = await fetch(`${BASE_URL}/devices/search?query=${text}`, {
        method: 'GET',
        headers,
      });
      if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
      return response.json();
    },
    staleTime: 30000,
  });

export const updateGPRSEndpoint = async (orgId: string, deviceId: number): Promise<void> => {
  const token = await getRefreshedToken();
  if (!token) throw new Error('Credentials are invalid');

  const headers = {
    authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-TPC-Version': import.meta.env.PACKAGE_VERSION,
  };

  const response = await fetch(`${BASE_URL}/organisations/${orgId}/devices/ota-gprs-endpoint-update`, {
    ...fetchOptions,
    method: 'POST',
    headers,
    body: JSON.stringify({ deviceId }),
  } as RequestInit);

  if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
};

export const requestGprsEndpoints = async (orgId: string, deviceId: number): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${orgId}/devices/${deviceId}/gprs-endpoints/request`,
    {},
  );
  HttpResponseError.detect(response);
};

export const setOrganisationEnabled = async (orgId: string, enabled: boolean): Promise<void> => {
  const response = await serenityFetch('PUT', `/staff/organisations/${orgId}`, {
    enabled,
  });
  HttpResponseError.detect(response);
};

export const setIncidentHandlingMethod = async (orgId: string, deviceId: number, status: string) => {
  const response = await serenityFetch(
    'PUT',
    `/organisations/${orgId}/devices/${deviceId}/incident-handling?method=${status}`,
    null,
  );
  HttpResponseError.detect(response);
};

export const setStatus = async (orgId: string, deviceId: number, status: string) => {
  const response = await serenityFetch('PUT', `/organisations/${orgId}/devices/${deviceId}/status?to=${status}`, null);
  HttpResponseError.detect(response);
};
