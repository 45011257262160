import { Paper, Stack } from '@mui/material';
import type { BasesOutletContextValue } from 'components/pages/manage/bases/bases-view';
import ManageListMapView from 'components/shared/manageMapList/manageListMap';
import type { ManageListMapObject } from 'components/shared/manageMapList/manageListMap/manage-list-map-view';
import SearchQueryTextField from 'components/shared/searchQueryTextField';
import type React from 'react';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router';
import { useSearchParams } from 'react-router';
import { useAppDispatch } from 'store/useAppDispatch';

const BasesList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useOutletContext<BasesOutletContextValue>();

  const bases = context.bases ?? [];
  const filterText = searchParams.get('filter');

  const listObjects: ManageListMapObject[] = useMemo(
    () =>
      bases
        .filter(b => [b.name.toLowerCase()].some(s => (filterText ? s.includes(filterText.toLowerCase()) : true)))
        .map(b => ({
          id: b.id,
          name: b.name,
          secondaryText: b.description,
          colour: b.colour,
        })),
    [bases, filterText],
  );

  return (
    <Stack spacing={3} maxHeight="100%">
      <Paper
        elevation={0}
        sx={{
          maxHeight: '100%',
          overflow: 'hidden',
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
        }}
      >
        <SearchQueryTextField
          label={'Search Bases'}
          textFieldProps={{
            sx: {
              m: 3,
            },
          }}
        />
        <ManageListMapView objs={listObjects} onRowHover={(id: string) => {}} hoveredIds={[]} />
      </Paper>
    </Stack>
  );
};

export default BasesList;
