import { useGetBases } from 'apis/rest/bases/bases-hooks';
import type { Base } from 'apis/rest/bases/bases-types';
import BasesMap from 'components/pages/manage/bases/bases-map';
import { BasesActions } from 'components/pages/manage/bases/components/bases-actions';
import GeofencesLayoutView from 'components/pages/manage/geofences/Geofences.layout.view';
import Page from 'components/pages/page';
import React, { useMemo } from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router';
import { useTranslations } from 'use-intl';

export interface BasesOutletContextValue {
  bases: Base[];
  selectedBase?: Base;
}

export const BasesView = () => {
  const t = useTranslations('pages.manage.bases');
  const { baseId } = useParams();

  const bases = useGetBases().data ?? [];

  const selectedBase = useMemo(() => bases.find(b => b.id === baseId), [bases, baseId]);

  const outletContextValue: BasesOutletContextValue = useMemo(
    () => ({
      bases: bases,
      selectedBase: selectedBase,
    }),
    [bases, selectedBase],
  );

  return (
    <Page title={t('title')}>
      <GeofencesLayoutView
        pageHeading={
          <Routes>
            <Route index element={t('title')} />
            <Route path="create" element={t('createTitle')} />
            <Route path=":id" element={t('editTitle', { name: selectedBase?.name ?? '' })} />
          </Routes>
        }
        pageActions={
          <Routes>
            <Route index element={<BasesActions />} />
          </Routes>
        }
        content={<Outlet context={outletContextValue} />}
        map={<BasesMap bases={selectedBase ? [selectedBase] : bases} />}
        description={t('description')}
        limitHeight={true}
      />
    </Page>
  );
};
