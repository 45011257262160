import React from 'react';
import { useGetPeople } from 'apis/rest/people/hooks';
import Page from 'components/pages/page';
import { Box, Container, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { Navigate, useParams } from 'react-router';
import LoadingPage from 'components/pages/loading';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import MissingPage from 'components/shared/missingPage';
import useLastDefined from 'hooks/useLastDefined';
import usePermissions from 'hooks/session/usePermissions';
import { BasicDetails } from './basicDetails';
import { PhoneContacts } from './phoneContacts';
import { EmailContacts } from './emailContacts';
import { DeleteContactProvider } from './deleteContactContext';
import Groups from './groups-view';

const EditPersonPage = (): JSX.Element => {
  const permissions = usePermissions();
  const { query: peopleQuery } = useGetPeople();
  const params = useParams<{id?: string}>();
  const personId = parseInt(params.id ?? '', 10);

  const foundPerson = peopleQuery.data?.find(p => p.id === personId);
  const person = useLastDefined(foundPerson, []);

  const t = useTranslation('pages.manage.person');

  if (!permissions.canEditPeople) return <Navigate to="/manage/people" replace />;

  if (!person) {
    if (peopleQuery.isLoading || peopleQuery.isRefetching) return <LoadingPage />;
    return (
      <MissingPage
        to="/manage/people"
        title={t('missing.title')}
        description={t('missing.description')}
        linkText={t('missing.link')}
      />
    );
  }

  return (
    <DeleteContactProvider>
      <Page title={person.name}>
        <SettingsMenuPageWrapper>
          <Container maxWidth="md">
            <Box>
              <LinkAbove />
              <Typography variant="h1" gutterBottom>{`${t('editPerson')}: ${person?.name || ''}`}</Typography>
            </Box>
            <BasicDetails person={person} />
            <PhoneContacts person={person} contactType="phone" />
            <PhoneContacts person={person} contactType="sms" />
            <EmailContacts person={person} />
            <Groups person={person} />
          </Container>
        </SettingsMenuPageWrapper>
      </Page>
    </DeleteContactProvider>
  );
};

export default EditPersonPage;
