import { FeatureFlag } from 'components/shared/featureFlag';
import type { FC } from 'react';
import AnalysisBoxContainer from './analysis-box-container.view';
import OldAnalysisboxContainer from './old/analysisboxContainer-view';

interface AnalysisboxAdapterProps {
  selectedAsset: AssetBasic | null;
}

const AnalysisboxAdapter: FC<AnalysisboxAdapterProps> = ({ selectedAsset }) => (
  <FeatureFlag
    feature="frontendRevisedAnalysisBox"
    enabled={<AnalysisBoxContainer selectedAsset={selectedAsset} />}
    disabled={<OldAnalysisboxContainer selectedAsset={selectedAsset} />}
  />
);

export default AnalysisboxAdapter;
