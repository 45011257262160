import React, { useState } from 'react';
import { Button, ButtonGroup, Menu, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router';
import { ArrowDropDown } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import usePermissions from 'hooks/session/usePermissions';

const GeofencesListActions: React.FC = () => {
  const t = useTranslations('pages.manage.geofencing');
  const permissions = usePermissions();

  const [geofenceEditAnchorEl, setGeofenceEditAnchorEl] = useState<null | HTMLElement>(null);

  if (!permissions.canEditGeofences) return null;

  const createGeofenceDropdownOpen = Boolean(geofenceEditAnchorEl);

  const handleClickCreateDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setGeofenceEditAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setGeofenceEditAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup color="primary" variant="contained" disableElevation size="large">
        <Button
          component={Link}
          to="create"
        >
          {t('list.createGeofence')}
        </Button>
        <Button
          id="createGeofenceButton"
          size="small"
          aria-controls={createGeofenceDropdownOpen ? 'split-button-menu' : undefined}
          aria-expanded={createGeofenceDropdownOpen ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleClickCreateDropdown}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={geofenceEditAnchorEl}
        open={createGeofenceDropdownOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'createGeofenceButton',
          id: 'split-button-menu',
        }}
        // elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      >
        <MenuItem component={Link} to="create?import=true" sx={{ minWidth: '15rem' }}>
          <Typography>{t('list.createGeofenceFromFile')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default GeofencesListActions;
