import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
} from '@mui/material';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { TPButton } from 'components/shared/button/TPButton';
import {
  ALL_SEARCH_PATTERNS,
  type SearchPatternType,
  defaultSearchPattern,
  isSearchPattern,
} from 'helpers/searchPatterns';
import type React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSearchPattern,
  closeCreateSearchPatternDialog,
  selectCreateSearchPatternDialogOpen,
} from 'slices/searchPatterns.slice';
import { useTranslations } from 'use-intl';

export const CreateSearchPatternDialog: React.FC = () => {
  const t = useTranslations('dialogs.searchPatterns');
  const dispatch = useDispatch();
  const [type, setType] = useState<SearchPatternType | ''>('');
  const open = useSelector(selectCreateSearchPatternDialogOpen);

  const onClose = () => dispatch(closeCreateSearchPatternDialog());
  const onChange = (evt: SelectChangeEvent) => {
    if (isSearchPattern(evt.target.value)) {
      setType(evt.target.value);
    }
  };
  const onSubmit = () => {
    if (type === '') return;
    const sp = defaultSearchPattern(type, t('defaultName', { type }));
    dispatch(addSearchPattern(sp));
    onClose();
  };

  const onExit = () => setType('');

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" TransitionProps={{ onExit }}>
      <TPDialogTitle>{t('create.title')}</TPDialogTitle>
      <DialogContent>
        <Stack pt={3}>
          <FormControl fullWidth>
            <InputLabel id="search-patterns-select-type-label">{t('type')}</InputLabel>
            <Select
              labelId="search-patterns-select-type-label"
              label={t('type')}
              value={type}
              onChange={onChange}
              variant="outlined"
            >
              {ALL_SEARCH_PATTERNS.map(p => (
                <MenuItem key={p} value={p}>
                  {t(p)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <TPDialogActions>
        <TPButton onClick={onClose} variant="outlined">
          {t('cancel')}
        </TPButton>
        <TPButton onClick={onSubmit} disabled={!isSearchPattern(type)}>
          {t('add')}
        </TPButton>
      </TPDialogActions>
    </Dialog>
  );
};
