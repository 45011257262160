import React, { useState } from 'react';
import {
  Alert,
  Button,
  Stack,
  Tooltip,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslations } from 'use-intl';
import DetailPanel from 'components/shared/DetailPanel';
import usePermissions from 'hooks/session/usePermissions';
import useValidateName from 'hooks/contactGroup/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import {
  useGetIceContactGroups,
  useGetIceContactGroupsHealth,
  useMutateDeleteIceContactGroup, useMutateNewIceContactGroup,
} from 'apis/rest/iceContactGroups/hooks';
import {
  ContactGroupsTable,
  ContactGroupsTableComponents,
  defaultComponents,
} from 'components/pages/manage/contactGroups/common/contactGroupsTable';
import { DeleteIceContactGroupDialog } from 'components/dialogs/iceContactGroups/deleteGroup/deleteGroup-dialog';
import AddGroupDialog from 'components/dialogs/contactGroups/addGroup/addGroup-dialog';
import { AssignPeopleIceDialog } from 'components/dialogs/iceContactGroups/assignPeople';

const components: ContactGroupsTableComponents = {
  DeleteDialog: DeleteIceContactGroupDialog,
  NoGroups: () => {
    const t = useTranslations('pages.manage.ice.all');
    return <Alert severity="warning">{t('noGroupsAlert')}</Alert>;
  },
  DefaultGroup: props => {
    const t = useTranslations('pages.manage.ice.all');
    const iceHealth = useGetIceContactGroupsHealth().query.data;

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {iceHealth?.defaultIceGroupIsHealthy === false && (
          <Tooltip title={t('defaultUnhealthy')}>
            <ErrorOutline color="error" fontSize="large" />
          </Tooltip>
        )}
        <defaultComponents.DefaultGroup {...props} />
      </Stack>
    );
  },
};

const Create = ({ created }: { created: (group: ContactGroup) => void }) => {
  const t = useTranslations('pages.manage.ice');
  const { query: groupsQuery } = useGetIceContactGroups();
  const createMutation = useMutateNewIceContactGroup();
  const validateName = useValidateName(groupsQuery);
  const snackbar = useSnackbar();
  const [addGroup, setAddGroup] = useState(false);
  const [createdGroup, setCreatedGroup] = useState<ContactGroup>();

  const done = (group: ContactGroup) => {
    snackbar.display({
      id: `iceContactGroupCreated.${group.id}`,
      text: t('create.snackbar.success', { name: group.name }),
      type: 'success',
    });
    setCreatedGroup(undefined);
    created(group);
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mx={3} height="4em">
        <Button onClick={() => setAddGroup(true)} size="large" variant="contained">{t('all.createGroup')}</Button>
      </Stack>
      <AddGroupDialog
        title={t('create.title')}
        ariaLabel={t('create.title')}
        open={addGroup}
        mutation={createMutation}
        validateName={name => validateName(createMutation.data?.id, name)}
        created={group => {
          setAddGroup(false);
          setCreatedGroup(group);
        }}
        cancel={() => setAddGroup(false)}
      />
      <AssignPeopleIceDialog
        groupId={createdGroup?.id}
        open={!!createdGroup}
        onClose={() => {
          if (createdGroup) done(createdGroup);
        }}
        title={t('edit.people.dialog.title', { group: createdGroup?.name })}
        ariaLabel={t('edit.people.dialog.title', { group: createdGroup?.name })}
        skip
      />
    </>
  );
};

const IceAllTab = (): JSX.Element => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { query: groupsQuery } = useGetIceContactGroups();
  const deleteMutation = useMutateDeleteIceContactGroup();
  const [createdGroupId, setCreatedGroupId] = useState<number>();

  return (
    <DetailPanel pb={0} mb={8}>
      {permissions.canEditContactGroups && <Create created={group => setCreatedGroupId(group.id)} />}

      <ContactGroupsTable
        settingsCategory="iceTable"
        data={groupsQuery.data}
        isLoading={groupsQuery.isLoading}
        components={components}
        navigateToRow={row => navigate(`/manage/ice/groups/${row.group.id}`)}
        deleteMutation={deleteMutation}
        createdGroupId={createdGroupId}
      />
    </DetailPanel>
  );
};

export default IceAllTab;
