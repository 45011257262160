import type { LayersList } from '@deck.gl/core';
import { MapboxOverlay, type MapboxOverlayProps } from '@deck.gl/mapbox';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import { Box } from '@mui/material';
import type { Base } from 'apis/rest/bases/bases-types';
import { type IconPoint, useIconLayers } from 'components/maps/reactmapgl/layers/useIconLayer';
import { getMapBoundsForCoords } from 'helpers/mapBounds';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { useSize } from 'hooks/useSize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGl, { type MapRef, useControl, type ViewStateChangeEvent } from 'react-map-gl';

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export interface BasesMapProps {
  bases: Base[];
}

const BasesMap = ({ bases }: BasesMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);
  const mapTemplate = useMapTemplate();

  const { width, height } = useSize(ref);

  const [viewport, setViewport] = useState<WebMercatorViewport>(
    () =>
      new WebMercatorViewport({
        width,
        height,
      }),
  );

  const onViewStateChange = useCallback(
    (params: ViewStateChangeEvent) => {
      setViewport(
        new WebMercatorViewport({
          width,
          height,
          ...params.viewState,
        }),
      );
    },
    [width, height],
  );

  const wmViewport = useMemo(() => new WebMercatorViewport(viewport), [viewport]);

  const iconPoints = useMemo(
    () =>
      bases.map<IconPoint>(b => ({
        id: b.id,
        name: b.name,
        latitude: b.latitude ?? 0,
        longitude: b.longitude ?? 0,
        colour: b.colour,
        icon: b.icon,
        altitude: 0,
      })),
    [bases],
  );

  const iconLayers = useIconLayers(iconPoints, wmViewport, 'bases-icon-layer', true, false);

  useEffect(() => {
    const mapBounds = getMapBoundsForCoords(
      bases.map(b => ({ latitude: b.latitude ?? 0, longitude: b.longitude ?? 0 })),
    );

    mapRef.current?.getMap().fitBounds(mapBounds, {
      padding: Math.min(width, height, 150),
      maxZoom: 13,
    });
  }, [bases, width, height]);

  return (
    <Box
      ref={ref}
      sx={theme => ({
        width: '100%',
        height,
        minHeight: 800,
        borderLeft: theme.border.default,
        position: 'sticky',
      })}
    >
      <ReactMapGl
        ref={mapRef}
        mapStyle={mapTemplate.template}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
        initialViewState={viewport}
        onMove={onViewStateChange}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        projection={{ name: 'mercator' }}
        doubleClickZoom={false}
        attributionControl
      >
        <DeckGLOverlay
          _animate
          style={{
            position: 'relative',
            zIndex: '0',
          }}
          pickingRadius={32}
          layers={[...iconLayers] as LayersList}
        />
      </ReactMapGl>
    </Box>
  );
};

export default BasesMap;
