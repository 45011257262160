import { Box } from '@mui/material';
import { type Selection, axisTop, scaleTime, select } from 'd3';
import useTimezone from 'hooks/session/useTimezone';
import { useSize } from 'hooks/useSize';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { MARGIN_TOP } from './AssetTimelineGraph';

class TimelineGraphAxis {
  private scaleX = scaleTime();
  private root: Selection<SVGSVGElement, unknown, null, undefined>;
  public timezone = 'Etc/UTC';

  constructor(svg: SVGSVGElement) {
    this.root = select(svg);
  }

  draw(startTime: number, width: number) {
    this.root.attr('viewBox', [0, 0, width, MARGIN_TOP]);

    this.scaleX.domain([new Date(startTime), new Date(startTime + 86400 * 1000)]).range([0, width]);

    this.updateAxis();
  }

  updateAxis() {
    this.root.select('.grids').selectAll('.x-axis').remove();
    this.root.select('.grids').selectAll('.x-ticks').remove();

    const formatXAxisTick = (date: Date): string => {
      const dt = DateTime.fromJSDate(date).setZone(this.timezone);
      if (dt.get('second') !== 0) return '';
      if (dt.get('hour') !== 0) return dt.toFormat('HH:mm');
      return dt.toFormat('MMM d');
    };

    this.root
      .select('.grids')
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${MARGIN_TOP})`)
      .call(axisTop<Date>(this.scaleX).tickFormat(formatXAxisTick));
  }
}

export const AssetTimelineGraphAxis = ({ startTimeIso, width }: { startTimeIso: string | null; width: number }) => {
  const d3container = useRef<HTMLDivElement>(null);
  const d3root = useRef<SVGSVGElement>(null);
  const chart = useRef<TimelineGraphAxis>();
  const size = useSize(d3container);
  const timezone = useTimezone();

  useEffect(() => {
    if (!d3root.current) return;
    chart.current = new TimelineGraphAxis(d3root.current);
  }, []);

  useEffect(() => {
    if (!chart.current) {
      chart.current = undefined;
      return;
    }

    chart.current.timezone = timezone;
    chart.current.draw(
      (startTimeIso === null
        ? DateTime.now().setZone(timezone).startOf('day')
        : DateTime.fromISO(startTimeIso, { zone: timezone })
      ).toMillis(),
      size.width,
    );
  }, [size, startTimeIso, timezone]);

  return (
    <Box
      sx={{
        height: MARGIN_TOP,
        width,
        overflow: 'visible',
      }}
      ref={d3container}
    >
      <svg style={{ height: MARGIN_TOP, width: '100%' }} ref={d3root} overflow="visible">
        <g className="grids" />
      </svg>
    </Box>
  );
};
