import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';
import { useLatestPosition } from 'repositories/reports/hooks';

const INACTIVE_MILLISECONDS = 30 * 60 * 1000;

interface BeaconUserProps {
  user: Membership;
  asset: AssetBasic | undefined;
  actions: React.ReactNode;
  onClick: (user: Membership) => void;
  isLast: boolean;
}

export const BeaconUser = ({
  user,
  asset,
  actions,
  onClick,
  isLast
}: BeaconUserProps) => {
  const theme = useTheme();
  const t = useTranslations('pages.beacon');
  const navigate = useNavigate();

  const latestPosition = useLatestPosition(asset?.id);

  const lastActive = useMemo(() => (latestPosition ? DateTime.fromSeconds(latestPosition.received ?? 0, { zone: 'utc' }) : undefined), [latestPosition]);
  const isActive = useMemo(() => (lastActive ? DateTime.now().diff(lastActive).toMillis() < INACTIVE_MILLISECONDS : false), [lastActive]);

  return (
    <Stack
      direction="row"
      borderTop={1}
      borderBottom={isLast ? 1 : 0}
      borderColor="common.midGrey"
      p={2}
      spacing={2}
      alignItems="center"
      role="button"
      sx={{ cursor: 'pointer', ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
      onClick={() => onClick(user)}
    >
      <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
        <Stack>
          <Typography>{user.name}</Typography>
          <Typography>{user.email}</Typography>
        </Stack>
      </Stack>
      {isActive && (
        <Tooltip title={t('tooltips.viewOnMap')}>
          <Chip
            label={t('trackingInProgress')}
            color="primary"
            onClick={asset ? () => navigate(`/search?q=${asset.name}`) : undefined}
          />
        </Tooltip>
      )}
      {!isActive && lastActive && (
        <Chip label={t('lastTrackedAt', { at: lastActive.toJSDate() })} />
      )}
      {actions}
    </Stack>
  );
};
