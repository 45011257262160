import React from 'react';

export interface IconProps {
  fill: string,
  outline: string,
  outlineOpacity: number,
}

const House = ({
  fill,
  outline,
  outlineOpacity,
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="64px" width="64px" viewBox="-2 -2 13 13">
    <rect fill="none" height="13" width="13" />
    <path
      d="M4.4839.1292 0 3.333 0 8.8376 3.5308 8.8376 3.5308 5.8962 5.4359 5.8962 5.4359 8.8376 8.9667 8.8376 8.9667 3.333Z"
      fill={fill}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outline}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill',
      }}
    />
  </svg>
);

export default House;
