import type { FC } from 'react';
import { FeatureFlag } from 'components/shared/featureFlag';
import GroupedResults, { type GroupedResultsProps } from './grouped-results.view';
import OldGroupedQueryResults from './groupedResults-view.old';

const GroupedResultsAdapter: FC<GroupedResultsProps> = props => (
  <FeatureFlag
    feature="frontendRevisedOmniboxGroupedResults"
    enabled={<GroupedResults {...props} />}
    disabled={<OldGroupedQueryResults {...props} />}
  />
);

export default GroupedResultsAdapter;
