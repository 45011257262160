import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router';
import { excludeForwardProps } from 'utils/style';

export const NavigationContainer = styled(Box)<{ open: boolean }>(({ theme, open }) => `
  padding-top: ${theme.spacing(4)};
  grid-area: nav;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${theme.palette.common.white};
  border-right: ${theme.border.default};
  transition: all 0.1s;
  overflow: hidden;
  z-index: 11;
  box-shadow: 1px 0 2px -1px rgba(0, 0, 0, .15), 4px 0 5px 0 rgba(0, 0, 0, .08);
  position: absolute;
  height: 100%;
  
  ${open ? `
    width: 150px;
    transition-delay: 0.3s;
  ` : `
    width: 43px;
  `}
`);

export const NavigationButtonIconWrapper = styled(Box)(({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing(2, 0)};
  cursor: pointer;
  transition: all 0.1s;
  
  & .MuiBadge-root {
    position: absolute;
    right: 12px;
    top: 16px;
    
    & > span {
      background-color: ${theme.palette.error.main};
    }
  }
`);

export const NavigationButtonLink = styled(NavLink, {
  shouldForwardProp: excludeForwardProps(['labs']),
})<{ labs: boolean }>(({ theme, labs }) => `
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.1s;
  
  & .MuiTypography-root {
    text-align: left;
    max-height: 46px;
    min-width: 106px;
    flex: 1;
  }
  
  & svg, path, circle, line {
    stroke: none;
    fill: ${labs ? theme.palette.labs.main : theme.palette.common.text};
  }
  
  &:hover {
    background-color: ${labs ? theme.palette.labs.light : theme.palette.common.lightBlue};
  }
  
  &.active {
    background-color: ${labs ? theme.palette.labs.main : theme.palette.primary.main};
    
    & svg, g, path, circle, line {
      stroke: none;
      fill: ${theme.palette.common.white};
    }
    
    & .MuiTypography-root {
      color: ${theme.palette.common.white};
    }
  }
`);
