import React, { useCallback, useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import WebMercatorViewport from '@math.gl/web-mercator';
import useSupercluster from 'use-supercluster';
import { BBox, Feature, Point } from 'geojson';
import { TextLayer } from '@deck.gl/layers';
import { memoize } from 'lodash';
import { useTranslations } from 'use-intl';
import MarkerIcon, { MarkerIconType } from 'components/shared/icons/markerIcons/markerIcon';
import { hexToRGBArray } from 'helpers/color';
import OutlinedIconLayer from './outlinedIconLayer';

const ICON_SIZE = 128;
const LABEL_COLOR: Color = [255, 255, 255];
const LABEL_BACKGROUND: ColorAlpha = [0, 0, 0, 128];
const LABEL_PADDING: [number, number, number, number] = [3, 1, 3, 1];

interface LabelData {
  text: string,
  position: [number, number, number],
}

// from marker we need position, color, clusterId, icon name
export interface IconPoint {
  id: string
  name: string
  latitude: number
  longitude: number
  altitude: number
  colour: string
  icon: string
}

const useLabelLayer = (
  markers: IconPoint[],
  visible: boolean,
  use3d: boolean,
) => {

  const data: LabelData[] = useMemo(() => markers.map(marker => ({
    text: marker.name,
    position: [marker.longitude, marker.latitude, use3d ? marker.altitude : 0],
  })), [markers, use3d]);

  return useMemo(() => new TextLayer<LabelData>({
    id: `icon-label-layer-${use3d ? '3d' : '2d'}`,
    data,
    getText: d => d.text,
    fontWeight: 600,
    getPosition: d => d.position,
    getSize: 12,
    getColor: LABEL_COLOR,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'top',
    getPixelOffset: [0, 32],
    visible,
    parameters: { depthTest: false },
    fontSettings: {
      sdf: true,
      fontSize: 30,
      radius: 12,
    },
    fontFamily: 'objektiv-mk2,sans-serif',
    background: true,
    getBackgroundColor: LABEL_BACKGROUND,
    backgroundPadding: LABEL_PADDING,
  }), [data, use3d, visible]);
}

interface IconData {
  iconPoint: IconPoint;
  size: number;
  colour: string;
  icon: {
    id: string
    url: string
    height: number
    width: number
    anchorX: number
    anchorY: number
    mask: boolean
  };
  position: [number, number, number];
}

// pass in "house" for house
const getIcon = memoize((icon: MarkerIconType) => ({
  id: icon,
  // NOTE: We cannot pass in the correct asset color to the SVG because the way deck.gl renders it makes it black.
  //       It is passed into the OutlinedFragmentShader, which maps the white outline/black fill to white outline/<specified color> fill
  url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<MarkerIcon type={icon} />))}`,
  height: ICON_SIZE,
  width: ICON_SIZE,
  anchorX: ICON_SIZE / 2,
  anchorY: ICON_SIZE / 2,
  mask: true,
}));



type ClusteredMarker = IconPoint & { clusterId: string };

const useIconLayer = (
  iconPoints: ClusteredMarker[],
  visible: boolean,
  use3d: boolean,
  layerId: string,
) => {
  const iconCache = useRef<Record<string, [IconData, IconData, IconData] | undefined>>({});
  const iconCache3D = useRef<Record<string, [IconData, IconData, IconData] | undefined>>({});

  const data = useMemo<IconData[]>(() => iconPoints.flatMap(iconPoint => {
    if (!iconPoint) return [];

    const colour = iconPoint.colour ?? '#ff69b4';

    let items = use3d ? iconCache3D.current[iconPoint.clusterId] : iconCache.current[iconPoint.clusterId];

    if (!items || items[0].colour !== colour || items[0].iconPoint.longitude !== iconPoint.longitude || items[0].iconPoint.latitude !== iconPoint.latitude) {
      const item: IconData = {
        iconPoint,
        size: 5,
        colour,
        icon: getIcon(iconPoint.icon),
        position: [iconPoint.longitude, iconPoint.latitude, use3d ? iconPoint.altitude : 0],
      };

      items = [
        item,
        {
          ...item,
          position: [iconPoint.longitude - 360, iconPoint.latitude, use3d ? iconPoint.altitude : 0],
        },
        {
          ...item,
          position: [iconPoint.longitude + 360, iconPoint.latitude, use3d ? iconPoint.altitude : 0],
        },
      ];

      if (use3d) {
        iconCache3D.current[iconPoint.clusterId] = items;
      } else {
        iconCache.current[iconPoint.clusterId] = items;
      }
    }

    return items;
  }), [iconPoints, use3d]);

  return useMemo(() => new OutlinedIconLayer<IconData>({
    id: layerId,
    data,
    billboard: true,
    pickable: true,
    autoHighlight: true,
    getIcon: d => d.icon,
    sizeScale: 1,
    getPosition: d => d.position,
    getSize: 40,
    getAngle: 0,
    getColor: d => hexToRGBArray(d.colour),
    outlineColor: LABEL_COLOR,
    parameters: { depthTest: false },
    visible,
  }), [data, visible]);
};

const options = {
  map: (props: { marker: IconPoint }) => ({
    markers: [props.marker],
  }),
  reduce: (acc: { markers: IconPoint[] }, props: { markers: IconPoint[] }) => {
    acc.markers = acc.markers.concat(props.markers);
  },
};

export const useIconLayers = (
  markers: IconPoint[] | undefined,
  viewport: WebMercatorViewport,
  layerId: string,
  visible: boolean,
  use3d = false,
) => {
  const t = useTranslations('pages.map.markers');

  const getClusterName = useCallback((clusterMarkers: IconPoint[]) => {
    const maxMarkersShown = 2;
    if (clusterMarkers.length > maxMarkersShown) {
      return `${clusterMarkers.slice(0, maxMarkersShown)
        .map(m => m.name)
        .join('\n')
        }\n${t('overflow', { n: clusterMarkers.length - maxMarkersShown })}`;
    }
    return clusterMarkers.map(m => m.name)
      .join('\n');
  }, [t]);

  const points: Feature<Point, {
    cluster: false,
    marker: IconPoint
  }>[] = useMemo(() => markers?.map(marker => ({
    type: 'Feature',
    properties: {
      cluster: false,
      marker,
    },
    geometry: {
      type: 'Point',
      coordinates: [marker.longitude, marker.latitude],
    },
  })) ?? [], [markers]);

  const bounds = useMemo(() => viewport.getBounds()
    .flat(), [viewport]) as BBox;

  const { clusters } = useSupercluster<{ cluster: false, marker: IconPoint }, { markers: IconPoint[] }>({
    points,
    bounds,
    zoom: viewport.zoom,
    options,
    disableRefresh: !markers,
  });

  const clusterMarkers = useMemo(() => clusters.reduce<ClusteredMarker[]>((acc, cluster) => {
    if (cluster.properties.cluster) {
      acc.push({
        id: '',
        name: getClusterName(cluster.properties.markers),
        colour: '#000',
        icon: 'generic',
        longitude: cluster.geometry.coordinates[0],
        latitude: cluster.geometry.coordinates[1],
        altitude: cluster.geometry.coordinates[2] ?? 0,
        clusterId: `C${cluster.id}`,
      });
    } else {
      const { marker } = cluster.properties;
      acc.push({
        ...marker,
        clusterId: `M${marker.id}`,
      });
    }
    return acc;
  }, []), [clusters, getClusterName]);

  const iconLayer = useIconLayer(clusterMarkers, visible, use3d, layerId);
  const labelLayer = useLabelLayer(clusterMarkers, visible, use3d);

  return useMemo(() => [
    iconLayer,
    labelLayer,
  ], [iconLayer, labelLayer]);
};
