import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import mixpanel from 'mixpanel-browser';
import { isLoggedIn } from 'selectors/session';
import { Settings } from 'slices/settings.slice';
import { MapSettings } from 'slices/map.slice';

interface RequireAuthProps {
  children: JSX.Element
  isStaffOnly?: boolean
}

const RequireAuth = ({ children, isStaffOnly }: RequireAuthProps): JSX.Element => {
  const isAuthenticated = useSelector(isLoggedIn);
  const user = useSelector<ReduxState, ReduxState['session']['user']>(state => state.session.user);
  const userSettings = useSelector<ReduxState, Settings>(state => state.settings);
  const mapSettings = useSelector<ReduxState, MapSettings['maps']['default']>(state => state.map?.maps?.default);
  const location = useLocation();
  const intercom = useIntercom();

  const isPermitted = isAuthenticated && (isStaffOnly ? user.isStaff : true);

  useEffect(() => {
    if (!isPermitted) return;

    // remove settings that are initialized as empty objects (which will cause mixpanel to crash)
    const { layers, ...cleanMapSettings } = mapSettings ?? {};
    const { locale, map, ...cleanUserSettings } = userSettings;
    mixpanel.identify(user.id);
    mixpanel.people.set({
      $name: user.name,
      $email: user.email,
      isStaff: user.isStaff,
      memberOf: user.memberOf.map(org => org.name).join(', '),
      userSettings: cloneDeep(cleanUserSettings),
      localeSettings: { timezone: userSettings?.locale?.timezone, language: userSettings?.locale?.language },
      mapSettings: cleanMapSettings,
    });

    intercom.update({
      name: user.name,
      email: user.email,
      userId: user.id,
      customAttributes: {
        isStaff: user.isStaff,
        memberOf: user.memberOf.map(org => org.name).join(', '),
      }
    });

    intercom.trackEvent('navigate', {
      path: location.pathname,
      time_at: +new Date(),
    });
  }, [location]);

  if (!isPermitted) return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
};

export default RequireAuth;
