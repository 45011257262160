import { Box, Button, Grid, Typography } from '@mui/material';
import { changeDisplayName, logout } from 'apis/auth';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import LangSelector from 'components/shared/languageSelector';
import { useUser } from 'hooks/session/useUser';
import type React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { updateUser } from 'slices/session/session.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { useTranslations } from 'use-intl';
import LoginInput from '../loginInput';
import SubmitButton from '../submitButton';
import useStyles from './SetDisplayName.styles';

export const SetDisplayNamePage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const t = useTranslations('pages.setDisplayName');

  const user = useUser();

  const [name, setName] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const validateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
    if (!value.trim()) return setNameErrorMessage('nameRequired');
    return setNameErrorMessage('');
  };

  const handleSubmit = () => {
    if (!user) return;
    changeDisplayName(name).then(() => {
      dispatch(updateUser({ email: user.email, name }));
      navigate('/');
    });
  };

  const handleLogout = () => {
    logout().then(() => navigate('/login'));
  };

  if (user?.name) {
    navigate('/');
    return null;
  }

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>{t('pageTitle')} - TracPlus Cloud</title>
      </Helmet>
      <Grid className={classes.gridWrapper} container spacing={0}>
        <Grid item xs={12} sm={5}>
          <Box className={classes.formWrapper}>
            <Box className={classes.resetPasswordForm}>
              <Box className={classes.iconWrapper}>
                <TracplusIcon />
              </Box>

              <Typography variant="h1" className={classes.title}>
                {t('title')}
              </Typography>

              <Typography variant="body1" className={classes.subtitle}>
                {t('paragraph')}
              </Typography>

              <LoginInput
                type="name"
                placeholderText={t('namePlaceholder')}
                value={name}
                onChange={validateName}
                inputError={nameErrorMessage}
                t={t}
              />

              <LoginInput
                id="email"
                type="email"
                placeholderText={t('emailPlaceholder')}
                value={user?.email ?? ''}
                t={t}
                readOnly
              />

              <SubmitButton disabled={!name.trim()} text={t('submitButton')} onSubmit={handleSubmit} />

              <Button variant="contained" className={classes.cancelButton} onClick={handleLogout}>
                {t('cancelButton')}
              </Button>

              <LangSelector />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.signupImageWrapper}>
          <Box className={classes.imageOfTheWeek} style={{ backgroundImage: 'url(/img/kestrel-aviation.png)' }}>
            <Box className={classes.imageOfTheWeekText}>Kestrel Aviation (Photo by Wayne Rigg)</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
