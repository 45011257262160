import React from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import DetailPanel from 'components/shared/DetailPanel';
import useTranslation from 'hooks/useTranslation';
import SettingsSection from 'components/shared/settingsSection';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { Link } from 'react-router';
import useOrganisationId from 'hooks/session/useOrganisationId';
import usePermissions from 'hooks/session/usePermissions';
import { useGetAssetBasicAssignedToDevice } from 'apis/rest/assets/hooks';

export const DeviceAsset = ({ deviceId }: { deviceId: number }): JSX.Element => {
  const t = useTranslation('pages.deviceView.asset');
  const permissions = usePermissions();
  const organisationId = useOrganisationId();
  const assetQuery = useGetAssetBasicAssignedToDevice(deviceId).query;
  const asset = assetQuery.data;

  if (!asset || assetQuery.isError) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel p={3}>
          <Box py={2}>
            {t('errorAssignedDevice', { statusCode: assetQuery.error?.response.status })}
          </Box>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (assetQuery.isLoading) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel p={3}>
          <Box py={2}><LoadingIcon size={50} /></Box>
        </DetailPanel>
      </SettingsSection>
    );
  }

  const readOnlyAsset = !(asset.ownerId.toLowerCase() === organisationId.toLowerCase())
    || !permissions.canEditAssets
    || asset.category === 'System';

  return (
    <SettingsSection title={t('title')} description={t('description')}>
      <DetailPanel p={3}>
        <Stack spacing={3}>
          <Grid container component="dl">
            <Grid item xs={6} xl={4} component="dt">{t('owner')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.ownerName}</Grid>
            <Grid item xs={6} xl={4} component="dt">{t('name')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.name}</Grid>
            <Grid item xs={6} xl={4} component="dt">{t('make')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.make}</Grid>
            <Grid item xs={6} xl={4} component="dt">{t('model')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.model}</Grid>
            <Grid item xs={6} xl={4} component="dt">{t('variant')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.variant}</Grid>
            <Grid item xs={6} xl={4} component="dt">{t('tailNumber')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.tailNumber}</Grid>
            <Grid item xs={6} xl={4} component="dt">{t('callSign')}:</Grid>
            <Grid item xs={6} xl={8} component="dd">{asset.callSign}</Grid>
            {asset.messagingHandle && (
              <>
                <Grid item xs={6} xl={4} component="dt">{t('messagingHandle')}:</Grid>
                <Grid item xs={6} xl={8} component="dd">@{asset.messagingHandle}</Grid>
              </>
            )}
          </Grid>
          <Box>
            <Button
              variant="contained"
              component={Link}
              to={`/manage/assets/${asset.id}`}
              sx={theme => ({ padding: theme.spacing(2, 6), fontSize: '1rem' })}
            >
              {t(readOnlyAsset ? 'viewAsset' : 'editAsset')}
            </Button>
          </Box>
        </Stack>
      </DetailPanel>
    </SettingsSection>
  );
};
