import { CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { useFetchEngineUsage } from 'apis/rest/engineUsage/hooks';
import { useFetchTrips } from 'apis/rest/trips/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import { type Input, stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useState } from 'react';
import { useTranslations } from 'use-intl';
import { downloadCSV } from 'utils/download';

export const useDownload = () => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const assetLabel = useAssetLabel();

  const fetchTrips = useFetchTrips();
  const fetchEngineUsage = useFetchEngineUsage();

  const distance = useDistance();
  const duration = useDuration();

  const enableDurationMetrics = useFeatureFlag('frontendAirborneMovementTime');

  return useCallback(
    async (assets: AssetWithDevice[], from: number, until: number) => {
      mixpanel.track('Trip Analysis Download', { type: 'Summary' });
      const assetIds = assets.map(asset => asset.id);
      const [trips, engineUsage] = await Promise.all([
        fetchTrips(assetIds, from, until, 60000),
        fetchEngineUsage(assetIds, from, until, 60000),
      ]);

      const records = assets.reduce<Input>((acc, asset) => {
        const assetTrips = trips.filter(trip => trip.assetId === asset.id);
        const assetEngineUsage = engineUsage.filter(item => item.assetId === asset.id);
        const totals = assetTrips.reduce(
          (sum, trip) => ({
            duration: sum.duration + (trip.duration ?? 0),
            distance: sum.distance + (trip.distance ?? 0),
            airborneDuration: sum.airborneDuration + (trip.airborneDuration ?? 0),
            movementDuration: sum.movementDuration + (trip.movementDuration ?? 0),
          }),
          {
            duration: 0,
            distance: 0,
            airborneDuration: 0,
            movementDuration: 0,
          },
        );
        const totalEngineDuration = assetEngineUsage.reduce((sum, item) => sum + (item.duration ?? 0), 0);

        return acc.concat({
          asset,
          assetLabel: assetLabel(asset),
          tripCount: assetTrips.length,
          totalDistance: distance.create(totals.distance * 1000).format(d => +d.unitValue.toFixed(3)),
          totalDuration: duration.fromMillis(totals.duration),
          totalAirborneDuration: duration.fromMillis(totals.airborneDuration),
          totalMovementDuration: duration.fromMillis(totals.movementDuration),
          engineUsageCount: assetEngineUsage.length,
          totalEngineDuration: duration.fromMillis(totalEngineDuration),
        });
      }, []);

      const columns = [
          {
            key: 'assetLabel',
            header: t('columns.asset'),
          },
          {
            key: 'asset.make',
            header: t('columns.make'),
          },
          {
            key: 'asset.model',
            header: t('columns.model'),
          },
          {
            key: 'tripCount',
            header: t('columns.tripCount'),
          },
          {
            key: 'totalDistance',
            header: t('columns.totalDistance', { unit: distance.unitLabel }),
          },
          {
            key: 'totalDuration',
            header: t('columns.totalDuration'),
          },
          {
            key: 'engineUsageCount',
            header: t('columns.engineUsageCount'),
          },
          {
            key: 'totalEngineDuration',
            header: t('columns.totalEngineDuration'),
          },
        ];

      if (enableDurationMetrics) {
        columns.push(
          {
            key: 'totalAirborneDuration',
            header: t('columns.totalAirborneDuration'),
          },
          {
            key: 'totalMovementDuration',
            header: t('columns.totalMovementDuration'),
          },
        );
      }

      const csvString = csvStringify(records, {
        header: true,
        columns,
      });

      let exportFilename = `${t('filename.summary')} ${assets.map(assetLabel).join(', ')} `;
      const startDate = DateTime.fromMillis(from).toISODate();
      const endDate = DateTime.fromMillis(until).toISODate();
      if (startDate === endDate) {
        exportFilename += startDate;
      } else {
        exportFilename += t('filename.dateRange', { from: startDate, until: endDate });
      }

      downloadCSV(exportFilename, csvString);
    },
    [enableDurationMetrics, fetchTrips, fetchEngineUsage, assetLabel, t, distance, duration],
  );
};

interface DownloadSummaryMenuItemProps {
  assets: AssetWithDevice[];
  from: number | undefined;
  until: number | undefined;
  close: () => void;
  disabled?: boolean;
}

const DownloadSummaryMenuItem = ({
  assets,
  from,
  until,
  close,
  disabled: disabledProp = false,
}: DownloadSummaryMenuItemProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const [isLoading, setIsLoading] = useState(false);
  const download = useDownload();

  const disabled = disabledProp || !assets.length || !from || !until || isLoading;

  const onClick = async () => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await download(assets, from, until);
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" minWidth="12rem">
        <Typography>{t('button.summary')}</Typography>
        {isLoading && <CircularProgress size="1.5rem" />}
      </Stack>
    </MenuItem>
  );
};

export default DownloadSummaryMenuItem;
