import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Container, Stack, Typography } from '@mui/material';
import { Navigate, useParams } from 'react-router';
import { useTranslations } from 'use-intl';
import Page from 'components/pages/page';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import LoadingPage from 'components/pages/loading';
import MissingPage from 'components/shared/missingPage';
import { AssignPeopleIceDialog } from 'components/dialogs/iceContactGroups/assignPeople';
import { DeleteIceContactGroupDialog } from 'components/dialogs/iceContactGroups/deleteGroup/deleteGroup-dialog';
import { AssignAssetsSingleGroupDialog } from 'components/dialogs/contactGroups/assignAssetsSingleGroup';
import {
  useGetIceContactGroups,
  useMutateDeleteIceContactGroup,
  useMutateIceContactGroup, useMutateIceContactGroupAssignDevices,
} from 'apis/rest/iceContactGroups/hooks';
import useLastDefined from 'hooks/useLastDefined';
import useValidateName from 'hooks/contactGroup/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import usePermissions from 'hooks/session/usePermissions';
import { BasicDetails } from '../../common/contactGroupEdit/basicDetails-view';
import { Remove } from '../../common/contactGroupEdit/remove-view';
import { ContactGroupAssetsTable } from '../../common/contactGroupAssetsTable';
import PeopleTable from './peopleTable-view';
import SetDefault from './setDefault-view';

const removeComponents = { DeleteDialog: DeleteIceContactGroupDialog };

const EditIcePage = (): JSX.Element => {
  const permissions = usePermissions();
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id ?? '', 10);

  const { query: groupsQuery, queryKey: groupsQueryKey } = useGetIceContactGroups();
  const updateMutation = useMutateIceContactGroup();
  const deleteMutation = useMutateDeleteIceContactGroup();
  const assignDevicesMutation = useMutateIceContactGroupAssignDevices();

  const foundGroup = groupsQuery.data?.find(g => g.id === id);
  const group = useLastDefined<ContactGroup>(foundGroup, groupsQueryKey);

  const t = useTranslations('pages.manage.ice.edit');

  const [isAssignPeopleDialogOpen, setIsAssignPeopleDialogOpen] = useState<boolean>(false);
  const [isAssignDevicesDialogOpen, setIsAssignDevicesDialogOpen] = useState<boolean>(false);

  const onAssignPeople = () => {
    setIsAssignPeopleDialogOpen(true);
  };

  const onAssignDevices = () => {
    setIsAssignDevicesDialogOpen(true);
  };

  const validateName = useValidateName(groupsQuery);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (!updateMutation.isSuccess) return;
    snackbar.display({
      id: `iceContactGroupEdit.${id}`,
      text: t('basicDetails.snackbar.success'),
      type: 'success',
    });
  }, [id, updateMutation.isSuccess, snackbar, t]);

  useEffect(() => {
    if (!updateMutation.isError) return;
    snackbar.display({
      id: `iceContactGroupEdit.${id}`,
      text: t('basicDetails.snackbar.error'),
      type: 'error'
    });
  }, [id, updateMutation.isError, snackbar, t]);

  if (deleteMutation.isSuccess) return <Navigate to="/manage/ice/groups" replace />;

  if (!group) {
    if (groupsQuery.isLoading || groupsQuery.isRefetching) return <LoadingPage />;
    return (
      <MissingPage
        to="/manage/ice/groups"
        title={t('missing.title')}
        description={t('missing.description')}
        linkText={t('missing.link')}
      />
    );
  }

  return (
    <Page title={group.name}>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>
              {permissions.canEditContactGroups ? t('title', { name: group.name }) : group.name}
            </Typography>
          </Box>

          {permissions.canEditContactGroups && (
            <SettingsSection title={t('basicDetails.title')}>
              <DetailPanel spacing={3}>
                <BasicDetails
                  group={group}
                  mutation={updateMutation}
                  validateName={validateName}
                />
              </DetailPanel>

              <DetailPanel spacing={3} mt={3}>
                <Remove
                  group={group}
                  mutation={deleteMutation}
                  components={removeComponents}
                >
                  {group.isDefault ? (
                    <>
                      <Typography>{t('remove.isDefaultDescription')}</Typography>
                      <Typography>{t('remove.isDefaultBeforeRemoveDescription')}</Typography>
                    </>
                  ) : null}
                </Remove>
              </DetailPanel>

              {!group.isDefault && (
                <DetailPanel spacing={3} mt={3}>
                  <SetDefault group={group} />
                </DetailPanel>
              )}
            </SettingsSection>
          )}

          <SettingsSection
            title={t('people.title')}
            description={t('people.description')}
          >
            {group.peopleWithOrder.length ? (
              <DetailPanel spacing={3} pt={0}>
                <PeopleTable
                  group={group}
                  edit={permissions.canEditContactGroups}
                  noEndBorder={!permissions.canEditContactGroups}
                />
                {permissions.canEditContactGroups && (
                  <Stack px={3} direction="row" justifyContent="flex-end" height="4rem">
                    <Button variant="contained" size="large" onClick={onAssignPeople}>
                      {t('people.assignButton')}
                    </Button>
                  </Stack>
                )}
              </DetailPanel>
            ) : (
              <DetailPanel spacing={3}>
                <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
                  <Stack justifyContent="center">
                    <Typography>{t('people.noPeople')}</Typography>
                  </Stack>
                  {permissions.canEditContactGroups && (
                    <Button variant="contained" size="large" onClick={onAssignPeople}>
                      {t('people.addButton')}
                    </Button>
                  )}
                </Stack>
              </DetailPanel>
            )}
            {permissions.canEditContactGroups && (
              <AssignPeopleIceDialog
                title={t('people.dialog.title', { group: group.name })}
                ariaLabel={t('people.dialog.title', { group: group.name })}
                groupId={id}
                open={isAssignPeopleDialogOpen}
                onClose={() => setIsAssignPeopleDialogOpen(false)}
              />
            )}
          </SettingsSection>
          <SettingsSection
            title={t('assets.title')}
            description={t('assets.description')}
          >
            {group.deviceAndAssetIds.length ? (
              <DetailPanel spacing={3} pt={0}>
                <ContactGroupAssetsTable
                  group={group}
                  noEndBorder={!permissions.canEditContactGroups && !group.isDefault}
                />
                {group.isDefault && <Box px={3}><Alert severity="info">{t('assets.forDefaultGroup')}</Alert></Box>}
                {permissions.canEditContactGroups && (
                  <Stack px={3} direction="row" justifyContent="flex-end" height="4rem">
                    <Button variant="contained" size="large" onClick={onAssignDevices}>
                      {t('assets.assignButton')}
                    </Button>
                  </Stack>
                )}
              </DetailPanel>
            ) : (
              <DetailPanel spacing={3}>
                <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
                  <Stack justifyContent="center">
                    <Typography>{t('assets.noAssets')}</Typography>
                  </Stack>
                  {permissions.canEditContactGroups && (
                    <Button variant="contained" size="large" onClick={onAssignDevices}>
                      {t('assets.assignButton')}
                    </Button>
                  )}
                </Stack>
                {group.isDefault && <Box px={3}><Alert severity="info">{t('assets.forDefaultGroup')}</Alert></Box>}
              </DetailPanel>
            )}
            {permissions.canEditContactGroups && (
              <AssignAssetsSingleGroupDialog
                title={t('assets.dialog.title', { group: group.name })}
                ariaLabel={t('assets.dialog.title', { group: group.name })}
                group={group}
                open={isAssignDevicesDialogOpen}
                onClose={() => setIsAssignDevicesDialogOpen(false)}
                mutation={assignDevicesMutation}
                type="ice"
              />
            )}
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default EditIcePage;
