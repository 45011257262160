import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router';

export interface ReturnToListButtonProps {
  label: string;
}

const ReturnToListButton = ({ label }: ReturnToListButtonProps) => {
  const ReturnButtonText = styled(Typography)(
    ({ theme }) => `
    color: ${theme.palette.primary.main};
  `,
  );

  return (
    <Button component={Link} to=".." sx={{ width: '100%', border: 1 }}>
      <Stack direction="row" spacing={3} sx={{ width: '100%', m: 2 }}>
        <ArrowBackIcon />
        <ReturnButtonText alignSelf="flex-start" fontWeight={700}>{label}</ReturnButtonText>
      </Stack>
    </Button>
  );
};

export default ReturnToListButton;
