import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import useOrganisationId from "hooks/session/useOrganisationId";
import { conversationQueryKeys } from "./conversationQueryKeys";
import { fetchConversations, fetchMessagesByDeviceId } from "./conversationRequests";

export const useMessagesByDeviceId = (deviceId: number, pageSize: number = 30) => {
  const org = useOrganisationId();
  return useInfiniteQuery({
    initialPageParam: 0,

    queryKey: conversationQueryKeys.messages(org, deviceId),
    queryFn: ctx => fetchMessagesByDeviceId(org, deviceId, ctx.pageParam ?? 0, pageSize),
    getNextPageParam: (lastPage: Message[], allPages: Message[][]) => {
      if (lastPage.length < pageSize) return undefined;
      return allPages.reduce((count: number, page: Message[]) => count + page.length, 0);
    },
    refetchInterval: 15 * 1000, // 15 Seconds.
    refetchIntervalInBackground: true,
  });
};

export const useConversations = () => {
  const org = useOrganisationId();
  return useQuery({
    queryKey: conversationQueryKeys.conversations(org),
    queryFn: () => fetchConversations(org),
    refetchInterval: 30 * 1000, // 30 Seconds.
    refetchIntervalInBackground: true,
  });
};
