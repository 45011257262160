import mapboxgl, { type LngLatBounds } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import type { ReportsDataRepository } from 'repositories/reports';
import type { Timespan } from 'repositories/reports/reports';

const calcBounds = (reports: Pick<Report, 'longitude' | 'latitude'>[] | undefined) => {
  if (!reports || reports.length === 0) return null;
  const coords = reports.map<[number, number]>(r => [r.longitude, r.latitude]);
  return coords.reduce((b, coord) => b.extend(coord), new mapboxgl.LngLatBounds(coords[0], coords[0]));
};

type LatestReport = Pick<Report, 'isValid' | 'assetId' | 'latitude' | 'longitude'>;
type TimespanWithAssetId = Pick<Leg, 'assetId'> & Timespan;

export interface MoveMapOptions {
  setView: (bounds: LngLatBounds | null, report: LatestReport | undefined) => void;
  reportsRepository: ReportsDataRepository;
  selectedAssetId?: number;
  selectedLeg?: TimespanWithAssetId | null;
  latestReport?: LatestReport;
  activeLegs?: TimespanWithAssetId[] | null;
}

const useMoveMapToSelection = ({
  setView,
  reportsRepository,
  selectedAssetId,
  selectedLeg,
  latestReport,
  activeLegs,
}: MoveMapOptions) => {
  const selectedAssetIdRef = useRef<number>();
  const selectedLegRef = useRef<TimespanWithAssetId | null>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't run effect on change of setView, latestReport, or assetReports
  useEffect(() => {
    const previousSelectedItemId = selectedAssetIdRef.current;
    const previousSelectedLeg = selectedLegRef.current;
    selectedAssetIdRef.current = selectedAssetId;

    if (selectedAssetId === undefined) return;
    selectedLegRef.current = selectedLeg;

    const assetId = selectedLeg?.assetId ?? selectedAssetId;
    if (selectedAssetId === previousSelectedItemId && (!selectedLeg || selectedLeg === previousSelectedLeg)) return;

    const leg = selectedLeg ?? activeLegs?.find(l => l.assetId === assetId) ?? null;
    // if there is no leg for this asset, and we are filtering to certain legs then jump to latest position only
    if (!leg && activeLegs) {
      setView(null, latestReport);
      return;
    }

    // this ensures that the zoom and pan are retained when the user changes between map splits with different assets
    // selected so zoom to bounds should only happen when the user selects a new asset on this view
    const bounds = calcBounds(reportsRepository.getReportsForAsset(assetId, true, leg));
    setView(bounds, latestReport);
  }, [selectedAssetId, selectedLeg, reportsRepository]);
};

export default useMoveMapToSelection;
