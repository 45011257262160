import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router';
import { useTranslations } from 'use-intl';

export const BasesActions = () => {
  const t = useTranslations('pages.manage.bases.actions');

  return (
    <Button color="primary" variant="contained" disableElevation size="large" component={Link} to="create">
      {t('createBase')}
    </Button>
  );
};
