import React, { useRef, useState } from 'react';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Container, Box, Button, Grid, Tooltip, Typography, Menu, MenuItem, ListItemIcon, ListItemText,
} from '@mui/material';
import {
  PersonAdd,
  PersonAddDisabled,
  Add,
  Checklist,
  Settings,
  ToggleOn,
} from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import insensitiveSort from 'utils/insensitiveSort';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import tableIcons from 'components/shared/icons/tableIcons';
import TableActions from 'components/shared/materialTable/tableActions';
import LinkAbove from 'components/shared/linkAbove';
import { useGetAllOrganisations } from 'apis/rest/memberships/hook';
import Page from 'components/pages/page/page-view';
import { setOrganisationEnabled } from 'apis/rest/staff';
import { membershipQueryKeys } from 'apis/rest/memberships/queryKeys';
import { HttpResponseError } from 'helpers/api';
import { JoinOrganisationParams, LeaveOrganisationParams } from 'slices/session/types';
import DetailPanel from './detailPanel';
import useStyles from './organisationsList-styles';

interface SettingsMenuProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  organisation: Organisation;
}

interface OrganisationsListPageProps {
  organisationId: string;
  user: NonNullable<ReduxState['session']['user']>,
  setOrganisation: (organisationId: string) => void,
  joinOrganisation: (organisation: JoinOrganisationParams) => void,
  leaveOrganisation: (organisation: LeaveOrganisationParams) => void,
}

const SettingsMenu = ({ open, onOpen, onClose, organisation }: SettingsMenuProps) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const iconRef = useRef<null | SVGSVGElement>(null);
  const t = useTranslations('pages.organisationsList');

  const mutation = useMutation<void, HttpResponseError, Organisation>({
    mutationFn: org => setOrganisationEnabled(org.id, !org.enabled),
    onSuccess: () => {
      onClose();
      return queryClient.invalidateQueries({ queryKey: membershipQueryKeys.allOrganisations() });
    },
  });

  return (
    <>
      <Settings
        className={clsx(classes.actionButtonColor)}
        id="menu-button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onOpen}
        ref={iconRef}
      />
      <Menu
        id="menu"
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorEl={() => iconRef.current as Element}
        open={open}
        onClose={onClose}
      >
        <MenuItem onClick={() => mutation.mutate(organisation)} disabled={mutation.isPending}>
          <ListItemIcon>
            <ToggleOn fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('toggleOrganisationEnabled')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const OrganisationsListPage = ({
  organisationId,
  user,
  setOrganisation,
  joinOrganisation,
  leaveOrganisation,
}: OrganisationsListPageProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslations('pages.organisationsList');
  // const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const getAllOrganisations = useGetAllOrganisations().query;

  const editOrganisation = (rowData: Organisation) => {
    const { id } = rowData;
    if (id) {
      setOrganisation(id);
      navigate('/settings/organisation');
    } else {
      navigate('/missing-organisation');
    }
  };

  const handleRowClick = (e: React.MouseEvent<Element, MouseEvent> | undefined, data: Organisation) => {
    const isMember = !!user.memberOf.find(org => org.id === data.id);
    if (isMember) editOrganisation(data);
  };

  const [selectedOrganisation, setSelectedOrganisation] = useState<null | Organisation>(null);

  // const handleCreateClose = orgDetails => {
  //   if (orgDetails) {
  //     const primaryContact = {
  //       name: orgDetails.contactName,
  //       phone: orgDetails.phone,
  //       email: orgDetails.email,
  //       address: orgDetails.address,
  //       city: orgDetails.city,
  //       state: orgDetails.state,
  //       postcode: orgDetails.postcode,
  //       country: orgDetails.country
  //     };
  //     createOrganisation({
  //       name: orgDetails.organisationName,
  //       type: 'Public',
  //       access: orgDetails.accessLevel,
  //       description: orgDetails.organisationDescription,
  //       contactDetails: {
  //         PRIMARY: primaryContact,
  //         BILLING: primaryContact
  //       }
  //     });
  //   }
  //   setCreateDialogOpen(false);
  // };

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <LinkAbove />
            <Box>
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography variant="body1" paragraph>{t('description')}</Typography>
            </Box>
            <Container className={classes.tableContainer} maxWidth="md">
              <Box className={classes.materialTable}>
                {/* @ts-ignore */}
                <MaterialTable
                  isLoading={getAllOrganisations.isLoading}
                  title={t('title')}
                  icons={tableIcons}
                  onRowClick={(e, rowData) => rowData && handleRowClick(e, rowData)}
                  columns={[
                    {
                      title: t('organisationName'),
                      field: 'name',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      defaultSort: 'asc',
                      customSort: (a, b) => insensitiveSort(a.name, b.name)
                    },
                    {
                      title: t('usercode'),
                      field: 'id',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left', fontFamily: 'monospace' },
                      defaultSort: 'asc',
                      customSort: (a, b) => insensitiveSort(a.id, b.id)
                    },
                    {
                      title: t('enabled'),
                      field: 'enabled',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      defaultSort: 'asc',
                    },
                    {
                      title: t('primaryContactName'),
                      field: 'contactName',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      customSort: (a, b) => insensitiveSort(a.contactName, b.contactName)
                    },
                    {
                      title: t('userCount'),
                      field: 'userCount',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      render: rowData => <Tooltip title={t('userCountTooltip')}><span>{rowData.userCount}</span></Tooltip>
                    }
                  ]}
                  data={getAllOrganisations.isSuccess ? getAllOrganisations.data : []}
                  actions={[
                    rowData => {
                      const isMember = !!user.memberOf?.find(org => org.id === rowData.id);
                      const invalidOrg = rowData.id === organisationId;

                      let tooltip = t('join');
                      if (invalidOrg) {
                        tooltip = 'Org is disabled';
                      } else if (isMember) {
                        tooltip = rowData.id !== organisationId ? t('leave') : '';
                      }

                      return {
                        icon: () => {
                          if (invalidOrg) return <PersonAdd />;
                          return (
                            isMember
                              ? <PersonAddDisabled className={clsx(classes.actionButtonColor, { [classes.disabledActionButtonColor]: invalidOrg })} />
                              : <PersonAdd className={clsx(classes.actionButtonColor, { [classes.disabledActionButtonColor]: invalidOrg })} />
                          );
                        },
                        tooltip,
                        onClick: (event, data) => {
                          if (invalidOrg || Array.isArray(data)) {
                            return;
                          }
                          if (isMember) {
                            leaveOrganisation({ organisationId: data.id, userId: user.id });
                          } else {
                            joinOrganisation({
                              organisationId: data.id,
                              membershipRole: 'Administrator',
                              userId: user.id,
                              organisationName: data.name,
                              publicKey: data.publicKey
                            });
                          }
                        },
                        disabled: invalidOrg
                      };
                    },
                    rowData => ({
                      icon: () => <Checklist className={clsx(classes.actionButtonColor)} />,
                      tooltip: 'Toggle Features',
                      onClick: e => navigate(`/staff/organisations/${rowData.id}/features`),
                    }),
                    rowData => ({
                      icon: () => (
                        <SettingsMenu
                          open={rowData.id === selectedOrganisation?.id}
                          onOpen={() => setSelectedOrganisation(rowData)}
                          onClose={() => setSelectedOrganisation(null)}
                          organisation={rowData}
                        />
                      ),
                      onClick: () => null,
                    }),
                  ]}
                  options={{
                    draggable: false,
                    showTitle: false,
                    search: true,
                    actionsColumnIndex: -1,
                    searchFieldStyle: {
                      borderRadius: '4px',
                      paddingLeft: '18px',
                      paddingRight: '10px'
                    },
                    searchFieldVariant: 'outlined',
                    thirdSortClick: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: { position: 'sticky', top: 0 },
                  }}
                  localization={{
                    header: {
                      actions: t('actions')
                    },
                    toolbar: {
                      searchPlaceholder: t('search')
                    },
                    pagination: {
                      labelRows: t('rows'),
                      labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
                      firstTooltip: t('firstPage'),
                      previousTooltip: t('previousPage'),
                      nextTooltip: t('nextPage'),
                      lastTooltip: t('lastPage')
                    }
                  }}
                  components={{
                    Actions: TableActions,
                    // eslint-disable-next-line react/destructuring-assignment
                    Action: props => {
                      const { action, data } = props;
                      if (action.position === 'toolbar') {
                        return (
                          <Button
                            onClick={event => action.onClick(event, data)}
                            className={classes.newButton}
                            variant="contained"
                          >
                            <Add />
                            {t('createOrg')}
                          </Button>
                        );
                      }
                      return (<MTableAction {...props} />);
                    }
                  }}
                  detailPanel={[{
                    tooltip: t('ShowOrgContactDetails'),
                    render: row => <DetailPanel rowData={row.rowData} />
                  }]}
                />
              </Box>
              {// TODO: Disabling org creation until we turn sync off (because it's not compatible with Legacy sync)
                // <CreateDialog
                //   open={createDialogOpen}
                //   onClose={handleCreateClose}
                //   classes={classes}
                // />
              }
            </Container>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default OrganisationsListPage;
