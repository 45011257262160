import { HttpResponseError, serenityFetch } from 'helpers/api';
import { OrganisationUpdateDetails } from './types';

export const getOrganisationDetails = async (id: string): Promise<Organisation> => {
  const response = await serenityFetch('GET', `/organisations/${id}`, null);
  HttpResponseError.detect(response);
  return (await response.json()) as Organisation;
};

export const updateOrganisationDetails = async (id: string, organisation: OrganisationUpdateDetails): Promise<Organisation> => {
  const response = await serenityFetch('PUT', `/organisations/${id}`, organisation);
  HttpResponseError.detect(response);
  return (await response.json()) as Organisation;
};
