import {Base, BaseAssetAssignment, BaseNote, BaseRequest} from "apis/rest/bases/bases-types";
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from "@tanstack/react-query";
import type {HttpResponseError} from "helpers/api";
import useOrganisationId from "hooks/session/useOrganisationId";
import {createBase, deleteBase, getBases, updateBase, updateBaseAssignment} from "apis/rest/bases/bases-requests";
import { v4 as uuid } from 'uuid';

export const baseQueryKeys = {
  org: (org: string) => [org, 'bases'] as const,
}

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetBases = <T = Base[]>(options?: Options<Base[], T>)=> {
  const organisationId = useOrganisationId();
  const queryKey = baseQueryKeys.org(organisationId);

  return useQuery({
    queryKey,
    queryFn: async () => await getBases(organisationId),
    ...options
  });
};

export const useDeleteBaseMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (baseId: string) => deleteBase(organisationId, baseId),
    onMutate: async baseId => {
      await queryClient.cancelQueries({queryKey: baseQueryKeys.org(organisationId)});
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));
      queryClient.setQueryData(
        baseQueryKeys.org(organisationId),
        (oldData?: Base[]) => oldData?.filter(base => base.id !== baseId)
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? [])
    },
  })
}

const mapBaseToBaseRequest = (base: Base): BaseRequest =>
  ({
    ...base,
    assignments: mapAssetIdsToAssetAssignments(base.assetIds),
    latitude: base.latitude ?? 0,
    longitude: base.longitude ?? 0,
    notes: base.notes.map<BaseNote>(n => ({
      id: uuid(),
      note: n,
    })),
  })

const mapAssetIdsToAssetAssignments = (assetIds: number[]): BaseAssetAssignment[] => assetIds.map<BaseAssetAssignment>(id =>
  ({
    assetId: id,
    id: uuid()
  }))

export const useUpdateBaseMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (base: Base) => updateBase(organisationId, mapBaseToBaseRequest(base)),
    onMutate: async newBase => {
      await queryClient.cancelQueries({queryKey: baseQueryKeys.org(organisationId)});
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));
      queryClient.setQueryData(
        baseQueryKeys.org(organisationId),
        (oldData?: Base[]) => (oldData?.filter(base => base.id !== newBase.id) ?? []).concat(newBase)
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? [])
    },
  })
}

export const useCreateBaseMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (base: Base) => createBase(organisationId, mapBaseToBaseRequest(base)),
    onMutate: async newBase => {
      await queryClient.cancelQueries({queryKey: baseQueryKeys.org(organisationId)});
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));
      queryClient.setQueryData(
        baseQueryKeys.org(organisationId),
        (oldData?: Base[]) => (oldData ?? []).concat(newBase)
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? [])
    },
  })
}

export const useUpdateBaseAssignmentsMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (base: Base) => updateBaseAssignment(organisationId, base.id, mapAssetIdsToAssetAssignments(base.assetIds)),
    onMutate: async newBase => {
      await queryClient.cancelQueries({queryKey: baseQueryKeys.org(organisationId)});
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));

      queryClient.setQueryData(
        baseQueryKeys.org(organisationId),
        (oldData?: Base[]) => (oldData?.filter(base => base.id !== newBase.id) ?? []).concat(
          {
            ...(oldData?.find(base => base.id === newBase.id) ?? newBase),
            assetIds: newBase.assetIds
          }
        )
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? [])
    },
  })
}
