import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router';
import qs from 'query-string';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import LangSelector from 'components/shared/languageSelector';
import PasswordStrength from 'components/shared/passwordStrength';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import MicrosoftLoginButton from 'components/shared/microsoftLoginButton';
import * as passwordHelper from 'helpers/password';
import useTranslation from 'hooks/useTranslation';
import { finishCreateInvitedSSOUser, verifyInvite, createInvitedSSOUser } from 'apis/invite';
import { logout } from 'apis/auth';

import LoginInput from '../loginInput';
import SubmitButton from '../submitButton';

import useStyles from './signup-styles';

const SignupPage = ({
  signup,
  SSOSignupSuccess,
  SSOSignupFailure,
}) => {
  const location = useLocation();
  const [email, setEmail] = useState('');
  // verified == true means we're sure we're verified, false means unsure (loading)
  const [verified, setVerified] = useState(false);
  // notVerified == true means we're sure we're not verified, false means unsure (loading)
  const [notVerified, setNotVerified] = useState(false);
  const query = qs.parse(location.search);
  const redirect = Object.keys(query).includes('redirect');
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation('pages.signup');

  const [name, setName] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [fields, setField] = useState({
    password: '',
    confirm: ''
  });
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(name && !nameErrorMessage && !passwordHelper.validate(fields.password, fields.confirm));
  }, [name, fields]);

  useEffect(() => {
    if (redirect) {
      setVerified(false);
      finishCreateInvitedSSOUser(query.token)
        .then(r => {
          if (r !== null) {
            SSOSignupSuccess(r);
            navigate('/login?loading');
          } else {
            SSOSignupFailure(Error('User is null. Try logging in again or logging out.'));
          }
        })
        .catch(e => {
          setVerified(true);
          SSOSignupFailure(e);
        });
    } else if (!verified) {
      verifyInvite(query.token)
        .then(invDetails => {
          setEmail(invDetails.email);
          setName(invDetails.name);
          setVerified(true);
        }, () => {
          setNotVerified(true);
        });
    }
  }, []);

  const update = (id, value) => setField({
    ...fields,
    [id]: value
  });

  const validateName = e => {
    const { value } = e.target;
    setName(value);
    if (!value.trim()) return setNameErrorMessage('nameRequired');
    return setNameErrorMessage('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isValid) {
      // Log out any previous user
      logout()
        .then(() => signup({
          name,
          email,
          password: fields.password,
          token: query.token,
        }))
        .then(() => navigate('/'));
    }
  };

  const handleSSOSubmit = (e, provider) => {
    e.preventDefault();
    logout()
      .then(() => createInvitedSSOUser(query.token, provider))
      .then(() => navigate({ pathname: '/signup', search: `?token=${query.token}&redirect` }));
  };

  return (
    <Box className={classes.container}>
      <Grid className={classes.gridWrapper} container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.formWrapper}>
          {verified === false ? (
            notVerified === false ? (
              <NoHeaderNoFooterLoadingPage />
            ) : (
              <Box className={classes.signupForm}>
                <Box className={classes.iconWrapper}>
                  <TracplusIcon />
                </Box>
                <Typography variant="h1" className={classes.title}>{t('inviteInvalid')}</Typography>
                <Typography variant="body1" className={classes.subtitle}>{t('inviteInvalidParagraph')}</Typography>
                <Grid item xs={12} sm={12}>
                  <Link to="/login">
                    <Button variant="contained" className={classes.cancelButton}>{t('cancelButton')}</Button>
                  </Link>
                </Grid>
              </Box>
            )
          ) : (
            <Box className={classes.signupForm}>
              <Box className={classes.iconWrapper}>
                <TracplusIcon />
              </Box>
              <Typography variant="h1" className={classes.title}>{t('title')}</Typography>
              <Typography variant="body1" className={classes.subtitle}>{t('paragraph')}</Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className={classes.formControl}>
                  <LoginInput
                    id="email"
                    type="email"
                    placeholderText={t('emailPlaceholder')}
                    value={email}
                    t={t}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formControl}>
                  <LoginInput
                    type="name"
                    placeholderText={t('namePlaceholder')}
                    value={name}
                    onChange={validateName}
                    inputError={nameErrorMessage}
                    onEnterKeypress={handleSubmit}
                    t={t}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formControl}>
                  <LoginInput
                    id="password"
                    type="password"
                    placeholderText={t('passwordPlaceholder')}
                    value={fields.password}
                    validate={passwordHelper.validateSingle}
                    onChange={update}
                    onEnterKeypress={handleSubmit}
                    t={t}
                  />
                  {fields.password.length >= passwordHelper.MINIMUM_PASSWORD_LENGTH && <PasswordStrength value={fields.password} />}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formControl}>
                  <LoginInput
                    id="confirm"
                    type="password"
                    placeholderText={t('confirmPasswordPlaceholder')}
                    value={fields.confirm}
                    validate={v => passwordHelper.validateSingle(v, fields.password)}
                    onChange={update}
                    onEnterKeypress={handleSubmit}
                    t={t}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.action}>
                <Grid item xs={12} sm={12}>
                  <SubmitButton
                    disabled={!isValid}
                    text={t('submitButton')}
                    onSubmit={handleSubmit}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.signupSeparatorContainer}>
                    <Box className={classes.signupSeparatorBorder} />
                    <Typography variant="body1" className={classes.signupSeparator}>
                      {t('signupSeparator')}
                    </Typography>
                    <Box className={classes.signupSeparatorBorder} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MicrosoftLoginButton onClick={handleSSOSubmit} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" className={classes.disclaimer}>
                    {t('disclaimerText')}
                    {' '}
                    <Link to="/legal/terms" target="_blank">{t('disclaimerLink')}</Link>
                    .
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Link to="/login">
                    <Button variant="contained" className={classes.cancelButton}>{t('cancelButton')}</Button>
                  </Link>
                </Grid>
                <Typography className={classes.errorMessage} />
                <LangSelector />
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.signupImageWrapper}>
          <Box className={classes.imageOfTheWeek} style={{ backgroundImage: 'url(/img/kestrel-aviation.png)' }}>
            <Box className={classes.imageOfTheWeekText}>Kestrel Aviation (Photo by Wayne Rigg)</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignupPage;
